import { Stack } from '@mui/material';
import { forwardRef } from 'react';
import { themes } from '../../mui/themes';

const ControlButton = forwardRef(({
  children,
  onClick,
  hasHover = true,
  isPointer = true,
  isActive = false,
  p = 1,
  sx = {},
  className = 'control',
}, ref) => (
  <Stack
    ref={ref}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      width: '50px',
      cursor: isPointer ? 'pointer' : 'not-allowed',
      backgroundColor: isActive ? 'var(--gi_color_primary_color)' : themes.geoimpact.palette.primary.main,
      ...(hasHover && {
        '&:hover': {
          backgroundColor: 'var(--gi_color_accent_color)',
        },
      }),
      ...sx,
    }}
    p={p}
    onClick={(e) => onClick(e)}
    className={className}
  >
    {children}
  </Stack>
));

export default ControlButton;
