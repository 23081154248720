/* eslint-disable max-len */
import * as L from 'leaflet';
import FavoriteApi from '../../services/FavoriteApi';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';

const {
  getEventTypesForAddresses,
  deleteEventTypeFromAddressFavorite,
  addEventTypeToAddressFavorite,
  getEventTypesForRegions,
  deleteEventTypeFromRegionFavorite,
  addEventTypeToRegionFavorite,
  deleteEventTypeOfAddressByGroup,
  deleteEventTypeOfRegionByGroup,
  addRecipientOfAddressByEmail,
  addRecipientOfRegionByEmail,
  deleteRecipientOfAddressByEmail,
  deleteRecipientOfRegionByEmail,
  postRecipient,
  deleteRecipientOfAddressByGroup,
  deleteRecipientOfRegionByGroup,
  addRecipientOfAddressGroupByEmail,
  addRecipientOfRegionByGroup,
  deleteFavoriteAddress,
  deleteFavoriteRegion,
  patchFavoriteAddress,
  patchFavoriteRegion,
  patchFavoritePolygon,
  deleteFavoritePolygon,
  addEventTypeOfAddressByGroup,
  addEventTypeOfRegionByGroup,
} = FavoriteApi;

export const API = {
  [FAVORITE_TYPE.ADDRESS]: {
    getEventTypes: (jwt) => getEventTypesForAddresses(jwt),
    deleteEventType: (jwt, favId, evId) => deleteEventTypeFromAddressFavorite(jwt, favId, evId),
    addEventType: (jwt, favId, evId) => addEventTypeToAddressFavorite(jwt, favId, evId),
    deleteEventTypeFromGroup: (jwt, evId, group) => deleteEventTypeOfAddressByGroup(jwt, evId, group),
    addRecipient: (jwt, favId, email) => addRecipientOfAddressByEmail(jwt, favId, email),
    deleteRecipient: (jwt, favId, email) => deleteRecipientOfAddressByEmail(jwt, favId, email),
    createRecipient: (jwt, email) => postRecipient(jwt, email),
    deleteRecipientByGroup: (jwt, group, email) => deleteRecipientOfAddressByGroup(jwt, group, email),
    addRecipientByGroup: (jwt, group, email) => addRecipientOfAddressGroupByEmail(jwt, group, email),
    deleteFavorite: (jwt, favId) => deleteFavoriteAddress(jwt, favId),
    patchFavorite: (jwt, favorite, group) => patchFavoriteAddress(jwt, favorite, { group }),
    addEventTypeToGroup: (jwt, evId, group) => addEventTypeOfAddressByGroup(jwt, evId, group),
  },
  [FAVORITE_TYPE.REGION]: {
    getEventTypes: (jwt) => getEventTypesForRegions(jwt),
    deleteEventType: (jwt, favId, evId) => deleteEventTypeFromRegionFavorite(jwt, favId, evId),
    addEventType: (jwt, favId, evId) => addEventTypeToRegionFavorite(jwt, favId, evId),
    deleteEventTypeFromGroup: (jwt, evId, group) => deleteEventTypeOfRegionByGroup(jwt, evId, group),
    addRecipient: (jwt, favId, email) => addRecipientOfRegionByEmail(jwt, favId, email),
    deleteRecipient: (jwt, favId, email) => deleteRecipientOfRegionByEmail(jwt, favId, email),
    createRecipient: (jwt, email) => postRecipient(jwt, email),
    deleteRecipientByGroup: (jwt, group, email) => deleteRecipientOfRegionByGroup(jwt, group, email),
    addRecipientByGroup: (jwt, group, email) => addRecipientOfRegionByGroup(jwt, group, email),
    deleteFavorite: (jwt, favId) => deleteFavoriteRegion(jwt, favId),
    patchFavorite: (jwt, favorite, group) => patchFavoriteRegion(jwt, favorite, { group }),
    addEventTypeToGroup: (jwt, evId, group) => addEventTypeOfRegionByGroup(jwt, evId, group),
  },
  [FAVORITE_TYPE.POLYGON]: {
    patchFavorite: (jwt, favorite, group) => patchFavoritePolygon(jwt, favorite, { group }),
    deleteFavorite: (jwt, favId) => deleteFavoritePolygon(jwt, favId),
  },
};

export function filterIDsBasedOnEventTypes(referenceIds, favorites) {
  let filteredIds = [...referenceIds];
  Object.values(favorites).forEach((entry) => {
    const currentIds = entry.eventTypes.map((e) => e.id);
    filteredIds = filteredIds.filter((id) => currentIds.includes(id));
  });

  return filteredIds.sort((a, b) => a - b);
}

export const parseWKT = (wktString) => wktString
  .replace('POLYGON((', '')
  .replace('))', '')
  .split(', ')
  .map((pair) => {
    const [lng, lat] = pair.split(' ');
    return [parseFloat(lat), parseFloat(lng)]; // Leaflet uses lat, lng order
  });

export const getLeafletBounds = (bounds) => {
  /* eslint-disable no-underscore-dangle */
  const corner1 = L.latLng(bounds._southWest.lat, bounds._southWest.lng);
  const corner2 = L.latLng(bounds._northEast.lat, bounds._northEast.lng);
  /* eslint-disable no-underscore-dangle */
  return L.latLngBounds(corner1, corner2);
};
