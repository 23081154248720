import {useState, useEffect, useContext, Fragment, useRef} from 'react';

import './Events.css'
import HyperMenu from '../hyper_menu/HyperMenu'
import Navbar from '../../components/sep_map/component_navbar/Navbar'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import moment from 'moment'
import { Button, Loader, Pagination, Icon } from 'semantic-ui-react'
import { Button as MUIButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import * as L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import SEPContext from "../../contexts/sep-context/SEPContext";
import {useSelection} from "../../contexts/selection/selection-context";
import {useMap} from "../../contexts/map/map-context";
import { useNavigate } from 'react-router-dom';


import * as loglevel from "loglevel";
import env from "../../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

function Filter (props) {
  // const [filter, setFilter] = useState('')
  return (
    <div className={'filter'}>
      <TextField
        style={{ minWidth: '200px' }}
        variant="outlined"
        onChange={(event) => {
          let value = event.target.value
          // setFilter(value)
          props.onFilterChange(value)
        }}
        placeholder={props.i18n.t('favorites:table-advanced-filter')}
        type={'text'}
      />
    </div>
  )
}

export function Events (props) {
  const hookName = 'Events'
  const updateEeventName = 'events-update'
  const maxPerPage = 50
  const context = useContext(SEPContext).SEPContext;
  const { user: { jwt }, env } = context;
  const { setPoints } = useSelection();
  const { setDesiredView } = useMap();
  const eventMacroTypes = {
    ADDRESS: 'ADDRESS',
    REGION: 'REGION'
  }
  const menuRef = useRef(null);
  const [favorites, setFavorites] = useState([])
  const [filter, setFilter] = useState('')
  const [selectedPage, setSelectedPage] = useState(1)

  const [events, setEvents] = useState([])
  const [completeEvents, setCompleteEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [fetchEventsCount, setFetchEventsCount] = useState(0)
  const [isLoadingEvents, setIsLoadingEvents] = useState(false)
  const [loadingEventIdArray, setLoadingEventId] = useState([])
  const navigate = useNavigate();

  const getFilteredEvents = (completeEventsArray) => {
    return completeEventsArray.filter((completeEvent, i) => {
      let propsToFilter = `${completeEvent.renderedEvent.innerText}`
      let filterTokens = filter.toLowerCase().split(' ').map((token) => {
        return token.trim()
      })

      let foundTokens = []
      let notFoundTokens = []
      filterTokens.forEach((token) => {
        let hasToken = propsToFilter.toLowerCase().includes(token)
        if (!hasToken) {
          notFoundTokens.push(token)
        } else {
          foundTokens.push(token)
        }
      })
      return foundTokens.length === filterTokens.length
    })
  }
  const getPaginationElements = (eventsArray) => {
    // hook in the paginator
    let minIndex = (selectedPage - 1) * maxPerPage
    let maxIndex = minIndex + (maxPerPage - 1)

    let newEvents = eventsArray.filter((completeEvent, index) => {
      return index >= minIndex && index <= maxIndex
    })

    return newEvents
  }
  const fetchFavorites = async () => {
    let favoritesResponse = await getFavoriteAddresses()
    setFavorites(favoritesResponse.data)
  }
  const fetchEvents = async () => {
    let addressEventsResponse = await getEvents()
    let regionEventsResponse = await getRegionalEvents()
    // setEvents([...addressEventsResponse.data])
    setEvents([...regionEventsResponse.data, ...addressEventsResponse.data])
  }
  const fetchEventTriggers = async () => {
    const getTriggerByBaseUri = async (event) => {
      let triggerBaseUri = event.triggerBaseUri
      let triggerBaseUriEndpoint = `${triggerBaseUri}?id=${event.triggerId}`
      return axios(
        {
          method: 'get',
          url: triggerBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        log.error(`${hookName} getCompleteEventInfo - trigger`, { error })
      })
    }
    const getTrigger = async (event) => {
      // make a distinction between new and old events based on triggerObject
      if (event.triggerObject === null) {
        return await getTriggerByBaseUri(event)
      } else {
        try {
          let trigger = JSON.parse(event.triggerObject)
          trigger.id = event.triggerId
          trigger.recordType = event.triggerType
          return await trigger
        } catch (e) {
          throw e
        }
      }
    }
    let uniqueEventsByTrigger = events.filter((event, index, events) => {
      return events.map(mapObject => mapObject.triggerId).indexOf(event.triggerId) === index
    })
    let promises = uniqueEventsByTrigger.map((event) => getTrigger(event))
    let triggers = await Promise.all(promises).then((triggers) => triggers)
    return triggers
  }
  const fetchEventTypes = async () => {
    const getType = (event) => {
      let eventTypeBaseUri = event.eventTypeBaseUri
      let eventTypeBaseUriEndpoint = `${eventTypeBaseUri}?id=${event.eventTypeId}`
      return axios(
        {
          method: 'get',
          url: eventTypeBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        log.error(`${hookName} getEventInfos - eventTypeBaseUri`, { error })
      })
    }
    let uniqueEventsByType = events.filter((event, index, events) => {
      return events.map(mapObject => mapObject.eventTypeId).indexOf(event.eventTypeId) === index
    })
    let promises = uniqueEventsByType.map((event) => getType(event))
    let types = await Promise.all(promises).then((types) => types)
    return types
  }
  const fetchAll = async () => {
    await fetchFavorites()
    await fetchEvents()
    setFetchEventsCount(fetchEventsCount + 1)
  }
  const getCompleteEventInfo = async (event, triggers, types) => {
    const getFavoriteByBaseUri = async (event) => {
      let favoriteBaseUriEndpoint = `${event.favoriteRegionBaseUri}?id=${event.favoriteRegionId}`
      return await axios(
        {
          method: 'get',
          url: favoriteBaseUriEndpoint,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then((response) => {
        return response.data[0]
      })
      .catch((error) => {
        log.error(`${hookName} getCompleteEventInfo - trigger`, { error })
      })
    }
    const getFavorite = async (event, macroType) => {
      if (macroType === eventMacroTypes.ADDRESS) {
        if (event.addressObject !== null) {
          let favorite = JSON.parse(event.addressObject)

          return await favorite
        } else {
          const favorite = favorites.filter(favorite => favorite.id === event.favoriteAddressId)[0]

          return await favorite
        }
      } else if (macroType === eventMacroTypes.REGION) {
        if (event.regionObject !== null) {
          let favorite = JSON.parse(event.regionObject)
          return await favorite
        } else {
          let favorite = await getFavoriteByBaseUri(event)

          return await favorite
        }
      }
    }
    const getMacroType = (event) => {
      let macroType
      if (typeof event.favoriteAddressId !== 'undefined') {
        macroType = eventMacroTypes.ADDRESS
      } else if (typeof event.favoriteRegionId !== 'undefined') {
        macroType = eventMacroTypes.REGION
      }
      return macroType
    }
    const getTrigger = async (event) => {
      if (event.triggerObject !== null) {
        let trigger = JSON.parse(event.triggerObject)

        return await trigger
      } else {
        const trigger = triggers.filter(trigger => trigger.id === event.triggerId)[0]

        return await trigger
      }
    }
    const trigger = await getTrigger(event)
    const type = types.filter(eventType => eventType.id === event.eventTypeId)[0]
    const macroType = getMacroType(event)
    const favorite = await getFavorite(event, macroType)

    return { event, trigger, type, favorite, macroType }
  }

  useEffect(() => {
    moment.locale(props.i18n.language.toLowerCase())
  }, [props.i18n.language, props])

  useEffect(() => {
    let filteredEvents = getFilteredEvents(completeEvents)
    setFilteredEvents(filteredEvents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeEvents, filter])

  useEffect(() => {
    if(env && jwt){
      fetchAll().catch((e) => {
        log.error('could not fetch the needed data', e)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [env, jwt])

  useEffect(() => {
    const getCompleteEventInfos = async (eventArray) => {
      let types = await fetchEventTypes()
      let triggers = await fetchEventTriggers()
      let completeEvents = []
      for (let i = 0; i < eventArray.length; i++) {
        let event = eventArray[i]
        completeEvents.push(await getCompleteEventInfo(event, triggers, types))
      }
      return completeEvents
    }
    const sortEventsByDate = (completeEvents) => {
      let sortedCompleteEvents = completeEvents
      sortedCompleteEvents.sort((a, b) => {
        let dateA = moment(a.event.lastUpdated)
        let dateB = moment(b.event.lastUpdated)

        if (dateA.isBefore(dateB)) {
          return 1
        }
        if (dateA.isAfter(dateB)) {
          return -1
        } else {
          return 0
        }
      })
      return sortedCompleteEvents
    }
    setIsLoadingEvents(true)
    getCompleteEventInfos(events).then((completeEvents) => {
      completeEvents = completeEvents.map((completeEvent, i) => {
        let reactComponent = getEventListComponent(completeEvent, i)
        let elementToFilter = document.createElement('div')
        elementToFilter.innerHTML = ReactDOMServer.renderToString(reactComponent)
        return {
          ...completeEvent,
          renderedEvent: elementToFilter
        }
      })
      setCompleteEvents(sortEventsByDate(completeEvents))
      setIsLoadingEvents(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  // axios
  const getFavoriteAddresses = async () => {
    let endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses`
    let data = {}
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} getFavoriteAddresses`, { error })
    })
  }

  const deleteEvent = async (completeEvent) => {

    let endpoint
    if (completeEvent.macroType === eventMacroTypes.ADDRESS) {
      endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses/${completeEvent.event.id}`
    } else if (completeEvent.macroType === eventMacroTypes.REGION) {
      endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions/${completeEvent.event.id}`
    }

    let data = {}
    return await axios(
      {
        method: 'delete',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} deleteEvent ${completeEvent.macroType}`, { error })
    })
  }

  const deleteAllEventsForMandant = async () => {
    let endpointAddressDelete = `${env.API_GATEWAY_BASE}/api/eventsforaddresses/`
    let data = {}
    let addressDeleteResponse = await axios(
      {
        method: 'delete',
        url: endpointAddressDelete,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} deleteAllEventsForMandant`, { error })
    })
    let endpointRegionDelete = `${env.API_GATEWAY_BASE}/api/eventsforregions/`
    let regionDeleteResponse = await axios(
      {
        method: 'delete',
        url: endpointRegionDelete,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        data: JSON.stringify(data)
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} deleteAllEventsForMandant`, { error })
    })
    return { addressDeleteResponse, regionDeleteResponse }
  }

  const getEvents = async () => {
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforaddresses`
    /*let data = {}*/
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }/*,
        data: JSON.stringify(data)*/
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} getEvents`, { error })
    })
  }

  const getRegionalEvents = async () => {
    let endpoint = `${env.API_GATEWAY_BASE}/api/eventsforregions`
    return await axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }/*,
        data: JSON.stringify(data)*/
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      log.error(`${hookName} getRegionalEvents`, { error })
    })
  }

  const getEventListComponentDetail = (completeEvent) => {
    let trigger = completeEvent.trigger
    let event = completeEvent.event
    let dynamicObject = completeEvent.favorite
    let macroType = completeEvent.macroType

    let eventImageUrl
    let recordInfo
    const getEventShell = (content) => {
      return (
        <div className={'container'}>
          <div className={'column icon'}>
            <div
              className={'image'}
              style={{
                backgroundImage: `url('${eventImageUrl}')`
              }}
            />
          </div>
          <div className={'column data'}>
            {content}
            <div className={'event-release'}>
              {event.userSpecific ? (
                <div>
                  {props.i18n.t(`events:event-released-by`)}&nbsp;
                  <a href={`mailto:${event.userOrigin}`}>{event.userOrigin}</a>&nbsp;
                  {props.i18n.t(`events:event-released-by-tenant`)} {event.mandantOrigin}&nbsp;
                  {props.i18n.t(`events:event-released-for`)}&nbsp;
                  <a href={`mailto:${event.user}`}>{event.user}</a>
                </div>
              ) : null}
            </div>
          </div>
          <div className={'recipient'} style={{ display: 'none' }}>{JSON.stringify(event.emailRecipient) || ''}</div>
        </div>
      )
    }
    const isRegionEvent = macroType === eventMacroTypes.REGION;
    const isCampaignEvent = (dynamicObject) => {
      return isRegionEvent && (
          dynamicObject.swissZipCode === null
          && dynamicObject.town === null
          && dynamicObject.campaign !== undefined
          && dynamicObject.campaign !== null
      )
    }
    const getTriggeredByComponents = (detail) => {
      if(isCampaignEvent(detail.dynamicObject)){
        return (
            <>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-campaign`)}:&nbsp;
            </>
        )
      } else {
        return (
            <>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
            </>
        )
      }
    }
    if (macroType === eventMacroTypes.ADDRESS) {
      if (event.triggerType === 'record_type1') {
        // Bauvorhaben
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Baugesuche.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type2') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudenutzung.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {props.i18n.t('events:message-ausgeloest-durch')}&nbsp;
              {props.i18n.t(`events:event-type-${macroType.toLowerCase()}`)}:&nbsp;
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type3') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudeinfo.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if(event.triggerType === 'record_type5'){
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Parzelle.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {`${dynamicObject.street} ${dynamicObject.houseNumber}, ${dynamicObject.swissZipCode} ${dynamicObject.town}`}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      }
    } else if (macroType === eventMacroTypes.REGION) {
      if (event.triggerType === 'record_type1') {// Bauvorhaben
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Baugesuche.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type2') {
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudenutzung.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type3') {// Address
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Gebaeudeinfo.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if (event.triggerType === 'record_type4') {// Address
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/marketsense/v_2/MarketSense_dashboard.png`
        let translatedRecordTitle = props.i18n.t(`panel_usage:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_usage:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      } else if(event.triggerType === 'record_type5'){
        eventImageUrl = `${env.AZURE_STORAGE_BASE}/halley/components/dashboard/tile-icons/Parzelle.png`
        let translatedRecordTitle = props.i18n.t(`panel_construction_zone:${trigger.recordTitle}`);
        let originalRecordTitle = trigger.recordTitle;
        let recordTitle = translatedRecordTitle !== `panel_construction_zone:${originalRecordTitle}` ? translatedRecordTitle : originalRecordTitle;
        recordInfo = trigger.recordInfo;
        let content = (
          <div className={'event-content'}>
            <div>{props.i18n.t(`events:${event.triggerType}`)}</div>
            <h4>{recordTitle}</h4>
            {(() => {
              if (recordInfo) {
                return <div>{recordInfo}</div>
              }
            })()}
            <div>{props.i18n.t(`events:${event.statusTextLocize}`)}</div>
            <div>
              {getTriggeredByComponents({event, dynamicObject, trigger})}
              {isCampaignEvent(dynamicObject) ? dynamicObject.campaign : dynamicObject.name}
            </div>
            <div>{moment(event.lastUpdated).format('LLLL')}</div>
          </div>
        )
        return getEventShell(content)
      }
      return null
    }
  }

  // util
  const dispatchUpdateEvent = async () => {
    const event = new Event(updateEeventName)
    // Dispatch the event.
    document.body.dispatchEvent(event)
  }

  const handleLatLngNavigation = (obj) => {
    if (!obj.lat || !obj.long) {
      log.warn("The object has no coordinates");
    }
    const point = L.latLng(obj.lat, obj.long);
    navigate('/map');
    setPoints([point]);
    setDesiredView({ point, zoom: 19 });
  }

  const getEventListComponent = (completeEvent, i = 0) => {
    let isEventLoading = loadingEventIdArray.includes(completeEvent.event.id)
    return (
      <div className={'event'} key={i}>
        <div className={'column info event-col'}>
          <div className={'event-data'}>
            {getEventListComponentDetail(completeEvent)}
          </div>
        </div>
        <div className={'action-container event-col'}>
          <div className={'column action'}>
            <Button
              loading={isEventLoading}
              onClick={() => handleLatLngNavigation(completeEvent.event)}
            >
              <i
                className="fas fa-map-marker-alt"
              />
            </Button>
          </div>
          <div className={'column action'}>
            <Button
              loading={isEventLoading}
              onClick={async () => {
                setLoadingEventId(loadingEventIdArray.concat([completeEvent.event.id]))
                await deleteEvent(completeEvent)
                await fetchAll()
                // await fetchEvents(false)
                await dispatchUpdateEvent()
                setLoadingEventId(loadingEventIdArray.filter((id) => id !== completeEvent.event.id))
              }}
            >
              <i
                className="fas fa-trash"
              />
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const getEventsList = () => {
    if (
      !isLoadingEvents
      && fetchEventsCount > 0
      && completeEvents.length === 0
    ) {
      return (
        <div className={'event'}>
          {props.i18n.t('events:table-message-no-events')}
        </div>
      )
    }
    if (
      isLoadingEvents
    ) {
      return (
        <Fragment>
          <Loader indeterminate/>
        </Fragment>
      )
    }

    let paginationEvents = getPaginationElements(filteredEvents)

    let eventComponents = paginationEvents.map((completeEvent, i) => {
      return getEventListComponent(completeEvent, i)
    })
    return eventComponents
  }
  const getContent = () => {
    if (
      context.user
      && context.user.jwtParsed
      && context.user.jwtParsed.Mandant === 'guest'
      && context.user.jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Guest'
    ) {
      return (
        <div>
          <h5 style={{ textAlign: 'center', marginTop: '15px' }}>{props.i18n.t(`events:license-needed`)}</h5>
        </div>
      )
    } else {
      return (
        <div className={'events-container-absolute'}>
          <div className={'page-title'}>
            <h1>{props.t('events:button-my-events')}</h1>
          </div>
          <div className={'controls'}>
            <div className={'advanced'}>
              <div className={'column filter'}>
                <Filter
                  {...props}
                  onFilterChange={async (data) => {
                    setFilter(data)
                    setSelectedPage(1)
                  }}
                />
              </div>
              <div className={'column delete'}>
                <MUIButton
                  variant="contained"
                  color={'primary'}
                  style={{ background: 'red' }}
                  startIcon={<DeleteIcon/>}
                  onClick={async () => {
                    let userWantsToDelete = window.confirm(props.i18n.t('events:table-advanced-delete-all-events-are-you-sure'))
                    if (userWantsToDelete) {
                      await fetchAll()
                      await dispatchUpdateEvent()
                    }
                  }}
                >
                  {props.i18n.t('favorites:table-advanced-delete-all')}
                </MUIButton>
              </div>
            </div>
          </div>

          <div className={'controls paginator'}>
            <div className={'advanced'}>
              <div className={'pagination-container'}>
                <Pagination
                  activePage={selectedPage}
                  siblingRange={1}
                  ellipsisItem={{ content: <Icon name='ellipsis horizontal'/>, icon: true }}
                  firstItem={{ content: <Icon name='angle double left'/>, icon: true }}
                  lastItem={{ content: <Icon name='angle double right'/>, icon: true }}
                  prevItem={{ content: <Icon name='angle left'/>, icon: true }}
                  nextItem={{ content: <Icon name='angle right'/>, icon: true }}
                  totalPages={Math.ceil(filteredEvents.length / maxPerPage)}
                  onPageChange={(event, data) => {
                    setSelectedPage(data.activePage)
                    // scroll up
                    document.querySelectorAll('.event-list')[0].scrollTop = 0
                    // smooth scroll example
                    // document.querySelectorAll(".event-list")[0].scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                />
              </div>
            </div>
          </div>
          {/*<div className={'controls event-progressbar'}>
                  <Progress
                    style={{ width: '100%', margin: '5px' }}
                    size={'tiny'}
                    percent={(completeEvents.length / events.length) * 100}
                    indicating={(() => {
                      if (completeEvents.length === events.length) {
                        return false
                      } else {
                        return true
                      }
                    })()}
                    autoSuccess={true}
                  />
                </div>*/}
          <div className={'event-list'}>
            {getEventsList()}
          </div>
        </div>
      )
    }
  }
  return (
    <div className="favorites">
      <HyperMenu
        {...props}
        ref={menuRef}
        content={(
          <div className={`menu-content-wrapper`}>
            <Navbar
              {...props}
              onMenuToggle={() => {
                let isOpen = menuRef.current.isOpen;
                menuRef.current.setIsOpen(!isOpen);
              }}
              isSearchDisplayed={false}
            />
            <div className={'events-container'}>
              {getContent()}
            </div>
          </div>
        )}
      />
    </div>
  )
}

export default withTranslation(['favorites', 'events', 'panel_construction_zone', 'panel_usage'])(Events)

