/* eslint brace-style: ["error", "stroustrup"] */
import env from "../env/env";
import * as Sentry from "@sentry/browser";
import sentryDefaultOptions from "./sentryDefaultOptions";

// Function to check if performance cookies are allowed
function isPerformanceConsentGiven() {
  try {
    const consentCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('CookieScriptConsent='));

    if (consentCookie) {
      const consentData = JSON.parse(decodeURIComponent(consentCookie.split('=')[1]));
      return consentData.categories.includes("performance");
    }

    return false;
  } catch (e) {
    console.log("Unable to load the CookieScriptConsent cookie");
    return false;
  }
}

switch (env.REACT_APP_GI_ENV) {
  case 'production':
    if (!isPerformanceConsentGiven()) {
      console.log("Sentry tracking is disabled due to lack of performance cookie consent.");
      break;
    }
    Sentry.init({
      dsn: 'https://f9ca6881f1414524a8767173096542d7@logs.services.swissenergyplanning.ch/2',
      environment: 'production',
      ...sentryDefaultOptions,
    });
    break;
  case 'staging':
    Sentry.init({
      dsn: 'https://11c6b1cc67fb4fd7be30c7f694bef23d@logs.services.swissenergyplanning.ch/3',
      environment: 'staging',
      ...sentryDefaultOptions,
    });
    break;
  default:
    Sentry.init({
      dsn: 'https://a12e8bf838a44b59be624b2eec226fb4@logs.services.swissenergyplanning.ch/1',
      environment: 'development',
      ...sentryDefaultOptions,
    });
}
