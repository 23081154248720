import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import env from '../../env/env';
import useLoading from '../../hooks/useLoading';
import SEPContext from '../sep-context/SEPContext';
import {
  createUserSettings, getPanelSettings, getUserSettings, updateUserSettings,
} from './API';
import { DASHBOARD, STANDARD_PANELS } from './constants';
import { getPanelDescription, getPanelId, getPanelName } from './utils';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const DashboardContext = createContext(null);

export default function DashboardProvider({ children }) {
  const [coverage, setCoverage] = useState(DASHBOARD.MID);
  const [tenantPanels, setTenantPanels] = useState([]);
  const [userPanels, setUserPanels] = useState([]);
  const [userPanelSettings, setUserPanelSettings] = useState({});
  const [userSettingsId, setUserSettingsId] = useState(null);

  const { user: { jwt, jwtParsed } } = useContext(SEPContext).SEPContext;
  const [loadingStack, setIsLoading] = useLoading();
  const { t, i18n } = useTranslation();

  const synchronizeUserPanelSettings = (newTenantPanelsState) => {
    setUserPanelSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };

      // remove panels that no longer exist
      Object.keys(updatedSettings).forEach((panelId) => {
        if (!newTenantPanelsState.some((panel) => panel.panelId === panelId)) {
          delete updatedSettings[panelId];
        }
      });

      // add new panels with default settings
      newTenantPanelsState.forEach((panel) => {
        if (!updatedSettings[panel.panelId]) {
          updatedSettings[panel.panelId] = {
            order: Object.keys(updatedSettings).length + 1,
            disabled: false,
          };
        }
      });

      // ensure the order is incremental and without gaps
      const orderedPanelIds = Object.keys(updatedSettings)
        .sort((a, b) => updatedSettings[a].order - updatedSettings[b].order);

      orderedPanelIds.forEach((panelId, index) => {
        updatedSettings[panelId].order = index + 1;
      });

      return updatedSettings;
    });
  };

  useEffect(() => {
    if (!jwtParsed) return;

    (async () => {
      let newTenantPanelsState = [];
      try {
        setIsLoading(true);

        const response = await getPanelSettings(jwt);
        const panelSettings = response.data
          .map((panelSetting) => ({ ...panelSetting, value: JSON.parse(panelSetting.value) }))
          .filter((panelSetting) => panelSetting.value.active)
          .sort((a, b) => {
            const aTranslated = getPanelName(a, i18n.language);
            const bTranslated = getPanelName(b, i18n.language);

            return aTranslated.localeCompare(bTranslated);
          });
        newTenantPanelsState = panelSettings.map((panelSetting) => ({
          panelId: getPanelId(panelSetting, jwtParsed),
          name: getPanelName(panelSetting, i18n.language),
          description: getPanelDescription(panelSetting, i18n.language),
          standard: false,
        }));
      } catch (e) {
        log.error('Failed to load panel settings:', e);
      } finally {
        // always add the standard panels
        STANDARD_PANELS
          .sort((a, b) => a.name(t).localeCompare(b.name(t)))
          .forEach((panelSetting) => {
            newTenantPanelsState.push({
              panelId: panelSetting.panelId(jwtParsed.Mandant),
              name: panelSetting.name(t),
              description: panelSetting.description,
              standard: true,
            });
          });

        synchronizeUserPanelSettings(newTenantPanelsState);
        setTenantPanels(newTenantPanelsState);
        setIsLoading(false);
      }
    })();
  }, [jwt, jwtParsed, setIsLoading, t, i18n.language]);

  useEffect(() => {
    if (!jwtParsed) return;

    (async () => {
      try {
        setIsLoading(true);

        const response = await getUserSettings(jwt);
        const userPanelStateSettings = response.data
          .find((userSetting) => userSetting.settingsType === 'panel_state_settings');

        const settings = userPanelStateSettings ? JSON.parse(userPanelStateSettings.settings) : {};
        setUserPanelSettings(settings);
        setUserSettingsId(userPanelStateSettings ? userPanelStateSettings.id : null);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        log.error(e);
      }
    })();
  }, [jwt, jwtParsed, setIsLoading]);

  useEffect(() => {
    if (Object.keys(userPanelSettings).length === 0) return;

    const enabledPanels = Object.keys(userPanelSettings)
      .filter((panelId) => !userPanelSettings[panelId].disabled);
    setUserPanels(enabledPanels);
  }, [userPanelSettings]);

  const updateServerSettings = useCallback(async (updatedSettings) => {
    try {
      if (userSettingsId) {
        await updateUserSettings(jwt, userSettingsId, 'panel_state_settings', JSON.stringify(updatedSettings));
      } else {
        const response = await createUserSettings(jwt, 'panel_state_settings', JSON.stringify(updatedSettings));
        setUserSettingsId(response.data.id);
      }
    } catch (e) {
      log.error('Failed to update settings:', e);
    }
  }, [jwt, userSettingsId]);

  const enablePanel = useCallback(async (panelId) => {
    if (userPanelSettings[panelId]?.disabled) {
      const updatedSettings = {
        ...userPanelSettings,
        [panelId]: { ...userPanelSettings[panelId], disabled: false },
      };
      setUserPanelSettings(updatedSettings);
      await updateServerSettings(updatedSettings);
    }
  }, [userPanelSettings, updateServerSettings]);

  const disablePanel = useCallback(async (panelId) => {
    // if it is ms panel just disable it
    const panel = tenantPanels.find((p) => p.panelId === panelId);
    if (!panel.standard) {
      const updatedSettings = {
        ...userPanelSettings,
        [panelId]: { ...userPanelSettings[panelId], disabled: true },
      };
      setUserPanelSettings(updatedSettings);
      await updateServerSettings(updatedSettings);
      return true;
    }

    // but if it is standard panel, check if at least one is enabled
    const standardPanels = tenantPanels.filter((p) => p.standard);
    const enabledStandardPanels = standardPanels
      .filter((p) => !userPanelSettings[p.panelId]?.disabled);
    if (enabledStandardPanels.length > 1) {
      const updatedSettings = {
        ...userPanelSettings,
        [panelId]: { ...userPanelSettings[panelId], disabled: true },
      };
      setUserPanelSettings(updatedSettings);
      await updateServerSettings(updatedSettings);
      return true;
    }

    // this happens usually when user tries to disable all standard panels
    return false;
  }, [userPanelSettings, tenantPanels, updateServerSettings]);

  const isEnabled = useCallback((pId) => !userPanelSettings[pId]?.disabled, [userPanelSettings]);

  const movePanel = useCallback(async (panelId, direction) => {
    const currentOrder = userPanelSettings[panelId]?.order;
    if (currentOrder === undefined) return;

    // sort all panels based on their current order, including disabled ones
    const panelsArray = Object.keys(userPanelSettings)
      .sort((a, b) => userPanelSettings[a].order - userPanelSettings[b].order);

    const panelIndex = panelsArray.indexOf(panelId);
    const newIndex = direction === 'up' ? panelIndex - 1 : panelIndex + 1;

    if (newIndex < 0 || newIndex >= panelsArray.length) return;

    const targetPanelId = panelsArray[newIndex];
    const targetOrder = userPanelSettings[targetPanelId].order;

    const updatedSettings = {
      ...userPanelSettings,
      [panelId]: { ...userPanelSettings[panelId], order: targetOrder },
      [targetPanelId]: { ...userPanelSettings[targetPanelId], order: currentOrder },
    };

    setUserPanelSettings(updatedSettings);
    await updateServerSettings(updatedSettings);
  }, [userPanelSettings, updateServerSettings]);

  const sortedTenantPanels = useMemo(() => tenantPanels.slice().sort((a, b) => {
    const orderA = userPanelSettings[a.panelId]?.order ?? Infinity;
    const orderB = userPanelSettings[b.panelId]?.order ?? Infinity;
    return orderA - orderB;
  }), [tenantPanels, userPanelSettings]);

  const sortedUserPanels = useMemo(() => userPanels.slice().sort((a, b) => {
    const orderA = userPanelSettings[a]?.order ?? Infinity;
    const orderB = userPanelSettings[b]?.order ?? Infinity;
    return orderA - orderB;
  }), [userPanels, userPanelSettings]);

  const api = useMemo(() => ({
    coverage,
    setCoverage,
    tenantPanels: sortedTenantPanels,
    userPanels: sortedUserPanels,
    enablePanel,
    disablePanel,
    isPanelEnabled: isEnabled,
    movePanel,
    isLoading: loadingStack.length > 0,
  }), [
    coverage,
    sortedTenantPanels,
    sortedUserPanels,
    loadingStack,
    enablePanel,
    disablePanel,
    isEnabled,
    movePanel,
  ]);
  return (
    <DashboardContext.Provider value={api}>{children}</DashboardContext.Provider>
  );
}

export const useDashboard = () => {
  const ctx = useContext(DashboardContext);
  if (!ctx) {
    throw new Error('useDashboard must be used inside the DashboardProvider');
  }
  return ctx;
};
