export const FAVORITE_TYPE = {
  NO_FILTER: 'ALL',
  ADDRESS: 'ADDRESS',
  REGION: 'REGION',
  POLYGON: 'POLYGON',
};

export const processRegionFavorites = (data) => data.reduce((acc, item) => {
  acc[`${item.id}_${FAVORITE_TYPE.REGION}`] = {
    ...item,
    favoriteType: FAVORITE_TYPE.REGION,
  };
  return acc;
}, {});

export const getAddressName = (address) => {
  const {
    street, houseNumber, swissZipCode, town,
  } = address;

  return `${street || ''} ${houseNumber || ''}, ${swissZipCode || ''} ${town || ''}`.trim();
};

export const processAddressFavorites = (data) => data.reduce((acc, item) => {
  acc[`${item.id}_${FAVORITE_TYPE.ADDRESS}`] = {
    ...item,
    favoriteType: FAVORITE_TYPE.ADDRESS,
    name: getAddressName(item),
  };
  return acc;
}, {});

export const FAVORITES_SORT_BY = {
  NAME_ASC: 'alphabetically',
  NAME_DES: 'alphabeticallyZA',
  LAST_UPDATED_ASC: 'newFirst',
  LAST_UPDATED_DES: 'newLast',
};
