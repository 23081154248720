/* eslint brace-style: ["error", "stroustrup"] */
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { Divider, List, Typography } from '@mui/material';
import FavoriteItem from '../favorite-item/FavoriteItem';

import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import Dimmer from '../../../reusable/Dimmer';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteList() {
  const {
    counter,
    paginatedFavorites,
    isLoading,
    setSelectedFavorites,
    favorites,
  } = useFavorites();
  const { t } = useTranslation('favorites');

  useEffect(() => {
    const onKeyDown = async (event) => {
      let isEscape = false;
      if ('key' in event) {
        isEscape = (event.key === 'Escape' || event.key === 'Esc');
      }
      else {
        isEscape = (event.keyCode === 27);
      }
      if (isEscape) {
        setSelectedFavorites((prevState) => {
          const newState = { ...prevState };
          paginatedFavorites.forEach((favorite) => {
            delete newState[favorite];
          });
          return newState;
        });
      }
    };

    const target = document.getElementById('root');
    target.addEventListener('keydown', onKeyDown);
    return () => {
      target.removeEventListener('keydown', onKeyDown);
    };
  }, [paginatedFavorites, setSelectedFavorites]);

  return (
    <>
      {!isLoading && counter === 0 && (
        <Typography sx={{ maxWidth: '800px' }}>
          {t('no-favorites')}
        </Typography>
      )}
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: `calc(${paginatedFavorites.length ? `47px * ${paginatedFavorites.length}` : '100vh - 124px'})`,
        }}
      >
        {isLoading && <Dimmer />}
        {!isLoading && counter > 0 && paginatedFavorites.length === 0 && (
          <div className="message-no-favorites">
            {t('table-message-no-favorites-filter')}
          </div>
        )}
        {paginatedFavorites.map((key, favoriteIndex) => (
          <Fragment key={JSON.stringify([key, favoriteIndex])}>
            <FavoriteItem favorite={favorites[key]} />
            <Divider flexItem component="li" />
          </Fragment>
        ))}
      </List>
    </>
  );
}
