import { useCallback, useRef, useState } from 'react';

const useLoading = () => {
  // Initialize DelayedStack as a state
  const [loadingStack, setLoadingStack] = useState([]);

  const timeoutId = useRef(null);

  // 'setIsLoading' is a function that accepts a boolean argument 'isLoading'
  const setIsLoading = useCallback((isLoading) => {
    // If 'isLoading' is true, the function adds a 'true'
    // value to the end of the 'loadingStack' array
    // This signifies the start of a new async process
    if (isLoading) {
      setLoadingStack((prevStack) => [...prevStack, true]);
    } else {
      // If 'isLoading' is false, it means an async process has completed, and the function removes
      // a 'true' value from the end of 'loadingStack'
      setLoadingStack((prevStack) => {
        // If 'prevStack' is already empty, there is nothing to remove, so return the original stack
        if (prevStack.length === 0) return prevStack;

        // If there is only one 'true' value in 'loadingStack', it means this is the
        // last async process still running. In this case, the function sets a delay
        // before removing the last 'true' value. This is done to ensure the loading
        // state persists for a minimum amount of time (500ms), which provides a better
        // user experience by preventing the loading state from flickering on and off
        // too quickly when there are multiple short-lived async processes.
        if (prevStack.length === 1) {
          if (timeoutId.current) clearTimeout(timeoutId.current);
          timeoutId.current = setTimeout(() => {
            setLoadingStack([]);
          }, 500);
          return prevStack;
        }

        // If there are multiple 'true' values in 'loadingStack', it means there are
        // other async processes still running. In this case, the function immediately
        // removes the last 'true' value without setting a delay, because there is no
        // need to ensure the loading state persists.
        return prevStack.slice(0, -1);
      });
    }
  }, []);

  return [loadingStack, setIsLoading];
};

export default useLoading;
