import packageJson from "../../package.json";
import * as Sentry from "@sentry/browser";

export default {
  release: packageJson.version,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.1,
  // control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/sep(?:-staging|-dev)?\.energyapps\.ch\//
  ],
  // ignore specific known errors
  ignoreErrors: [
    // string match for specific warning messages
    'Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code.',
    'Please update the following components: SideEffect(NullComponent)',
    'Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of RefFindNode which is inside StrictMode. Instead, add a ref directly to the element you want to reference.',

    // regular expression patterns
    /ReactStrictModeWarnings.flushPendingUnsafeLifecycleWarnings/,
    /findDOMNode is deprecated in StrictMode/,
    /ReactStrictModeWarnings/,
  ],
}
