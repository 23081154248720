import {
  useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Button,
  List,
  ListItemButton,
  Checkbox,
  ListItemText,
  Typography,
  Stack,
  ClickAwayListener, useMediaQuery, Alert,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import { motion, AnimatePresence } from 'framer-motion';
import InfoIcon from '@mui/icons-material/Info';
import { useMap } from '../../../contexts/map/map-context';
import { LAYERS } from '../../../contexts/map/constants';
import ControlButton from './ControlButton';

function MapSelectionControl() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('controls_map');
  const {
    setBaseLayer,
    baseLayer,
    overlayLayers,
    addOverlayLayer,
    removeOverlayLayer,
    availableLayers,
    setOpenLegends,
    setPositions,
    setZIndexes,
  } = useMap();
  const isSmallScreen = useMediaQuery('(max-width:380px)');

  const baseLayers = [
    { id: 'swisstopoGrey', name: 'Swisstopo Grey' },
    { id: 'swisstopoColor', name: 'Swisstopo Color' },
    { id: 'cadastralWebMapColor', name: 'Cadastral Web Map Color' },
    { id: 'swisstopoSatellite', name: 'Swisstopo Satellite' },
  ];

  const handleBaseLayerChange = (layerId) => {
    const selectedBaseLayer = baseLayers.find((layer) => layer.id === layerId);
    if (baseLayer.name !== selectedBaseLayer.id) {
      setBaseLayer(LAYERS[selectedBaseLayer.id]);
    }
  };

  const handleOverlayToggle = (layer) => {
    if (overlayLayers.some((l) => l.id === layer.id)) {
      removeOverlayLayer(layer.id);
    } else {
      addOverlayLayer(layer);
    }
  };

  const handleInfoClick = useCallback((layer) => {
    setOpenLegends((prevDialogs) => {
      const existingDialog = prevDialogs[layer.id];
      return existingDialog ? prevDialogs : { ...prevDialogs, [layer.id]: layer };
    });
    setPositions((prev) => ({
      ...prev,
      [layer.id]: { x: 0, y: 0 },
    }));
    setZIndexes((prev) => ({
      ...prev,
      [layer.id]: Object.keys(prev).length + 1,
    }));
  }, [setOpenLegends, setPositions, setZIndexes]);

  const prodLayers = availableLayers.filter((layer) => !layer.url.includes('wmstest'));
  const testLayers = availableLayers.filter((layer) => layer.url.includes('wmstest'));

  return (
    <div>
      <ControlButton
        isActive={isOpen}
        p={1}
        onClick={() => setIsOpen(!isOpen)}
        className="control map-layer-selection"
      >
        <IconButton sx={{ color: 'white' }}>
          <MapIcon />
        </IconButton>
      </ControlButton>
      <AnimatePresence>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <motion.div
              initial={{ opacity: 0, x: 300 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 300 }}
              transition={{ duration: 0.3 }}
              style={{
                position: 'absolute',
                top: '56px',
                right: '53px',
                width: isSmallScreen ? '280px' : '320px',
                overflowY: 'auto',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                padding: '12px',
                backgroundColor: 'white',
                zIndex: 1000,
              }}
            >
              <Box>
                <Stack mb={2} gap={2}>
                  <Typography fontWeight={700}>
                    {t('select-base-layer')}
                  </Typography>
                  <Box
                    className="base-layers"
                    sx={{
                      display: 'flex',
                      ...(isSmallScreen && {
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                      }),
                    }}
                  >
                    {baseLayers.map((layer) => (
                      <ControlButton
                        key={layer.id}
                        onClick={() => {
                          if (baseLayer.name === layer.id) return;
                          handleBaseLayerChange(layer.id);
                        }}
                        hasHover={baseLayer.name !== layer.id}
                        isPointer={baseLayer.name !== layer.id}
                        isActive={baseLayer.name === layer.id}
                        p={0}
                        sx={{
                          ...(!isSmallScreen && {
                            width: '100%',
                          }),
                          ...(isSmallScreen && {
                            flex: '1 1 calc(50%)',
                            boxSizing: 'border-box',
                            maxWidth: 'calc(50%)',
                          }),
                        }}
                        className={`control ${layer.id}`}
                      >
                        <Button
                          sx={{
                            color: 'white',
                            textTransform: 'none',
                            height: '100%',
                            fontWeight: '800',
                            cursor: baseLayer.name === layer.id ? 'default' : 'pointer',
                          }}
                        >
                          {t(`select-map-${layer.id}`)}
                        </Button>
                      </ControlButton>
                    ))}
                  </Box>
                </Stack>
                <Stack mt={2} gap={1}>
                  <Typography fontWeight={700}>
                    {t('select-overlay-layers')}
                  </Typography>
                  {prodLayers.length === 0 && (
                    <Alert severity="warning">{t('there-are-no-overlay-layers')}</Alert>
                  )}
                  {prodLayers.length > 0 && (
                    <List dense={false} sx={{ mx: '-12px' }}>
                      {prodLayers.map((layer, index) => (
                        <ListItemButton
                          disableGutters
                          key={JSON.stringify([layer.id, index])}
                          onClick={() => handleOverlayToggle(layer)}
                          sx={{ py: '2px' }}
                        >
                          <Checkbox
                            checked={overlayLayers.some((l) => l.id === layer.id)}
                            disableRipple
                          />
                          <ListItemText primary={layer.title} />
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleInfoClick(layer);
                            }}
                            sx={{ mr: '6px' }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </ListItemButton>
                      ))}
                    </List>
                  )}
                  {testLayers.length > 0 && (
                    <>
                      <Typography fontWeight={700}>
                        {t('select-overlay-test-layers-works-only-on-dev-environment')}
                      </Typography>
                      <List dense={false} sx={{ mx: '-12px' }}>
                        {testLayers.map((layer, index) => (
                          <ListItemButton
                            disableGutters
                            key={JSON.stringify([layer.id, index])}
                            onClick={() => handleOverlayToggle(layer)}
                            sx={{ py: '2px' }}
                          >
                            <Checkbox
                              checked={overlayLayers.some((l) => l.id === layer.id)}
                              disableRipple
                            />
                            <ListItemText primary={layer.title} />
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleInfoClick(layer);
                              }}
                              sx={{ mr: '6px' }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </ListItemButton>
                        ))}
                      </List>
                    </>
                  )}
                </Stack>
              </Box>
            </motion.div>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </div>
  );
}

export default MapSelectionControl;
