/* eslint-disable no-underscore-dangle */
/* eslint brace-style: ["error", "stroustrup"] */
import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import * as loglevel from 'loglevel';
import * as L from 'leaflet';
import axios from 'axios';
import env from '../../env/env';
import SEPContext from '../sep-context/SEPContext';
import { LayerMapper } from '../../hooks/constants';
import { LAYERS } from '../map/constants';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const getLeafletBounds = (bounds) => {
  const corner1 = L.latLng(bounds._southWest.lat, bounds._southWest.lng);
  const corner2 = L.latLng(bounds._northEast.lat, bounds._northEast.lng);
  return L.latLngBounds(corner1, corner2);
};

const extractParamValue = (paramName, params) => (params
  .has(paramName) ? decodeURIComponent(params.get(paramName)) : null);

const DeepStateContext = createContext(null);

export default function DeepStateProvider({ children }) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;

  const [deepLinkState, setDeepLinkState] = useState(null);
  const [deepStateLoading, setDeepStateLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // continuePath includes the whole path after root /
    // meaning the actual query params we are targeting are stored in continue path
    const deepLinkParams = urlParams.has('continuePath')
      ? new URLSearchParams(decodeURIComponent(urlParams.get('continuePath')).split('?')[1])
      : urlParams;

    let stateValue = null;
    let sharedStateValue = null;

    if (deepLinkParams.has('state') && !deepLinkParams.has('code')) {
      stateValue = extractParamValue('state', deepLinkParams);
    }
    else if (deepLinkParams.has('sharedState')) {
      sharedStateValue = extractParamValue('sharedState', deepLinkParams);
    }

    if (stateValue) {
      // Decode the 'state' parameter and parse it as JSON
      const decodedState = decodeURIComponent(stateValue);

      try {
        const stateObj = JSON.parse(decodedState);

        // Validate the structure of the state object
        if (
          stateObj.mapState
          && stateObj.mapState.selectedBaseLayer
          && stateObj.mapState.selectedBaseLayer.name
          && stateObj.mapState.selection
          && stateObj.mapState.selection.points
          && stateObj.mapState.bounds
          && stateObj.mapState.bounds._southWest
          && stateObj.mapState.bounds._northEast
        ) {
          // We convert the old sharing state to the shape of new sharing state
          const selectedLayerName = stateObj.mapState.selectedBaseLayer.name;
          const mappedLayerName = LayerMapper[selectedLayerName];
          const newSelectedLayer = LAYERS[mappedLayerName];

          const bounds = getLeafletBounds(stateObj.mapState.bounds);
          const latLngPoints = stateObj.mapState.selection.points
            .map((p) => L.latLng(p.lat, p.lng));

          const newState = {
            version: 1,
            mapState: {
              selectedBaseLayer: {
                name: newSelectedLayer.name,
              },
              bounds,
            },
            selection: {
              points: latLngPoints,
            },
          };

          setDeepLinkState(newState);
          setDeepStateLoading(false);
        }
      }
      catch (error) {
        log.error('Error parsing state parameter:', error);
      }
    }

    if (sharedStateValue && jwt) {
      (async () => {
        const url = `${env.API_GATEWAY_BASE}/api/shared-settings-json/${sharedStateValue}`;
        const response = await axios({
          url,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (response && response.data) {
          const { data } = response;

          const bounds = getLeafletBounds(data.mapState.bounds);
          const latLngPoints = data.selection.points
            .map((p) => L.latLng(p.lat, p.lng));

          const newState = {
            version: 1,
            mapState: {
              selectedBaseLayer: {
                name: data.mapState.selectedBaseLayer.name,
              },
              bounds,
            },
            selection: {
              ...data.selection,
              points: latLngPoints,
            },
          };

          setDeepLinkState(newState);
          setDeepStateLoading(false);
        }
      })();
    }

    if (!stateValue && !sharedStateValue) {
      setDeepStateLoading(false);
    }
  }, [jwt]);

  const api = useMemo(() => ({
    deepLinkState,
    deepStateLoading,
  }), [
    deepLinkState,
    deepStateLoading,
  ]);

  return (
    <DeepStateContext.Provider value={api}>{children}</DeepStateContext.Provider>
  );
}

export const useDeepState = () => {
  const ctx = useContext(DeepStateContext);
  if (!ctx) {
    throw new Error('useDeepState must be used within the DeepStateProvider');
  }

  return ctx;
};
