/* eslint brace-style: ["error", "stroustrup"] */
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as EmailValidator from 'email-validator';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
// import { useConfirm } from 'material-ui-confirm';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';
import { API } from './utils';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import FavoriteApi from '../../services/FavoriteApi';
import useToast from '../../../../hooks/useToast';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function ModalRecipients(props) {
  const {
    targetFavorites, // parent should provide selected or target favorites
    onRecipientsModalClose,
    open,
  } = props;
  const {
    recipients, currentRecipients,
  } = useFavorites();
  const { t } = useTranslation(['favorites', 'events']);
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const toast = useToast();
  // const confirm = useConfirm();

  const [pendingChanges, setPendingChanges] = useState({});
  const [values, setValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  const hasChanges = Object.keys(pendingChanges).length > 0;
  // NOTE: basically there is no UI element which allows you to open this
  // modal by selecting favorites from different groups
  const targetGroups = useMemo(() => {
    const groups = new Set(targetFavorites.map((f) => f.group));
    return [...groups];
  }, [targetFavorites]);
  const favoriteTypes = useMemo(() => targetFavorites.reduce((acc, favorite) => {
    const { favoriteType } = favorite;
    acc.add(favoriteType);
    return acc;
  }, new Set()), [targetFavorites]);
  const existingRecipients = useMemo(() => currentRecipients
    .reduce((acc, recipient) => acc.add(recipient.email), new Set()), [currentRecipients]);

  const pendingActions = useMemo(() => Object.keys(pendingChanges)
    .reduce((acc, change) => {
      if (pendingChanges[change] === 'delete') {
        acc.toDelete.push(change);
      }
      else {
        acc.toAdd.push(change);
      }
      return acc;
    }, {
      toDelete: [],
      toAdd: [],
    }), [pendingChanges]);

  const clearBackendStateForGroup = useCallback(async () => {
    try {
      setIsLoading(true);
      // targetFavorites are always from one group
      const recipientsToClear = targetFavorites.reduce((acc, favorite) => {
        const { favoriteType, recipients: fRecipients } = favorite;
        const emails = fRecipients.map((r) => r.email);
        if (!emails.length) return acc;

        emails.forEach((email) => {
          acc[favoriteType].add(email);
        });
        return acc;
      }, {
        [FAVORITE_TYPE.ADDRESS]: new Set(),
        [FAVORITE_TYPE.REGION]: new Set(),
      // [FAVORITE_TYPE.POLYGON]: new Set(),
      });

      const requests = [];
      Object.entries(recipientsToClear).forEach(([type, emailSet]) => {
        emailSet.forEach((email) => {
          const apiCall = API[type].deleteRecipientByGroup;
          const request = apiCall(jwt, targetGroups[0], email)
            .catch((error) => {
              log.error('Error deleting recipient', error);
            });
          requests.push(request);
        });
      });
      await Promise.all(requests);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      log.error('Error deleting recipient', error);
    }
  }, [targetFavorites, targetGroups, jwt]);

  const addRecipientsToGroup = useCallback(async (emails) => {
    try {
      setIsLoading(true);
      const addRequests = Array.from(favoriteTypes).map((type) => {
        const apiCall = API[type].addRecipientByGroup;
        return emails.map((email) => apiCall(jwt, targetGroups[0], email)
          .catch((error) => {
            log.error('Error adding recipient', error);
          }));
      });

      await Promise.all(addRequests);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      log.error('Error adding recipient', error);
    }
  }, [targetGroups, favoriteTypes, jwt]);

  const showSuccessMessage = useCallback(() => {
    toast.success(t('various:settings-successfully-saved'));
    const pendingToDelete = pendingActions.toDelete;
    setSelectedValues((prev) => {
      const newValues = values.reduce((acc, v) => {
        acc[v.email] = v;
        return acc;
      }, { ...prev });

      // remove recipients that were deleted
      pendingToDelete.forEach((email) => {
        delete newValues[email];
      });

      return newValues;
    });
    setPendingChanges({});
    setValues([]);
  }, [pendingActions, values, t, toast]);

  const updateRecipients = useCallback(async () => {
    try {
      setIsLoading(true);
      const { toAdd, toDelete } = pendingActions;

      // targetFavorites are more than one if user edits group recipients
      // but the group should be one element, in other words users
      // can't edit recipients for many groups at once
      if (targetFavorites.length > 1 && targetGroups.length === 1) {
        const alertText = `${t('override-all-recipients-for-current-selection')}`;
        // const isOk = await confirm({
        //   title: t('delete-confirm-title'),
        //   description: alertText,
        //   confirmationText: t('delete-confirm-ok'),
        //   confirmationButtonProps: { color: 'warning' },
        //   cancellationText: t('delete-confirm-cancel'),
        // })
        //   .then(() => true)
        //   .catch(() => false);
        // eslint-disable-next-line no-alert
        const isOk = window.confirm(alertText);
        if (isOk) {
          await clearBackendStateForGroup(); // delete all recipients
          const createRequests = toAdd // pending to add includes manually entered emails
            .filter((email) => !existingRecipients.has(email))
            .map((email) => FavoriteApi.postRecipient(jwt, email).catch((error) => {
              log.error('Error creating recipient', error);
            }));
          await Promise.all(createRequests);
          // since we cleared the backend state in previous step there are no recipients
          // of targetFavorites anymore, so we need concatenate the pending to add
          // with already selected recipients
          const mergedRecipients = toAdd.concat(Object.keys(selectedValues));
          await addRecipientsToGroup(mergedRecipients);

          showSuccessMessage();
        }
      }
      if (targetFavorites.length === 1) {
        const apiCallDelete = API[targetFavorites[0].favoriteType].deleteRecipient;
        const apiCallAdd = API[targetFavorites[0].favoriteType].addRecipient;

        const requestsToDelete = toDelete
          .map((email) => apiCallDelete(jwt, targetFavorites[0].id, email)
            .catch((error) => {
              log.error('Error adding recipient', error);
            }));
        await Promise.all(requestsToDelete);
        const createRequests = toAdd
          .filter((email) => !existingRecipients.has(email))
          .map((email) => FavoriteApi.postRecipient(jwt, email).catch((error) => {
            log.error('Error creating recipient', error);
          }));
        await Promise.all(createRequests);

        const requestsToAdd = toAdd
          .map((email) => apiCallAdd(jwt, targetFavorites[0].id, email)
            .catch((error) => {
              log.error('Error adding recipient', error);
            }));
        await Promise.all(requestsToAdd);

        showSuccessMessage();
      }
      setIsLoading(false);
    }
    catch (error) {
      log.error('Error updating recipients', error);
      setIsLoading(false);
    }
  }, [
    selectedValues,
    clearBackendStateForGroup,
    existingRecipients,
    showSuccessMessage,
    addRecipientsToGroup,
    targetGroups.length,
    targetFavorites,
    pendingActions,
    jwt,
    t,
  ]);

  // effect which forms the common recipients from all target favorites
  // this effect runs on initial render only
  // (we don't change elsewhere the targetFavorites)
  useEffect(() => {
    const referenceRecipients = targetFavorites[0].recipients;
    // early exit if the referenceRecipients array is empty
    if (referenceRecipients.length === 0) return;

    const recipientsDict = referenceRecipients.reduce((acc, r) => {
      acc[r.email] = r;
      return acc;
    }, {});

    if (targetFavorites.length === 1) {
      // if there's only one favorite, no need for further comparisons
      setSelectedValues(recipientsDict);
      return;
    }

    const referenceEmailsSet = new Set(referenceRecipients.map((r) => r.email));

    const commonRecipients = targetFavorites.reduce((acc, favorite) => {
      favorite.recipients.forEach((r) => {
        if (referenceEmailsSet.has(r.email)) {
          acc[r.email] = recipientsDict[r.email];
        }
      });
      return acc;
    }, {});

    setSelectedValues(commonRecipients);
  }, [targetFavorites]);

  useEffect(() => {
    const { toDelete, toAdd } = pendingActions;
    if (!toAdd.length && !toDelete.length) return;

    setSelectedValues((prev) => Object.keys(prev).reduce((acc, key) => {
      const recipient = prev[key];
      if (toDelete.includes(recipient.email)) {
        delete acc[recipient.email];
      }
      if (toAdd.includes(recipient.email)) {
        if (!existingRecipients.has(recipient.email)) {
          acc[recipient.email] = { email: recipient.email, isConfirmed: undefined };
        }
        else {
          acc[recipient.email] = recipients[recipient.email];
        }
      }
      return acc;
    }, { ...prev }));
  }, [pendingActions, recipients, existingRecipients, hasChanges]);

  const handleChange = async (event, newValues, reason) => {
    if (reason === 'createOption' || reason === 'selectOption') {
      let option = newValues[newValues.length - 1];
      if (option.inputValue) { // user selected non-existing option from dropdown
        option = { email: option.inputValue };
      }
      else if (typeof option === 'string') { // user selected value by clicking enter
        option = { email: option };
      }
      else {
        option = { email: option.email }; // user selected exising option
      }

      const isValid = EmailValidator.validate(option.email)
        && await FavoriteApi.postRecipient(jwt, option.email)
          .then(() => true)
          .catch((error) => error.response.status === 409); // if email exists then true
      if (!isValid) {
        toast.warning(`${option.email} ${t('favorites:not-allowed-email')}.`);
        return; // prevent adding invalid email
      }

      const alreadyExists = values.some((r) => r.email === option.email)
        || Object.keys(selectedValues).some((r) => r.email === option.email);
      if (alreadyExists) {
        toast.warning(`${option.email} ${t('favorites:already-selected')}.`);
        return; // prevent adding duplicate email
      }

      setPendingChanges((prev) => ({ ...prev, [option.email]: 'add' }));
    }

    const correctedValues = newValues
      .map((v) => {
        if (typeof v === 'string') {
          return { email: v, isConfirmed: undefined };
        }
        if (typeof v === 'object' && v.inputValue) {
          return { email: v.inputValue, isConfirmed: undefined };
        }

        return v;
      });

    setValues(correctedValues); // always update values with corrected ones

    if (reason === 'removeOption') {
      if (!newValues.length) {
        setPendingChanges({});
        return;
      }
      const newPending = newValues.reduce((acc, option) => {
        const newAcc = { ...acc };
        if (!newAcc[option.email]) {
          newAcc[option.email] = 'add';
        }

        return newAcc;
      }, {});
      setPendingChanges(newPending);
    }
  };

  const handleDelete = (email) => {
    setPendingChanges((prev) => ({
      ...prev,
      [email]: 'delete',
    }));
  };

  // Modify or create a new filterOptions function
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.email || option,
  });

  // Adjust the getOptionLabel function to handle new options
  const getOptionLabel = useCallback((option) => (option.inputValue
    ? `${option.inputValue} (${t('create-new-recipient')})`
    : option.email), [t]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      onClose={() => onRecipientsModalClose(isPristine)}
    >
      <DialogTitle>
        {t('recipients-settings')}
        {targetFavorites.length > 1 && (
          <Typography variant="caption" component="p">
            {`${t('events:settings-for-group')}: ${targetGroups[0]}`}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          {Object.values(selectedValues).length === 0 && (
            <Typography>
              {t('no-recipients')}
            </Typography>
          )}
          {Object.values(selectedValues).length > 0
            && targetFavorites.length > 1
            && Object.keys(pendingChanges).length === 0 && (
            <Typography fontWeight={800}>
              {t('events:common-recipients')}
            </Typography>
          )}
          {Object.values(selectedValues).length > 0 && (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {Object.keys(selectedValues).map((email) => (
              <Chip
                key={email}
                size="small"
                label={`${email} (${selectedValues[email].isConfirmed ? t('email-confirmation-confirmed') : t('email-confirmation-pending')})`}
                onDelete={() => handleDelete(email)}
              />
            ))}
          </Stack>
          )}
          <Autocomplete
            multiple
            freeSolo
            disableCloseOnSelect
            value={values}
            options={currentRecipients.filter((r) => !Object
              .keys(selectedValues).includes(r.email))}
            getOptionLabel={getOptionLabel}
            filterOptions={(opts, params) => {
              const filtered = filterOptions(opts, params);
              if (filtered.length === 0 && params.inputValue !== '') {
                return [{
                  label: `${params.inputValue} (${t('create-new-recipient')})`,
                  inputValue: params.inputValue,
                }];
              }
              return filtered;
            }}
            filterSelectedOptions
            renderOption={(_, option, state, ownerState) => (
              <ListItemButton
                key={option.email}
                {..._} // eslint-disable-line react/jsx-props-no-spreading
              >
                <ListItemText primary={ownerState.getOptionLabel(option)} />
                {option.isConfirmed !== undefined && (
                  <Typography>
                    {option.isConfirmed ? t('email-confirmation-confirmed') : t('email-confirmation-pending')}
                  </Typography>
                )}
              </ListItemButton>
            )}
            renderInput={(params) => (
              <TextField
                {...params} // eslint-disable-line react/jsx-props-no-spreading
                variant="standard"
                label={t('select-target-recipients')}
                placeholder={t('select-target-recipients')}
              />
            )}
            renderTags={(value, getTagProps) => value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                label={option.email || option}
                {...getTagProps({ index })} // eslint-disable-line react/jsx-props-no-spreading
              />
            ))}
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      {isLoading && (
        <LinearProgress />
      )}
      <DialogActions>
        <Button onClick={() => onRecipientsModalClose(isPristine)}>
          {t('button-modal-cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            await updateRecipients();
            setIsPristine(false);
          }}
          disabled={!hasChanges || isLoading}
        >
          {t('button-modal-notification-update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
