import axios from 'axios'
import * as loglevel from "loglevel";
import env from "../../../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

const initAuthInterceptor = (onUnauthorized) => {
  // Add a request interceptor
  return axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      let pathName = window.location.pathname
      if (
          (
              pathName.startsWith("/user/") === false
              && pathName !== "/user/edit/"
          )
        && pathName.startsWith("/admin/panels/") === false
        && pathName.startsWith("/favorites/confirmation") === false
      ) {
        onUnauthorized(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error)
    }
  })
}

const removeAuthInterceptor = (interceptor) => {
  // Remove a request interceptor
  try {
    axios.interceptors.response.eject(interceptor)
  } catch (e) {
    log.error(e)
  }
}

export {
  initAuthInterceptor,
  removeAuthInterceptor
}
