import {
  useCallback, useContext, useState, Suspense, lazy,
} from 'react';

import './PanelContainer.css';
import * as loglevel from 'loglevel';
import env from '../../../../env/env';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import DynamicPanels from '../cards/dynamic_panels/DynamicPanels';
import { useDashboard } from '../../../../contexts/dashboard/DashboardProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

// Lazy load panel components
const Heat = lazy(() => import('../cards/heat/Heat'));
const Co2Emission = lazy(() => import('../cards/co2/Co2Emission'));
const BuildingInfo = lazy(() => import('../cards/building_info/BuildingInfo'));
const ConstructionProjects = lazy(() => import('../cards/construction_projects/ConstructionProjects'));
const Elcom = lazy(() => import('../cards/elcom/Elcom'));
const SolarElectricity = lazy(() => import('../cards/solar_electricity/SolarElectricity'));
const Usage = lazy(() => import('../cards/usage/Usage'));

const PANEL_COMPONENTS = {
  Heat,
  Co2Emission,
  BuildingInfo,
  ConstructionProjects,
  Elcom,
  SolarElectricity,
  Usage,
};

function PanelContainer(props) {
  const [isPortalVisible, setIsPortalVisible] = useState(false);
  const [isTilesVisible, setIsTilesVisible] = useState(true);
  const { user: { jwtParsed } } = useContext(SEPContext).SEPContext;
  const { userPanels, isLoading } = useDashboard();

  const setParentState = useCallback((propertyName, propertyValue) => {
    if (propertyName === 'isPortalVisible') {
      setIsPortalVisible(propertyValue);
    }
    if (propertyName === 'isTilesVisible') {
      setIsTilesVisible(propertyValue);
    }
    return Promise.resolve();
  }, [setIsPortalVisible, setIsTilesVisible]);

  const getCards = () => {
    const cards = [];

    const role = jwtParsed
      ? jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      : undefined;

    // If the user is a guest, return an empty array (no cards)
    if (role === 'Guest') {
      return null;
    }

    userPanels.forEach((panelId) => {
      const panelKey = panelId.replace(`${jwtParsed.Mandant}_`, '');
      const PanelComponent = PANEL_COMPONENTS[panelKey];

      if (PanelComponent) {
        cards.push(
          <Suspense fallback={<div>Loading...</div>} key={panelId}>
            <PanelComponent
              setParentState={setParentState}
            />
          </Suspense>,
        );
      }
    });

    return cards;
  };

  const cards = getCards();

  return (
    <div className="PanelContainer">
      <div id="dashboard-portal" className={`${isTilesVisible ? 'invisible' : 'visible'}`} />
      {!isLoading && (
        <div className={`PanelContainer-flex ${isTilesVisible ? 'visible' : 'invisible'}`}>
          <DynamicPanels
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            setParentState={setParentState}
            isPortalVisible={isPortalVisible}
            isTilesVisible={isTilesVisible}
          />
          {cards}
        </div>
      )}
    </div>
  );
}

export default PanelContainer;
