import {
  useCallback, useEffect, useState,
} from 'react';
import './AccountConfirm.css';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SupportRequest from '../../reusable/SupportRequest';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.DEBUG : loglevel.levels.WARN,
);

export default function AccountConfirm() {
  const { t } = useTranslation(['sign_up'], { useSuspense: true });
  const [messages, setMessages] = useState([
    t('sign_up:confirm-processing-1'),
  ]);
  const [hasAskedConfirmation, setHasAskedConfirmation] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const confirmUri = searchParams.get('confirmUri');
  const token = searchParams.get('token');
  const userid = searchParams.get('userid');

  const confirm = useCallback(() => axios(
    {
      method: 'post',
      url: `${confirmUri}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        token, userid,
      }),
    },
  ), [confirmUri, token, userid]);
  useEffect(() => {
    if (hasAskedConfirmation) {
      log.info('Confirmation has already been asked.');
    } else {
      setHasAskedConfirmation(true);
      const startConfirmationProcess = async () => {
        setMessages((p) => p.concat([t('sign_up:confirm-processing-2')]));
        // eslint-disable-next-line react/destructuring-assignment
        await confirm()
          .then((axiosRes) => {
            setMessages((p) => p.concat(
              [t('sign_up:confirm-processing-done')],
            ));
            return axiosRes;
          })
          .catch(async (e) => {
            setMessages((p) => p.concat([
              `${t('sign_up:confirm-processing-done')}<br/> Error: ${e.message}.`,
            ]));
            return null;
          });
        setTimeout(() => {
          log.info('Redirecting the user to the login.');
          // eslint-disable-next-line react/destructuring-assignment
          navigate('/user/signin/');
        }, 3000);
      };
      startConfirmationProcess();
    }
  }, [confirm, hasAskedConfirmation, navigate, t]);
  return (
    <div className="AccountConfirm">
      <h1>{t('sign_up:confirm-header')}</h1>
      <h3>
        <div
            /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: messages[messages.length - 1],
          }}
        />
      </h3>
      <SupportRequest />
    </div>
  );
}
