/* eslint brace-style: ["error", "stroustrup"] */
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';

import { useEffect, useState, useMemo } from 'react';
import {
  Divider, Paper, Stack, Button,
  FormControl, IconButton, InputBase, InputLabel, MenuItem, Select, Menu,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';
import FavoriteSort from '../favorite-sort/FavoritesSort';
import FavoriteGroupFilter from '../favorite-group-filter/FavoriteGroupFilter';
import FavoriteActionBar from '../favorite-action-bar/FavoriteActionBar';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteFilter() {
  const {
    nameFilter,
    setNameFilter,
    filterType,
    setFilterType,
    selectedFavorites,
    filterGroup,
    setFilterGroup,
    favorites,
    sortedFavorites,
    setItemsPerPage,
    setSelectedFavorites,
    setCurrentPage,
    paginatedFavorites,
  } = useFavorites();
  const { t } = useTranslation('favorites');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [typeSelected, setTypeSelected] = useState(filterType);

  const targetFavorites = useMemo(
    () => Object.keys(selectedFavorites)
      .filter((favoriteKey) => paginatedFavorites.includes(favoriteKey))
      .map((favoriteKey) => favorites[favoriteKey]),
    [selectedFavorites, favorites, paginatedFavorites],
  );

  useEffect(() => {
    setFilterType(typeSelected);

    return () => {
      setNameFilter('');
      setFilterType(FAVORITE_TYPE.NO_FILTER);
    };
  }, [typeSelected, setFilterType, setNameFilter]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const isFilterInactive = useMemo(() => (nameFilter === '' || nameFilter === null)
    && typeSelected === FAVORITE_TYPE.NO_FILTER
    && filterGroup === 'all', [nameFilter, typeSelected, filterGroup]);

  const rowsPerPageOptions = useMemo(() => {
    const dataLength = isFilterInactive
      ? Object.values(favorites).length : sortedFavorites.length;
    const maxOption = Math.min(50, dataLength);
    const o = [25, 10, 5].filter((option) => option <= maxOption);

    if (maxOption === dataLength) {
      o.push(dataLength); // Include actual length if it does not exceed 50
    }
    else {
      o.push(50); // Otherwise, include 50 as the last option
    }
    return o;
  }, [favorites, isFilterInactive, sortedFavorites.length]);

  const nextValidOption = useMemo(() => [25, 10, 5].find((option) => rowsPerPageOptions
    .includes(option)) || rowsPerPageOptions[0], [rowsPerPageOptions]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={2}
      >
        <Paper
          square
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={t('label-filter-global')}
            onChange={(e) => {
              setNameFilter(e.target.value);
            }}
            inputProps={{
              className: 'favorite-search-input',
            }}
            value={nameFilter || ''}
          />
          <IconButton type="button" sx={{ p: '10px' }}>
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            sx={{ p: '10px' }}
            onClick={handleFilterClick}
          >
            {isFilterInactive ? <FilterListIcon /> : <FilterListOffIcon sx={{ color: 'red' }} />}
          </IconButton>
        </Paper>
        {Object.keys(targetFavorites).length > 0 && <FavoriteActionBar />}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleFilterClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            style: {
              minWidth: '250px',
            },
          },
        }}
      >
        <MenuItem disableRipple>
          <FavoriteGroupFilter closeMenu={handleFilterClose} />
        </MenuItem>
        <MenuItem>
          <FormControl fullWidth>
            <InputLabel id="filter-by-type">{t('filter-by-type-label')}</InputLabel>
            <Select
              fullWidth
              labelId="filter-by-type"
              value={typeSelected}
              label={t('filter-by-type-label')}
              onChange={(event) => {
                setCurrentPage(1);
                setSelectedFavorites({});
                setTypeSelected(event.target.value);
              }}
              size="small"
            >
              <MenuItem value={FAVORITE_TYPE.NO_FILTER}>{t('no-filter')}</MenuItem>
              <MenuItem value={FAVORITE_TYPE.ADDRESS}>{t('filter-by-address')}</MenuItem>
              <MenuItem value={FAVORITE_TYPE.REGION}>{t('filter-by-regions')}</MenuItem>
              <MenuItem value={FAVORITE_TYPE.POLYGON}>{t('filter-by-polygons')}</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <FavoriteSort />
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setItemsPerPage(nextValidOption);
              setNameFilter('');
              setTypeSelected(FAVORITE_TYPE.NO_FILTER);
              setFilterGroup('all');
            }}
            disabled={isFilterInactive}
            startIcon={<FilterListOffIcon />}
          >
            {t('clear-filters')}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
