/* eslint brace-style: ["error", "stroustrup"] */
import * as loglevel from 'loglevel';
import { TablePagination, useMediaQuery } from '@mui/material';

import './FavoritePagination.css';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoritePagination() {
  const {
    setCurrentPage,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    sortedFavorites,
    setSelectedFavorites,
    paginatedFavorites,
  } = useFavorites();
  const { t } = useTranslation('favorites');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangeRowsPerPage = (event) => {
    const newRows = parseInt(event.target.value, 10);
    setCurrentPage(1);
    setRowsPerPage(newRows);
    setItemsPerPage(newRows);
    setSelectedFavorites({});
  };

  const rowsPerPageOptions = useMemo(() => {
    const maxOption = Math.min(50, sortedFavorites.length);
    const options = [5, 10, 25].filter((option) => option <= maxOption);
    if (maxOption === sortedFavorites.length) {
      options.push(sortedFavorites.length);
    }
    else {
      options.push(50);
    }
    return options;
  }, [sortedFavorites.length]);

  useEffect(() => {
    const newItemsPerPage = rowsPerPageOptions[rowsPerPageOptions.length - 1];
    setRowsPerPage(itemsPerPage || newItemsPerPage);
  }, [itemsPerPage, rowsPerPageOptions]);

  const getDisplayedRowsLabel = ({ from, to, count }) => {
    if (isMobile) {
      return `${from}–${to}`;
    }

    return `${from}–${to} ${t('pagination-of')} ${count}`;
  };

  return (
    <TablePagination
      className="FavoritePagination"
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      labelRowsPerPage={t('rows-per-page')}
      labelDisplayedRows={getDisplayedRowsLabel}
      count={sortedFavorites.length}
      page={currentPage - 1}
      onPageChange={(e, page) => setCurrentPage(page + 1)}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      slotProps={{
        select: {
          style: {
            margin: 0,
            marginBottom: '-2px',
          },
        },
      }}
      disabled={!paginatedFavorites.length}
    />
  );
}
