import './Home.css';

import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HyperMenu from '../hyper_menu/HyperMenu';
import Navbar from '../sep_map/component_navbar/Navbar';
import env from '../../env/env';
import MicrosoftLoginButton from '../reusable/MicrosoftLoginButton';
import TermsOfUseAgreement from '../reusable/TermsOfUseAgreement';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

function Home(props) {
  const menuRef = useRef(null);
  const { t } = useTranslation('home');
  const { appState: { isAuthenticated } } = props;
  const navigate = useNavigate();
  const getWelcomeComponent = useCallback(() => (
    <div>
      <h1>{t('h1-welcome')}</h1>
      <h4>
        {t('h4-welcome-long')}
      </h4>
    </div>
  ), [t]);

  const getHomeComponent = useCallback(() => {
    if (isAuthenticated === false) {
      return (
        <div className="container">
          <div className="heading">
            <Stack gap={2}>
              <img
                className="image"
                alt=""
                src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
              />
              {getWelcomeComponent()}
              <div className="button-container">
                <Button
                  onClick={() => {
                    navigate('/user/signup/');
                  }}
                  className="menu-button"
                  primary
                >
                  {t('button-registration')}
                </Button>
                <Button
                  onClick={() => {
                    navigate('/user/signin/');
                  }}
                  className="menu-button"
                  primary
                >
                  {t('button-signin')}
                </Button>
                <MicrosoftLoginButton />
              </div>
              <TermsOfUseAgreement />
            </Stack>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          {getWelcomeComponent()}
          <div className="button-container">
            <Button
              size="medium"
              primary
              onClick={() => {
                navigate('/map/');
              }}
            >
              {t('button-map')}
            </Button>
          </div>
        </div>
      </div>
    );
  }, [getWelcomeComponent, isAuthenticated, navigate, t]);

  return (
    <div className="Home">
      <HyperMenu
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        ref={menuRef}
        content={(
          <div className="menu-content-wrapper">
            <Navbar
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              onMenuToggle={() => {
                const { isOpen } = menuRef.current;
                menuRef.current.setIsOpen(!isOpen);
              }}
              isSearchDisplayed={false}
            />
            {getHomeComponent()}
          </div>
        )}
      />
    </div>
  );
}

export default Home;
