/* eslint brace-style: ["error", "stroustrup"] */
import { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw-src';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import './SEPMap.css';
import * as L from 'leaflet';
import { useMap } from '../../contexts/map/map-context';
import Controls from './overlay_controls/Controls';
import { OverlayDashboard } from './overlay_dashboard/OverlayDashboard';
import Selection from './component_selection/Selection';
// eslint-disable-next-line import/no-cycle
import SuspenceLoader from '../suspence_loader/SuspenceLoader';

function SEPMap(props) {
  const {
    mapRef,
    setMapInitialized,
  } = useMap();

  const mapId = 'sep-map-leaflet';

  useEffect(() => {
    // Initialize the map
    // NOTE: by initialization we can't use the state zoom or position
    // values since this would mean infinite loop
    mapRef.current = L.map(mapId, {
      zoomDelta: 1,
      zoomSnap: 1,
      minZoom: 7,
      maxZoom: 21,
      wheelPxPerZoomLevel: 60,
      scrollWheelZoom: true,
      zoomControl: false,
      attributionControl: false,
      doubleClickZoom: false,
      inertia: true,
      maxBounds: [[49.152970, 3.847413], [44.653024, 14.626486]],
      editable: true,
      preferCanvas: true,
    });
    setMapInitialized(true);

    return () => {
      // Cleanup the map instance when the component is unmounted
      setMapInitialized(false);
      mapRef.current.remove();
    };
  }, [mapRef, setMapInitialized]);

  // eslint-disable-next-line react/destructuring-assignment
  if (props.appState.hasLoaded === false) {
    return (
      <SuspenceLoader />
    );
  }

  return (
    <div className="SEPMap">
      <div className="map-stack leaflet">
        <div className="map-layer leaflet selected-map-layer-leaflet">
          <div id={mapId} className="Leaflet" />
        </div>
        <div className="overlay-layer controls">
          <Controls />
        </div>
        <div className="overlay-layer dashboard">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <OverlayDashboard {...props} />
        </div>
        <div className="component-selection">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Selection {...props} />
        </div>
      </div>
    </div>
  );
}

export default SEPMap;
