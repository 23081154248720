// eslint-disable-next-line import/prefer-default-export
export const emotion = {
  paper: {
    borderRadius: '0px',
  },
  button: {
    background: 'var(--gi_color_dark_primary_color)',
    '&:hover': {
      background: 'var(--gi_color_accent_color)',
    },
    borderRadius: '0px',
    color: 'var(--gi_color_alt_text)',
  },
  buttonClose: {
    background: 'var(--gi_color_secondary_text)',
    '&:hover': {
      background: 'var(--gi_color_accent_color)',
    },
    borderRadius: '0px',
    color: 'var(--gi_color_alt_text)',
  },
};
