export const DASHBOARD = {
  HIGH: 100,
  MID: 48,
  LOW: 18,
};

export const STANDARD_PANEL_NAMES = {
  Heat: 'dynamic_panel_heat:panel-name',
  Co2Emission: 'panel_co2:panel-name',
  BuildingInfo: 'panel_building_info:label-panel-name',
  ConstructionProjects: 'panel_construction_projects:label-panel-name',
  Elcom: 'panel_electricity:label-panel-name',
  SolarElectricity: 'panel_solar_electricity:label-panel-name',
  Usage: 'panel_usage:label-panel-name',
};

export const STANDARD_PANELS = [{
  panelId: (tenant) => `${tenant}_Heat`,
  name: (t) => t(STANDARD_PANEL_NAMES.Heat),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_Co2Emission`,
  name: (t) => t(STANDARD_PANEL_NAMES.Co2Emission),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_BuildingInfo`,
  name: (t) => t(STANDARD_PANEL_NAMES.BuildingInfo),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_ConstructionProjects`,
  name: (t) => t(STANDARD_PANEL_NAMES.ConstructionProjects),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_Elcom`,
  name: (t) => t(STANDARD_PANEL_NAMES.Elcom),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_SolarElectricity`,
  name: (t) => t(STANDARD_PANEL_NAMES.SolarElectricity),
  description: null,
}, {
  panelId: (tenant) => `${tenant}_Usage`,
  name: (t) => t(STANDARD_PANEL_NAMES.Usage),
  description: null,
}];
