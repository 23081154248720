/* eslint brace-style: ["error", "stroustrup"] */
import {
  useCallback,
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  IconButton, Box, Button, ClickAwayListener,
  Stack, Typography, TextField, LinearProgress,
  DialogActions, Link, Dialog, DialogTitle,
  DialogContent, DialogContentText, CircularProgress,
  FormControlLabel, Checkbox,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@mui/icons-material/Share';
import axios from 'axios';
import { emotion } from './constants';
import Favorites from './Favorites';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import ControlButton from './ControlButton';
import { useSelection } from '../../../contexts/selection/selection-context';
import { getRestoreLink } from './utils';
import { useMap } from '../../../contexts/map/map-context';
import env from '../../../env/env';
import { useFavorites } from '../../../contexts/favorites/FavoriteProvider';
import SelectionExporter from './SelectionExporter';
import useToast from '../../../hooks/useToast';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const createPolygonFavorite = (jwt, context, polygonName, polygons) => axios({
  method: 'post',
  url: `${env.API_GATEWAY_BASE}/api/favoriteselections`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
  data: JSON.stringify({
    context: JSON.stringify(context),
    name: polygonName,
    group: 'default',
    wkt: polygons[0],
  }),
});

function AdvancedControls() {
  const { user: { jwt, jwtParsed } } = useContext(SEPContext).SEPContext;
  const { t } = useTranslation(['controls_map', 'favorites', 'uploader'], { useSuspense: false });
  const {
    selection, polygons, selectionMode, isPolygonMode,
  } = useSelection();
  const {
    mapRef, baseLayer, zoom, bounds,
  } = useMap();
  const { fetchFavorites } = useFavorites();
  const toast = useToast();

  const [isControlPopupOpen, setIsControlPopupOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [link, setLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [polygonNameModalOpen, setPolygonNameModalOpen] = useState(false);
  const [polygonName, setPolygonName] = useState('');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportError, setExportError] = useState(null);
  const [downloadLink, setDownloadLink] = useState('');
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState('');

  const textareaRef = useRef();
  const cancelDataExportToken = useRef(null);

  const role = jwtParsed
    ? jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    : undefined;

  const getShortUrl = async () => {
    if (!mapRef.current) return undefined;
    return getRestoreLink(
      baseLayer.name,
      mapRef.current.getBounds(),
      selection,
      jwt,
      polygons,
      selectionMode,
    );
  };

  const handleFavoritePolygon = async () => {
    const activeLayerName = baseLayer.name;
    const context = {
      map: { activeLayerName, zoom, bounds },
      selection: { polygons },
    };
    try {
      setIsLoading(true);
      await createPolygonFavorite(jwt, context, polygonName, polygons);
      toast.success(`${polygons.length} ${t('favorites:notification-favorites-added')}`);
      setPolygonNameModalOpen(false);
      setIsLoading(false);
      setPolygonName('');
      await fetchFavorites();
    }
    catch (error) {
      log.error('handleFavoritePolygon', error);
      setPolygonNameModalOpen(false);
      setIsLoading(false);
      setPolygonName('');
      toast.warning(t('controls_map:message-favorite-polygon-save-error'));
    }
  };

  const handleExportModalClose = () => {
    setExportError(null);
    setDownloadLink('');
    setFileName('');
    if (cancelDataExportToken.current) {
      cancelDataExportToken.current.cancel('Operation canceled by the user.');
    }
    setIsExportModalOpen(false);
  };

  const hasSelection = useMemo(() => selection?.simple?.length > 0
    || polygons?.length > 0, [selection, polygons]);

  const [uploaderFormState, setUploaderFormState] = useState({});
  const [cargoUploadErrors, setCargoUploadErrors] = useState([]);
  const [cargoUploadLoading, setCargoUploadLoading] = useState(false);

  const handleCheckboxChange = (e, fieldName) => {
    const { checked } = e.target;
    setUploaderFormState((prev) => {
      const newState = { ...prev, [`${fieldName}_save`]: checked };

      if (checked) {
        // Save the current input value to localStorage
        localStorage.setItem(`${jwtParsed.sub}_${fieldName}`, newState[fieldName]);
      }
      else {
        // Remove the saved value from localStorage
        localStorage.removeItem(`${jwtParsed.sub}_${fieldName}`);
      }

      return newState;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploaderFormState((prev) => {
      const newState = { ...prev, [name]: value };

      // Update localStorage if the checkbox for this field is checked
      if (prev[`${name}_save`]) {
        localStorage.setItem(`${jwtParsed.sub}_${name}`, value);
      }

      return newState;
    });
  };

  useEffect(() => {
    if (!jwtParsed) return;
    const storedValue = localStorage.getItem(`${jwtParsed.sub}_phoneNumber`);
    if (storedValue) {
      setUploaderFormState((prev) => ({
        ...prev,
        phoneNumber: storedValue,
        phoneNumber_save: true,
      }));
    }
    else {
      setUploaderFormState((prev) => ({
        ...prev,
        phoneNumber: '',
        phoneNumber_save: false,
      }));
    }
  }, [jwtParsed]);

  const resetFormState = useCallback(() => {
    if (!jwtParsed) return;
    setUploaderFormState((prevState) => {
      const newState = { ...prevState };

      Object.keys(newState)
        .filter((key) => !key.endsWith('_save')) // Only deal with fields that are not "_save" flags
        .forEach((key) => {
          if (!prevState[`${key}_save`]) {
            // If there's no "_save" flag, reset the field value
            newState[key] = ''; // Reset field value
          }
          else {
            // For fields marked to be saved, ensure their value is updated from localStorage
            // This is important if the user manually edited the field and then decided to cancel
            const localStorageValue = localStorage.getItem(`${jwtParsed.sub}_${key}`);
            newState[key] = localStorageValue || '';
          }
        });

      return newState;
    });
    setCargoUploadErrors([]);
  }, [jwtParsed]);

  const onUploadFormSubmit = useCallback(async () => {
    if (!files.length) return;

    const throwError = (response) => {
      response.json().then((error) => {
        throw new Error('Error from server:', error.message);
      }).catch((e) => {
        throw new Error('Error parsing server response:', e);
      });
    };

    try {
      setCargoUploadLoading(true);
      const authUrl = `${env.API_GATEWAY_BASE}/api/get-tenant-credentials`;
      const authResponse = await fetch(authUrl, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!authResponse.ok) throwError(authResponse);

      const authData = await authResponse.json();

      const formData = new FormData();
      formData.append('data', JSON.stringify({
        recipient: jwtParsed.sub,
        pin: Math.floor(Math.random() * 9000000) + 1000000,
        mobile_number: uploaderFormState.phoneNumber,
      }));

      files.forEach((f, i) => formData.append(`file${i + 1}`, f, fileName));

      const response = await fetch('https://services.swissenergyplanning.ch/api/v1/cargolinks', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Token token="${authData.cargo}"`,
        },
        body: formData,
      });

      if (!response.ok) throwError(response);

      await response.json();
      toast.success(t('uploader:upload-file-successful'));
      setCargoUploadLoading(false);
      resetFormState();
      handleExportModalClose();
    }
    catch (error) {
      log.error('Error uploading files:', error);
      setCargoUploadLoading(false);
      setCargoUploadErrors([error]);
    }
  }, [files, resetFormState, toast, t, uploaderFormState, jwt, jwtParsed, fileName]);

  const isSendReady = () => !!uploaderFormState.phoneNumber
      && !cargoUploadLoading
      && !isLoading
      && !exportError?.length
      && !cargoUploadErrors.length
      && files.length;

  return role !== 'Guest' && (
    <>
      <ClickAwayListener onClickAway={() => setIsControlPopupOpen(false)}>
        <ControlButton
          isActive={isControlPopupOpen}
          onClick={() => setIsControlPopupOpen(!isControlPopupOpen)}
          className="control menu-button"
        >
          <IconButton sx={{ color: 'white' }}>
            <MoreVertIcon />
          </IconButton>
          {isControlPopupOpen && (
            <Box
              sx={{
                position: 'absolute',
                right: '50px',
                display: 'flex',
                height: '50px',
              }}
            >
              <ControlButton
                sx={{ width: '53px' }}
                p={0}
                hasHover={hasSelection}
                isPointer={hasSelection}
                className="control add-favorite"
                onClick={() => {
                  if (isPolygonMode) {
                    setIsExportModalOpen(true);
                  }
                }}
              >
                <SelectionExporter
                  isOpen={isControlPopupOpen}
                  setIsExportModalOpen={setIsExportModalOpen}
                  setIsLoading={setIsLoading}
                  setDownloadLink={setDownloadLink}
                  setFileName={setFileName}
                  setExportError={setExportError}
                  cancelDataExportToken={cancelDataExportToken}
                  setFiles={setFiles}
                />
              </ControlButton>
              <ControlButton
                sx={{ width: '53px' }}
                p={0}
                hasHover={hasSelection}
                isPointer={hasSelection}
                className="control add-favorite"
                onClick={() => {
                  if (isPolygonMode) {
                    setPolygonNameModalOpen(true);
                  }
                }}
              >
                <Favorites
                  isOpen={isControlPopupOpen}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </ControlButton>
              <ControlButton
                sx={{ width: '53px' }}
                p={0}
                onClick={async () => {
                  setIsDialogOpen(true);
                  const newLink = await getShortUrl();
                  setLink(newLink);
                }}
                className="control share-selection"
              >
                <IconButton
                  id="share-clipboard-button"
                  sx={{ color: 'white', height: '100%' }}
                >
                  <ShareIcon />
                </IconButton>
              </ControlButton>
            </Box>
          )}
        </ControlButton>
      </ClickAwayListener>
      <Dialog
        onClose={() => {
          setIsDialogOpen(false);
        }}
        open={isDialogOpen}
      >
        <DialogTitle>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h3 style={{
              display: 'flex', margin: '0px', justifyContent: 'center', alignItems: 'center',
            }}
            >
              {t('controls_map:copy-to-clip-title')}
            </h3>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setIsDialogOpen(false);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {(() => {
            if (link) {
              return (
                <DialogContentText>
                  <textarea
                    id="share-clipboard-dialog-textarea"
                    value={link}
                    data-link={link}
                    readOnly
                    ref={textareaRef}
                    style={{
                      display: 'flex', flexDirection: 'row', flex: 1, minHeight: '50px', margin: '0px', width: '100%',
                    }}
                  />
                </DialogContentText>
              );
            }
            return (
              <div style={{
                display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center',
              }}
              >
                <CircularProgress />
              </div>
            );
          })()}
          <Button
            disabled={!link}
            css={{
              root: emotion.button,
            }}
            id="share-clipboard-dialog-button"
            onClick={async () => {
              /* Select the text field */
              textareaRef.current.select();
              textareaRef.current.setSelectionRange(0, 99999); /* For mobile devices */
              /* Copy the text inside the text field */
              document.execCommand('copy');
              toast.success(t('controls_map:message-link-copied-ok'));
              setIsDialogOpen(false);
            }}
          >
            {t('controls_map:copy-button-text')}
          </Button>
        </DialogContent>
      </Dialog>
      {polygonNameModalOpen && (
        <Dialog
          open={polygonNameModalOpen}
          scroll="paper"
          maxWidth="sm"
          fullWidth
          onClose={() => setPolygonNameModalOpen(false)}
        >
          <DialogTitle>
            {t('favorite-polygon-name')}
          </DialogTitle>
          <DialogContent dividers>
            <Stack gap={2}>
              <Typography>
                {t('please-define-a-name-for-the-polygon-favorite')}
              </Typography>
              <TextField
                label={t('polygon-name')}
                size="small"
                value={polygonName}
                onChange={(event) => setPolygonName(event.target.value)}
              />
            </Stack>
          </DialogContent>
          {isLoading && (
            <LinearProgress />
          )}
          <DialogActions>
            <Button onClick={() => setPolygonNameModalOpen(false)}>
              {t('button-modal-cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={async () => handleFavoritePolygon()}
              disabled={!polygonName || isLoading}
            >
              {t('button-modal-favorite-polygon-save')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isExportModalOpen && jwtParsed.Mandant !== 'aew' && (
        <Dialog
          open={isExportModalOpen}
          scroll="paper"
          maxWidth="sm"
          fullWidth
          onClose={() => handleExportModalClose()}
        >
          <DialogTitle>
            {t('export-modal-title')}
          </DialogTitle>
          <DialogContent dividers>
            <Stack gap={2}>
              {isLoading && !downloadLink && !exportError && (
                <Typography>
                  {t('we-are-preparing-the-export-file-please-be-patient-and-do-not-close-the-modal')}
                </Typography>
              )}
              {!isLoading && downloadLink && !exportError && (
                <Typography>
                  {t('download-ready-message')}
                </Typography>
              )}
              {!isLoading && !exportError && downloadLink && (
                <Link
                  color="primary"
                  href={downloadLink}
                  download={fileName}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <DownloadIcon sx={{ marginRight: 1 }} />
                  {fileName}
                </Link>
              )}
              {exportError && (
                <Box>
                  <Typography sx={{ color: 'red' }}>
                    {t(exportError)}
                  </Typography>
                </Box>
              )}
              {isLoading && !exportError && (
                <LinearProgress />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleExportModalClose()}>
              {t('button-modal-close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isExportModalOpen && jwtParsed.Mandant === 'aew' && (
        <Dialog
          open={isExportModalOpen}
          fullWidth
          maxWidth="sm"
          onClose={() => handleExportModalClose()}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {t('uploader:aew-uploader-modal-title')}
          </DialogTitle>
          <IconButton
            onClick={() => handleExportModalClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Stack gap={2}>
              {(cargoUploadLoading || (isLoading && !exportError?.length)) && (
                <Typography>
                  {t('we-are-preparing-the-export-file-please-be-patient-and-do-not-close-the-modal')}
                </Typography>
              )}
              {!isLoading && !exportError?.length && (
                <>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: t('uploader:aew-uploader-modal-body'),
                    }}
                  />
                  <Box>
                    <TextField
                      required
                      label={t('uploader:aew-uploader-modal-phoneNumber')}
                      placeholder={t('uploader:aew-uploader-modal-phoneNumber')}
                      type="tel"
                      name="phoneNumber"
                      variant="outlined"
                      value={uploaderFormState.phoneNumber || ''}
                      onChange={(e) => handleInputChange(e, 'phoneNumber')}
                      fullWidth
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={!!uploaderFormState.phoneNumber_save}
                          onChange={(e) => handleCheckboxChange(e, 'phoneNumber')}
                        />
                      )}
                      label={t('uploader:save-the-input-to-browser').replace('{label}', t('uploader:aew-uploader-modal-phoneNumber'))}
                    />
                  </Box>
                </>
              )}
              {!cargoUploadLoading && !isLoading && exportError?.length > 0 && (
                <Box>
                  <Typography sx={{ color: 'red' }}>
                    {t(exportError[0])}
                  </Typography>
                </Box>
              )}
              {!cargoUploadLoading && !isLoading && cargoUploadErrors.length > 0 && (
                <Box>
                  <Typography sx={{ color: 'red' }}>
                    {t('uploader:aew-upload-file-error')}
                  </Typography>
                </Box>
              )}
              {(cargoUploadLoading || (isLoading && !exportError?.length)) && (
                <LinearProgress />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              resetFormState();
              handleExportModalClose();
            }}
            >
              {t('uploader:aew-uploader-modal-cancel')}
            </Button>
            <Button
              onClick={() => onUploadFormSubmit()}
              disabled={!isSendReady()}
            >
              {t('uploader:aew-uploader-modal-submit')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default AdvancedControls;
