import {Suspense, useEffect, useState, useContext, lazy, Component} from 'react';
import axios from 'axios';
import './DynamicPanels.css';

import CircularProgress from '@mui/material/CircularProgress';
import SEPContext from "../../../../../contexts/sep-context/SEPContext";

const MarketsenseV4 = lazy(() => import('./marketsense_v4/Marketsense'));

const Demo = lazy(() => import('./demo/Demo'));
const EnergySimulation = lazy(() => import('./energysimulation/EnergySimulation'));
import * as loglevel from 'loglevel';
import env from "../../../../../env/env";
import {useDashboard} from "../../../../../contexts/dashboard/DashboardProvider";
const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error in dynamic panel', { error });
    this.props.onError();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <h5>Something went wrong. Please check your configuration for this panel or contact the support.</h5>
          <div className="message">
            <h5>settings:</h5>
            {JSON.stringify(this.props.setting)}
            <br />
            <h5>error message:</h5>
            {this.state.error.message}
            <h5>error stack:</h5>
            {this.state.error.stack}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function LoadingPanel() {
  return (
    <div style={{
      display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center',
    }}
    >
      <CircularProgress />
    </div>
  );
}

function DynamicPanel(props) {
  // const fnName = "DynamicPanel";
  const [supportedPanelTypesFromSettings] = useState([
    'marketsense',
    'marketsense_v4',
    'energysimulation',
    'demo',
  ]);
  const { user: { jwtParsed } } = useContext(SEPContext).SEPContext;
  const getPanelShell = (innerComponent) => (
    <div className="dynamic-panel">
      <ErrorBoundary
        {...props}
        onError={() => {
          // go to the dashboard if error occurs
          Promise.all([
            props.setParentState('isPortalVisible', false),
            props.setParentState('isTilesVisible', true),
          ]);
        }}
      >
        {innerComponent}
      </ErrorBoundary>
    </div>
  );
  if (
    supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'marketsense_v4'
    // to debug a specific panel, enable the following line
    // && props.setting.category === 'MarketSense Parzelle'
  ) {
    return getPanelShell(
      <Suspense fallback={<LoadingPanel />}>
        <MarketsenseV4
          {...props}
          panelConfig={props.setting}
          isPortalVisible={props.isPortalVisible}
          isTilesVisible={props.isTilesVisible}
        />
      </Suspense>,
    );
  } if (supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'energysimulation') {
    return getPanelShell(
      <Suspense fallback={<LoadingPanel />}>
        <EnergySimulation {...props} />
      </Suspense>,
    );
  } if (supportedPanelTypesFromSettings.includes(props.setting.panelType) && props.setting.panelType === 'demo') {
    const role = jwtParsed
      ? jwtParsed["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      : undefined;
    if (role === 'Guest') {
      return getPanelShell(
        <Suspense fallback={<LoadingPanel />}>
          <Demo {...props} />
        </Suspense>,
      );
    }
    return null;
  }
  if (props.comesFromSettings && !supportedPanelTypesFromSettings.includes(props.setting.panelType)) {
    log.error(`panelType ${props.setting.panelType} is not supported`, props);
  }
  return null;
}

function DynamicPanels(props) {
  const { user: { jwt, jwtParsed }, env } = useContext(SEPContext).SEPContext;
  const [panelSettings, setPanelSettings] = useState([]);
  const { userPanels } = useDashboard();

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParsedSettings = () => {
    const settings = [];
    const settingsTree = getSettingsTree(panelSettings);
    settingsTree.forEach((listElement) => {
      const settingsJS = {};
      listElement.settingsByTenantAndPanelTypeAndCategory.forEach((s) => {
        settingsJS.category = listElement.category;
        settingsJS.panelType = listElement.panelType;
        settingsJS[s.name] = s.value;
      });
      settings.push(settingsJS);
    });
    return settings;
  };

  const getPanelSettings = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/panelsettings`;
    return await axios({
      method: 'get',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => response)
      .catch((error) => {
        log.error(`DynamicPanels getPanelSettings`, { error });
      });
  };

  const getSettings = async () => {
    const response = await getPanelSettings();
    if (response && response.data) {
      setPanelSettings(response.data);
    }
  };

  const getPropertySet = (elements, property) => [...new Set(elements.map((el) => el[property]))];
  const filterByProperty = (elements, property, propertyValue) => elements.filter((el) => el[property] === propertyValue);

  const getSettingsTree = (panelSettings) => {
    const allTenants = getPropertySet(panelSettings, 'mandant');
    const settingsTree = [];
    allTenants.forEach((tenant) => {
      const tenantSettings = filterByProperty(panelSettings, 'mandant', tenant);
      const panelTypesForTenant = getPropertySet(tenantSettings, 'panelType');
      panelTypesForTenant.forEach((panelType) => {
        const settingsByTenantAndPanelType = filterByProperty(tenantSettings, 'panelType', panelType);
        const categoriesByTenantAndPanelType = getPropertySet(settingsByTenantAndPanelType, 'category');
        categoriesByTenantAndPanelType.forEach((category) => {
          const settingsByTenantAndPanelTypeAndCategory = filterByProperty(settingsByTenantAndPanelType, 'category', category);
          settingsTree.push({
            tenant,
            panelTypesForTenant,
            panelType,
            settingsByTenantAndPanelType,
            categoriesByTenantAndPanelType,
            category,
            settingsByTenantAndPanelTypeAndCategory,
          });
        });
      });
    });
    return settingsTree;
  };

  const getPanels = () => {
    if (!jwtParsed) return;

    const role = jwtParsed
      ? jwtParsed["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      : undefined;

    // Early return if the user is a guest
    if (role === 'Guest') {
      return null;
    }

    const dynamicPanels = [];
    const settings = getParsedSettings();

    // Iterate over userPanels to maintain order
    userPanels.forEach((userPanelId) => {
      const panel = settings.find((setting) => {
        const settingsObject = JSON.parse(setting.setting);
        let settingId = `${jwtParsed.Mandant}_${settingsObject.campaignId}`;
        if (!settingsObject.campaignId && setting.panelType === 'energysimulation') {
          settingId = `${jwtParsed.Mandant}_${setting.panelType}`;
        }
        return settingId === userPanelId;
      });

      if (panel && JSON.parse(panel.setting).active) {
        dynamicPanels.push(<DynamicPanel key={userPanelId} {...props} setting={panel} comesFromSettings />);
      }
    });

    return dynamicPanels;
  };

  return <>{getPanels()}</>;
}

export default DynamicPanels;
