// eslint-disable-next-line no-unused-vars
import React, {  useContext, useEffect } from 'react'
import axios from 'axios'
import * as loglevel from "loglevel";
import env from "../../env/env";
import SEPContext from "../../contexts/sep-context/SEPContext";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

// const LOCIZE_PANEL_NS = "kibana_events";
const requests = {
  postLoginEvent: async function postLoginEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/login?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postBuildingSelectionEvent: async function postBuildingSelectionEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/selection?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postPanelDetailEvent: async function postPanelDetailEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/paneldetail?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postMaketSenseDetailEvent: async function postMaketSenseDetailEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/marketsensequery?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  },
  postEnergySimulationEvent: async function postEnergySimulationEvent(jwt, env, eventInfo){
    return axios(
      {
        method: 'post',
        url: `${env.API_GATEWAY_BASE}/api/userevent/energysimulation?eventinfo=${encodeURIComponent(JSON.stringify(eventInfo || {}))}`,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      }
    )
  }
}
export default function KibanaEvents(props){
  // const fnName = `KibanaEvents`
  // const context = useContext(MarketsenseContext)
  // const { t, i18n } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: true })
    const { user: { jwt }, env } = useContext(SEPContext).SEPContext;

    useEffect(() => {
    async function onKibanaEvent(event){
      async function postEvent(){
        if(event?.detail?.type === "app-opened"){
          requests.postLoginEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "building-selection"){
          requests.postBuildingSelectionEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "panel-detail"){
          requests.postPanelDetailEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "marketsense-detail"){
          requests.postMaketSenseDetailEvent(jwt, env, event?.detail?.eventInfo)
        } else if(event?.detail?.type === "energy-simulation"){
          requests.postEnergySimulationEvent(jwt, env, event?.detail?.eventInfo)
        }
      }
      await postEvent();
    }
    window.addEventListener("kibana-event", onKibanaEvent);

    if(env && jwt){
      const event = new CustomEvent('kibana-event', { detail: {
          type: "app-opened",
          eventInfo: {
            info: "User opened the app and logged in."
          }
        }
      });
      window.dispatchEvent(event);
    }

    return () => {
      window.removeEventListener("kibana-event", onKibanaEvent);
    }
  }, [env, jwt])
  return null;
}
