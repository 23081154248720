/* eslint brace-style: ["error", "stroustrup"] */
import {
  Fragment, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as loglevel from 'loglevel';
// import { useConfirm } from 'material-ui-confirm';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import env from '../../../../env/env';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { API, filterIDsBasedOnEventTypes } from './utils';
import useToast from '../../../../hooks/useToast';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function ModalNotification(props) {
  const {
    targetFavorites, // parent should provide selected or target favorites
    onNotificationModalClose,
    open,
  } = props;
  // TODO: Add polygons back when we start to support them
  const tTargetFavorites = useMemo(() => targetFavorites
    .filter((f) => f.favoriteType !== FAVORITE_TYPE.POLYGON), [targetFavorites]);
  const {
    eventTypes,
    selectedTypes,
  } = useFavorites();
  const { t } = useTranslation(['events', 'favorites']);
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const toast = useToast();
  // const confirm = useConfirm();

  const [selectedEvents, setSelectedEvents] = useState({
    [FAVORITE_TYPE.REGION]: [],
    [FAVORITE_TYPE.ADDRESS]: [],
    // [FAVORITE_TYPE.POLYGON]: [],
  });
  // State to store the initial events selected
  const [initialEvents, setInitialEvents] = useState(selectedEvents);
  const [filterStringOfEvents, setFilterStringOfEvents] = useState({
    [FAVORITE_TYPE.REGION]: '',
    [FAVORITE_TYPE.ADDRESS]: '',
    // [FAVORITE_TYPE.POLYGON]: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [pendingChanges, setPendingChanges] = useState({});
  const [isPristine, setIsPristine] = useState(true);

  const isCountrySelected = useMemo(() => Object.values(tTargetFavorites)
    .some((f) => f.favoriteType === FAVORITE_TYPE.REGION
      && (f.name.toLowerCase() === 'schweiz' || f.name.toLowerCase() === 'svizzera' || f.name.toLowerCase() === 'suisse')
      && f.swissZipCode === null), [tTargetFavorites]);
  const hasChanges = useMemo(() => !!Object.keys(pendingChanges).length, [pendingChanges]);
  const targetGroups = useMemo(() => {
    const groups = new Set(tTargetFavorites.map((f) => f.group));
    return [...groups];
  }, [tTargetFavorites]);

  const changesByType = useMemo(() => selectedTypes.reduce((acc, type) => {
    const ids = Object.keys(pendingChanges).filter((evId) => evId.split('_')[1] === type);

    const toDelete = ids
      .filter((evId) => pendingChanges[evId] === 'delete')
      .map((evId) => parseInt(evId.split('_')[0], 10));

    const toAdd = ids
      .filter((evId) => pendingChanges[evId] === 'add')
      .map((evId) => parseInt(evId.split('_')[0], 10));

    acc[type] = { toDelete, toAdd };
    return acc;
  }, {}), [pendingChanges, selectedTypes]);

  // effect which forms uniform event type ids
  // this effect runs on initial render only
  // (we don't change elsewhere the tTargetFavorites, selectedTypes or eventTypes)
  useEffect(() => {
    selectedTypes.forEach((type) => {
      const initialUniformEventTypes = filterIDsBasedOnEventTypes(eventTypes[type]
        .map((e) => e.id), tTargetFavorites.filter((f) => f.favoriteType === type));
      if (initialUniformEventTypes.length) {
        setSelectedEvents((prev) => ({
          ...prev,
          [type]: initialUniformEventTypes,
        }));
        setInitialEvents((prev) => ({
          ...prev,
          [type]: initialUniformEventTypes,
        }));
      }
    });
  }, [selectedTypes, tTargetFavorites, eventTypes]);

  const clearBackendStateForGroup = useCallback(async () => {
    setIsLoading(true);
    const requests = selectedTypes.reduce((acc, type) => {
      const { toAdd, toDelete } = changesByType[type];
      if (!toAdd.length && !toDelete.length) return acc;

      const eventsToDelete = tTargetFavorites.reduce((eventsAcc, favorite) => {
        const { favoriteType, eventTypes: eTypes } = favorite;
        if (favoriteType !== type) return eventsAcc;

        const updatedEventsAcc = { ...eventsAcc };
        eTypes.forEach((eType) => {
          if (toDelete.includes(eType.id)) {
            updatedEventsAcc[`${eType.id}_${favoriteType}`] = 'delete';
          }
        });

        return updatedEventsAcc;
      }, {});

      Object.keys(eventsToDelete).forEach((eType) => {
        const [id, favoriteType] = eType.split('_');
        const apiCall = API[favoriteType].deleteEventTypeFromGroup;
        const request = apiCall(jwt, id, targetGroups[0]).catch((error) => {
          log.error(`Failed to clear backend state for favorite ${id} and event type ${eType}`, error);
        });
        acc.push(request);
      });

      return acc;
    }, []);
    await Promise.all(requests);
    setIsLoading(false);
  }, [setIsLoading, selectedTypes, changesByType, tTargetFavorites, jwt, targetGroups]);

  const updateNotifications = useCallback(async () => {
    const showSuccessMessage = () => {
      toast.success(t('various:settings-successfully-saved'));
      setInitialEvents(selectedEvents);
      setPendingChanges({});
    };

    try {
      setIsLoading(true);
      // basically user can't open this modal if there are multiple groups,
      // so we can safely clear the backend state for the group
      if (tTargetFavorites.length > 1 && targetGroups.length === 1) {
        const alertTransl = t('favorites:you-are-about-to-overwrite-all-notifications-for-group');
        const alertText = alertTransl.replace('{group}', targetGroups[0]);
        // const isOk = await confirm({
        //   title: t('delete-confirm-title'),
        //   description: alertText,
        //   confirmationText: t('delete-confirm-ok'),
        //   confirmationButtonProps: { color: 'warning' },
        //   cancellationText: t('delete-confirm-cancel'),
        // })
        //   .then(() => true)
        //   .catch(() => false);
        // eslint-disable-next-line no-alert
        const isOk = window.confirm(alertText);
        if (isOk) {
          await clearBackendStateForGroup();
          const addRequests = selectedTypes.map((type) => {
            const { toAdd } = changesByType[type];
            const apiCall = API[type].addEventTypeToGroup;
            return toAdd.map((evId) => apiCall(jwt, evId, targetGroups[0]).catch((error) => {
              log.error(`Failed to update notification for event type ${evId} and group ${targetGroups[0]}`, error);
            }));
          });
          await Promise.all(addRequests);
          showSuccessMessage();
        }
      }

      if (tTargetFavorites.length === 1) {
        const { favoriteType, id: favId } = tTargetFavorites[0];
        const { toDelete, toAdd } = changesByType[favoriteType];
        const apiCallDelete = API[tTargetFavorites[0].favoriteType].deleteEventType;
        const apiCallAdd = API[tTargetFavorites[0].favoriteType].addEventType;

        const requestsToDelete = toDelete.map((evId) => apiCallDelete(jwt, favId, evId)
          .catch((error) => log.error('Error deleting event', error)));
        await Promise.all(requestsToDelete);

        const requestsToAdd = toAdd.map((evId) => apiCallAdd(jwt, favId, evId)
          .catch((error) => log.error('Error adding event', error)));
        await Promise.all(requestsToAdd);

        showSuccessMessage();
      }
      setIsLoading(false);
    }
    catch (error) {
      log.error('Failed to update notifications', error);
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    tTargetFavorites,
    clearBackendStateForGroup,
    changesByType,
    selectedTypes,
    selectedEvents,
    targetGroups,
    jwt, t,
    toast,
  ]);

  const handleCheckboxChange = (evId) => {
    const favType = evId.split('_')[1];
    const evIdInt = parseInt(evId.split('_')[0], 10);
    setPendingChanges((prevChanges) => {
      const previousAction = prevChanges[evId];
      let newAction;
      const newEventChanges = { ...prevChanges };

      const isInitiallySelected = initialEvents[favType].includes(evIdInt);

      // Decision-making based on current action: this toggle approach allows
      // users to intuitively add or remove from their selections,
      // reflecting typical user interaction in UIs.
      if (previousAction) {
        // If toggling the action nullifies the change
        // (e.g., re-selecting a previously added item to remove it),
        // remove it from the tracking object to prevent unnecessary data processing.
        if ((previousAction === 'delete' && isInitiallySelected)
          || (previousAction === 'add' && !isInitiallySelected)) {
          delete newEventChanges[evId]; // Action is reversed or canceled
        }
        else {
          newAction = previousAction === 'add' ? 'delete' : 'add';
          newEventChanges[evId] = newAction;
        }
      }
      else {
        // Initially, when no action is set, determine the action based on whether
        // the event ID is currently selected.
        // Crucial for handling first interactions with each checkbox
        // where the previous state is empty.
        newAction = isInitiallySelected ? 'delete' : 'add';
        newEventChanges[evId] = newAction;
      }

      return newEventChanges;
    });
    setSelectedEvents((prevSelection) => {
      const newSelection = { ...prevSelection };
      if (prevSelection[favType].includes(evIdInt)) {
        newSelection[favType] = prevSelection[favType].filter((id) => id !== evIdInt);
      }
      else {
        newSelection[favType] = [...prevSelection[favType], evIdInt];
      }
      return newSelection;
    });
  };

  const handleAllCheckboxToggle = useCallback((favType) => {
    const allChecked = selectedEvents[favType].length === eventTypes[favType].length;
    const indeterminate = selectedEvents[favType].length > 0
      && selectedEvents[favType].length < eventTypes[favType].length;

    if (indeterminate || allChecked) {
      // If indeterminate or all are checked, uncheck all
      setPendingChanges(eventTypes[favType].reduce((acc, eventType) => {
        if (selectedEvents[favType].includes(eventType.id)) {
          acc[`${eventType.id}_${favType}`] = 'delete'; // Set to delete only if it was previously checked
        }
        return acc;
      }, {}));
      setSelectedEvents((prevSelection) => ({
        ...prevSelection,
        [favType]: [],
      }));
    }
    else {
      // If none are checked, check all
      setPendingChanges(eventTypes[favType].reduce((acc, eventType) => {
        if (!selectedEvents[favType].includes(eventType.id)) {
          acc[`${eventType.id}_${favType}`] = 'add'; // Set to add only if it was previously unchecked
        }
        return acc;
      }, {}));
      setSelectedEvents((prevSelection) => ({
        ...prevSelection,
        [favType]: eventTypes[favType].map((eventType) => eventType.id),
      }));
    }
  }, [selectedEvents, eventTypes]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      onClose={() => onNotificationModalClose(isPristine)}
    >
      {selectedTypes.map((type) => ( // e.g user has selected favorites like REGION, ADDRESS etc.
        <Fragment key={type}>
          <DialogTitle>
            {t(`favorites:notification-${type.toLowerCase()}`)}
            {tTargetFavorites.length === 1 && (
              <Typography variant="caption" component="p">
                {`${t('settings-for-favorite')}: ${tTargetFavorites[0].name}`}
              </Typography>
            )}
            {tTargetFavorites.length > 1 && (
              <Typography variant="caption" component="p">
                {`${t('settings-for-group')}: ${targetGroups[0]}`}
              </Typography>
            )}
            {isCountrySelected && (
              <Typography variant="caption" component="p" sx={{ color: 'red' }}>
                {t('favorites:switzerland-can-only-have-campaign-events')}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: 0,
              ...(eventTypes[type].length <= 4 && {
                padding: '16px',
              }),
              height: '50vh',
            }}
          >
            <List subheader={eventTypes[type].length > 4 && (
              <ListSubheader
                sx={{
                  paddingTop: '16px',
                  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                }}
              >
                <FormControl fullWidth>
                  <FormGroup row sx={{ justifyContent: 'space-between' }}>
                    <TextField
                      label={t('favorites:table-advanced-filter')}
                      size="small"
                      sx={{ flexGrow: '1' }}
                      onChange={(event) => {
                        setFilterStringOfEvents((prev) => ({
                          ...prev,
                          [type]: event.target.value,
                        }));
                      }}
                      disabled={isLoading}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={
                            eventTypes[type].length > 0
                            && selectedEvents[type].length === eventTypes[type].length
                          }
                          indeterminate={
                            selectedEvents[type].length > 0
                            && selectedEvents[type].length < eventTypes[type].length
                          }
                          onChange={() => handleAllCheckboxToggle(type)}
                          className="toggle-all-notifications"
                        />
                      )}
                      label={t('favorites:table-advanced-filter-turn-all')}
                      labelPlacement="start"
                      disabled={filterStringOfEvents[type] !== '' || isCountrySelected || isLoading}
                    />
                  </FormGroup>
                </FormControl>
              </ListSubheader>
            )}
            >
              {eventTypes[type].filter((notification) => {
                const tEventLabel = t(`events:${notification.descriptionLocize}`);
                if (filterStringOfEvents[type].length === 0) return true;
                return tEventLabel.toLowerCase()
                  .indexOf(filterStringOfEvents[type].toLowerCase()) !== -1;
              }).map((notification) => (
                <Fragment key={`item-${type}-${notification.id}-${notification.descriptionLocize}`}>
                  <ListItemButton
                    onClick={() => handleCheckboxChange(`${notification.id}_${type}`)}
                    disabled={isCountrySelected || isLoading}
                  >
                    <ListItemText primary={t(`events:${notification.descriptionLocize}`)} />
                    <Checkbox
                      edge="end"
                      checked={selectedEvents[type].includes(notification.id)}
                      className="notification-toggle"
                      sx={{ px: 0 }}
                    />
                  </ListItemButton>
                  <Divider component="li" />
                </Fragment>
              ))}
            </List>
          </DialogContent>
        </Fragment>
      ))}
      {isLoading && (
        <LinearProgress />
      )}
      <DialogActions>
        <Button onClick={() => onNotificationModalClose(isPristine)}>
          {t('favorites:button-modal-cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            await updateNotifications();
            setIsPristine(false);
          }}
          disabled={!hasChanges || isLoading}
        >
          {t('favorites:button-modal-notification-update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
