import { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';

function PasswordInput({
  placeholder, value, onChange, id,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      id={id}
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      value={value}
      onChange={onChange}
      onPaste={onChange}
      style={{ width: '100%' }}
      autoComplete="new-password"
      icon={(
        <Icon
          name={showPassword ? 'eye slash' : 'eye'}
          link
          onClick={toggleShowPassword}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
          }}
        />
      )}
    />
  );
}

export default PasswordInput;
