import { createTheme } from '@mui/material/styles';

const geoimpact = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba( 0, 0, 0, 0.12)"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#689F38',
    },
    'light_primary': {
      main: '#DCEDC8',
    },
    'light_transparent_primary': {
      main: 'rgba(220,237,200, 0.76)',
    },
    secondary: {
      main: '#0e72b5',
    },
    'accent_transparent': {
      main: 'rgba(14, 114, 181, 0.76)',
    },
    error: {
      main: '#ff0000',
    },
    warning: {
      main: '#ffaa00',
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'Lato',
      'sans-serif',
    ].join(','),
  },
});

export const themes = {
  geoimpact
}
