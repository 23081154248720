/* eslint-env browser */

import React, { useEffect, useState } from 'react';
import './LanguageSwitch.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as loglevel from 'loglevel';
import { CookieUtil } from '../../user_management/cookies/cookie-util';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export function initLangFromCookie(ctx) {
  const key = CookieUtil.constants.names.LANGUAGE;
  const cookieString = CookieUtil.getCookie(key);
  const { i18n } = ctx.props;
  // if the language cookie is in an old format, overwrite it
  const supportedLanguages = [
    'de-CH', 'it-CH', 'fr-CH',
  ];
  if (supportedLanguages.includes(cookieString) === false) {
    const defaultLanguage = 'de-CH';
    CookieUtil.setCookie(
      CookieUtil.constants.names.LANGUAGE,
      defaultLanguage,
    );
    i18n.changeLanguage(defaultLanguage);
  }

  try {
    return new Promise((resolve, reject) => {
      try {
        i18n.changeLanguage(cookieString, () => {
          resolve(cookieString);
        });
      } catch (e) {
        const browserLanguage = window.navigator.language;
        let lang = 'de-CH';
        if (
          browserLanguage.startsWith('de')
        ) {
          lang = 'de-CH';
        } else if (
          browserLanguage.startsWith('fr')
        ) {
          lang = 'fr-CH';
        } else if (
          browserLanguage.startsWith('it')
        ) {
          lang = 'it-CH';
        }
        i18n.changeLanguage(lang, () => {
          try {
            CookieUtil.setCookie(
              CookieUtil.constants.names.LANGUAGE,
              lang,
            );
          } catch (myErr) {
            reject(myErr);
          }
        });
        resolve(lang);
      }
    });
  } catch (e) {
    log.warn(`${'LanguageSwitch'} - warning - could not parse ${key} cookie`, { cookieString });
    return Promise.resolve();
  }
}
function URLLanguage(props) {
  const { i18n } = useTranslation(['settings'], {
    useSuspense: true,
  });
  // const fnName = `URLLanguage`;
  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    props.onLangChange();
    // eslint-disable-next-line
  }, [i18n.language])
  return null;
}

function LanguageSwitch(props) {
  const [targetLanguage, setTargetLanguage] = useState(null);
  const { t, i18n } = useTranslation(['settings'], {
    useSuspense: true,
  });
  const key = CookieUtil.constants.names.LANGUAGE;

  const getFromCookie = () => {
    const cookieString = CookieUtil.getCookie(key);
    let parsedCookie = null;
    try {
      parsedCookie = JSON.parse(cookieString);
      setTargetLanguage(parsedCookie);
    } catch (e) {
      // fallback to default language
      setTargetLanguage('de-CH');
    }
  };

  const setCookie = (lang) => {
    CookieUtil.setCookie(CookieUtil.constants.names.LANGUAGE, lang);
  };
  const switchLanguage = (lang) => new Promise((resolve, reject) => {
    try {
      i18n.changeLanguage(lang, () => {
        setCookie(lang);
        getFromCookie();
        // update moment so that it uses the new language as locale
        moment.locale(targetLanguage.toLowerCase());
        resolve();
      });
    } catch (e) {
      reject(e.message);
    }
  });
  return (
    <div className="LanguageSwitch">
      <URLLanguage
          /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        onLangChange={() => {
          setTargetLanguage(i18n.language);
        }}
      />
      <div className="button-container">
        <button
          type="button"
          className={`de-CH ${
            targetLanguage === 'de-CH' ? 'selected' : null
          }`}
          onClick={async () => {
            await switchLanguage('de-CH');
          }}
        >
          {t('label-de-CH')}
        </button>
        <span> | </span>
        <button
          type="button"
          className={`it-CH ${
            targetLanguage === 'it-CH' ? 'selected' : null
          }`}
          onClick={async () => {
            await switchLanguage('it-CH');
          }}
        >
          {t('label-it-CH')}
        </button>
        <span> | </span>
        <button
          type="button"
          className={`fr-CH ${
            targetLanguage === 'fr-CH' ? 'selected' : null
          }`}
          onClick={async () => {
            await switchLanguage('fr-CH');
          }}
        >
          {t('label-fr-CH')}
        </button>
      </div>
    </div>
  );
}

export default LanguageSwitch;
