import Paper from '@mui/material/Paper';
import React from 'react';

export default function AutocompletePaper(props) {
  /* eslint-disable react/jsx-props-no-spreading,react/destructuring-assignment */
  /* eslint-disable react/no-array-index-key */
  return (
    <Paper elevation={8} {...props} />
  );
  /* eslint-enable react/jsx-props-no-spreading,react/destructuring-assignment */
  /* eslint-enable react/no-array-index-key */
}
