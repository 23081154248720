import axios from 'axios';

export const getBuildingByPoint = async (env, jwt, event) => {
  const endpoint = `${env.API_GATEWAY_BASE}/api/building-by-point?x=${event.latlng.lng}&y=${event.latlng.lat}&srid=4326`;
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const response = await axios({
    method: 'get',
    url: endpoint,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    cancelToken: source.token,
  });

  if (!response.data.length) return null;

  response.data.forEach((building, index) => {
    response.data[index].coordinates = {
      latitude: event.latlng.lat,
      longitude: event.latlng.lng,
    };
  });

  try {
    response.buildingId = response.data[0].id;
  } catch (e) {
    response.buildingId = null;
  }
  response.type = 'info';
  return response;
};

export const getBuildingGeometry = async (env, jwt, buildingInfoResponse) => {
  const { latitude, longitude } = buildingInfoResponse.data[0].coordinates;
  try {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const response = await axios({
      url: `${env.API_GATEWAY_BASE}/api/marketsense/geometries/building-by-coordinates/${longitude}/${latitude}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      cancelToken: source.token,
    });

    try {
      response.buildingId = buildingInfoResponse.data[0].id;
    } catch (e) {
      response.buildingId = null;
    }
    response.type = 'geometry';
    return response;
  } catch (e) {
    return { request: Promise.resolve(null), cancel: null };
  }
};
