const searchResultType = {
  REGION_TOWN: {
    GROUP: 'SEP',
    GROUP_PRIORITY: 4,
    NAME: 'REGION_TOWN',
  },
  REGION_PLZ: {
    GROUP: 'SEP',
    GROUP_PRIORITY: 3,
    NAME: 'REGION_PLZ',
  },
  ADDRESS: {
    GROUP: 'SEP',
    GROUP_PRIORITY: 1,
    NAME: 'ADDRESS',
  },
  PARCEL: {
    GROUP: 'SEP',
    GROUP_PRIORITY: 2,
    NAME: 'PARCEL',
  },
  GOOGLE: {
    GROUP: 'GOOGLE',
    GROUP_PRIORITY: 5,
    NAME: 'GOOGLE',
  },
  GOOGLE_AUTOCOMPLETE: {
    GROUP: 'GOOGLE_AUTOCOMPLETE',
    GROUP_PRIORITY: 6,
    NAME: 'GOOGLE_AUTOCOMPLETE',
  },
};

export default searchResultType;
