import * as loglevel from 'loglevel';
import env from "../../../env/env";
let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

// Function to parse JSON from a cookie string
function parseJSONCookie(cookieString) {
  try {
    return JSON.parse(cookieString);
  } catch (error) {
    console.error("Failed to parse JSON cookie:", error);
    return null; // Return null if parsing fails
  }
}

// Function to get the value of a cookie by name
function getCookieByName(name) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null; // Return null if cookie not found
}

const STRICTLY_NECESSARY_COOKIES = ["USER"];
const OPTIONAL_COOKIE_NAMES = ["LANGUAGE"];
const REQUIRED_COOKIE_CATEGORY = "functionality";

function isCookieAllowed(cookieName) {
  // Allow strictly necessary cookies without checking for consent.
  if (STRICTLY_NECESSARY_COOKIES.includes(cookieName)) {
    return true;
  }

  try {
    const jsonCookieScriptConsent = getCookieByName("CookieScriptConsent");
    const consent = parseJSONCookie(jsonCookieScriptConsent);

    // "CookieScriptConsent" should be present since the script runs on app start.
    // If absent, it indicates a dev mode or pipeline scenario, and it's safe to default to 'true'.
    if (!consent) {
      return true;
    }

    console.log("Cookie-Script cookie:", consent);

    // If the 'action' or 'categories' parameters are missing, treat it as no consent.
    if (!consent.action || !consent.categories) {
      return false;
    }

    // Ensure user has accepted the required cookie category.
    if (consent.action !== "accept" || !consent.categories.includes(REQUIRED_COOKIE_CATEGORY)) {
      return false;
    }

    // Validate if the cookie name is allowed.
    return OPTIONAL_COOKIE_NAMES.includes(cookieName);
  } catch (e) {
    console.error("Error checking cookie allowance:", e);
    return false;
  }
}

function setCookie (cname, cvalue, exdays, path) {
    var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()

  let domain = window.location.hostname
  if (domain === 'localhost') {
    // omit the domain otherwise microsoft browsers wont set the damn cookie
    window.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=' + path
  } else {
    window.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=' + path + ';domain=' + window.location.hostname
  }
}

function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(window.document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

function deleteCookie (name) {
  let domain = window.location.hostname
  if (domain === 'localhost') {
    // omit the domain otherwise microsoft browsers wont set the damn cookie
    window.document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  } else {
    window.document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`
  }
}

class CookieUtilClass {
  constructor () {
    this.constants = {
      COOKIE_ERROR: 'Could not set/read cookies',
      names: {
        USER: 'USER',
        COOKIE: 'COOKIE',
        TOUR: 'TOUR',
        LANGUAGE: "LANGUAGE"

      },
      settings: {
        COOKIE_EXPIRY_DAYS: 365
      }
    }
  }

  setCookie (name, valueString, options = {
    expiryDays: this.constants.settings.COOKIE_EXPIRY_DAYS
  }) {
    // Check if setting the cookie is allowed
    let isAllowed = isCookieAllowed(name);
    if (!isAllowed) {
      log.warn(`Cookie-Script doesn't allow setting the "${name}" cookie.`);
      return;
    }
    setCookie(name, valueString, options.expiryDays, '/')
  }

  getCookie (name) {
    let thisRef = this
    let cookieString = null
    try {
      cookieString = getCookie(name)
    } catch (e) {
      log.error(`${thisRef.constructor.name} getCookie - got ${name} cookieString - error`, { cookieString, e })
    }
    return cookieString
  }

  deleteCookie (name) {
    deleteCookie(name)
  }

  test () {
    let thisRef = this
    log.info(`${thisRef.constructor.name} test`, {})
  }
}

export const CookieUtil = new CookieUtilClass()
