import Fuse from 'fuse.js';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, Drawer, CssBaseline, Divider, IconButton, ListItem,
  ListItemText, TextField, Autocomplete, Chip, Stack, Typography, Collapse
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useCallback, useEffect, useMemo, useState } from "react";
import Dimmer from "../../reusable/Dimmer";
import { useTranslation } from "react-i18next";
import * as loglevel from "loglevel";
import env from "../../../env/env";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Virtuoso } from 'react-virtuoso';

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const drawerWidth = 1240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

SyntaxHighlighter.registerLanguage('javascript', javascript);

export default function MSCatalog({ open, setOpen }) {
  const theme = useTheme();
  const { t } = useTranslation("dynamic_panel_marketsense_v4");
  const [queryParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [catalogData, setCatalogData] = useState([]);
  const [bbApiToken, setBBApiToken] = useState(null);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [mscOptions, setMscOptions] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedMsc, setSelectedMsc] = useState(null);

  const [expanded, setExpanded] = useState({});
  const [error, setError] = useState(null);

  const handleExpandClick = (index) => {
    setExpanded(prevState => ({ ...prevState, [index]: !prevState[index] }));
  };

  const fuse = useMemo(() => {
    return new Fuse(catalogData, {
      keys: ['label'],
      threshold: 0.3,
    });
  }, [catalogData]);

  const filteredOptions = useMemo(() => {
    let result = catalogData;
    if (nameFilter) {
      result = fuse.search(nameFilter).map(({ item }) => item);
    }
    return result.filter(item => {
      const matchesTenant = selectedTenant ? item.expressions.some(exp => exp.references.some(ref => Object.keys(ref).includes(selectedTenant))) : true;
      const matchesMsc = selectedMsc ? item.expressions.some(exp => exp.references.some(ref => ref[selectedTenant]?.includes(selectedMsc))) : true;
      return matchesTenant && matchesMsc;
    });
  }, [catalogData, nameFilter, selectedTenant, selectedMsc, fuse]);

  useEffect(() => {
    if (!bbApiToken) return;
    (async () => {
      try {
        const response = await axios({
          url: "https://api.bitbucket.org/2.0/repositories/geoimpactteam/market-senses/src/main/api.json",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${bbApiToken}`
          }
        });
        setCatalogData(response.data);

        const tenants = new Set();
        const mscs = new Set();

        response.data.forEach(item => {
          item.expressions.forEach(exp => {
            exp.references.forEach(ref => {
              Object.keys(ref).forEach(tenant => {
                tenants.add(tenant);
                ref[tenant].forEach(msc => mscs.add(msc));
              });
            });
          });
        });

        setTenantOptions([...tenants]);
        setMscOptions([...mscs]);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    })();
  }, [bbApiToken]);

  useEffect(() => {
    const bbToken = queryParams.get("bbApiToken");
    if (!bbToken) {
      setError("No Bitbucket API token provided. Please provide a token in the URL query parameters with the key 'bbApiToken'.");
      setIsLoading(false);
      return;
    }

    setBBApiToken(bbToken);
  }, [queryParams]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const evalInContext = useCallback(function evalInContext(expression) {
    try {
      eval(expression);
      return this.res;
    } catch (e) {
      return e.message;
    }
  }, []);

  const evaluateLabel = (label) => {
    return evalInContext.call({ t }, label);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography variant="h6" noWrap>
              Market Sense Details Catalog
            </Typography>
          </Stack>
        </DrawerHeader>
        {isLoading && <Dimmer />}
        <Divider />
        {error && (
          <Box sx={{ padding: 2 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
        {!isLoading && !error && (
          <Box sx={{ padding: 2 }}>
            <TextField
              fullWidth
              label="Search Labels"
              variant="outlined"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Autocomplete
              fullWidth
              options={tenantOptions}
              getOptionLabel={(option) => option}
              value={selectedTenant}
              onChange={(event, newValue) => {
                setSelectedTenant(newValue);
                setSelectedMsc(null); // Reset MSC when tenant changes
              }}
              renderInput={(params) => <TextField {...params} label="Select Tenant" variant="outlined" />}
              sx={{ marginBottom: 2 }}
            />
            <Autocomplete
              fullWidth
              options={mscOptions.filter(msc => {
                if (!selectedTenant) return true;
                return catalogData.some(item =>
                  item.expressions.some(exp =>
                    exp.references.some(ref => ref[selectedTenant]?.includes(msc))
                  )
                );
              })}
              getOptionLabel={(option) => option}
              value={selectedMsc}
              onChange={(event, newValue) => setSelectedMsc(newValue)}
              renderInput={(params) => <TextField {...params} label="Select MSC Panel" variant="outlined" />}
              sx={{ marginBottom: 2 }}
            />
            <Virtuoso
              style={{ height: '70vh' }}
              totalCount={filteredOptions.length}
              itemContent={(index) => {
                const item = filteredOptions[index];
                const expandKey = `${index}-main`;
                const codeExpandKey = `${index}-code`;

                return (
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleExpandClick(expandKey)}>
                            <Typography fontWeight={800}>
                              {evaluateLabel(item.label)}
                            </Typography>
                            {expanded[expandKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </Box>
                          <Collapse in={expanded[expandKey]} timeout="auto" unmountOnExit>
                            <Box
                              component="pre"
                              sx={{
                                backgroundColor: '#f5f5f5',
                                padding: 1,
                                borderRadius: 1,
                                overflowX: 'auto',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                              }}
                            >
                              <SyntaxHighlighter language="javascript" style={docco} wrapLongLines>
                                {item.label}
                              </SyntaxHighlighter>
                            </Box>
                          </Collapse>
                        </Box>
                      }
                      secondary={
                        <>
                          {item.expressions.map((exp, expIndex) => {
                            const expandKey = `${codeExpandKey}-${expIndex}`;
                            return (
                              <Box key={expIndex} sx={{ marginBottom: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleExpandClick(expandKey)}>
                                  <Typography variant="body2" color="textSecondary">
                                    References {exp.references.reduce((acc, ref) => acc + Object.values(ref).flat().length, 0)}
                                  </Typography>
                                  {expanded[expandKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Box>
                                <Collapse in={expanded[expandKey]} timeout="auto" unmountOnExit>
                                  {exp.references.map((ref, refIndex) => (
                                    <Box key={refIndex} sx={{ paddingLeft: 2 }}>
                                      {Object.entries(ref).map(([tenant, mscList]) => (
                                        <Box key={tenant} sx={{ marginBottom: 1 }}>
                                          <Typography variant="body2" color="textPrimary" fontWeight="bold">
                                            {tenant}
                                          </Typography>
                                          <Stack direction="row" gap={0.5} flexWrap="wrap">
                                            {mscList.map(msc => (
                                              <Chip
                                                key={msc}
                                                size="small"
                                                variant="outlined"
                                                label={msc}
                                                sx={{
                                                  marginRight: 1,
                                                  backgroundColor: msc.includes(' ') ? 'lightgreen' : 'lightcoral',
                                                  color: msc.includes(' ') ? 'black' : 'white',
                                                }}
                                              />
                                            ))}
                                          </Stack>
                                        </Box>
                                      ))}
                                    </Box>
                                  ))}
                                </Collapse>

                                <Box sx={{ marginTop: 1, overflowWrap: 'break-word' }}>
                                  <strong>Expression {expIndex + 1}:</strong>
                                  <Box
                                    component="pre"
                                    sx={{
                                      backgroundColor: '#f5f5f5',
                                      padding: 1,
                                      borderRadius: 1,
                                      overflowX: 'auto',
                                      whiteSpace: 'pre-wrap',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    <SyntaxHighlighter language="javascript" style={docco} wrapLongLines>
                                      {exp.expression}
                                    </SyntaxHighlighter>
                                  </Box>
                                </Box>
                                <Divider />
                              </Box>
                            )
                          })}
                        </>
                      }
                    />
                  </ListItem>
                );
              }}
            />
          </Box>
        )}
      </Drawer>
    </Box>
  );
}
