import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  Alert, Button, LinearProgress, Stack, Typography,
} from '@mui/material';
import XLSX from 'xlsx';
import * as loglevel from 'loglevel';
import * as defaultCKANSchema from './schema/default_main_schema.json';
import env from '../../../../env/env';

const convertCSVtoXLSX = (csvString) => XLSX.read(csvString, { type: 'string' });
const downloadWorkbook = (wb, filename) => {
  XLSX.writeFile(wb, filename);
};

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const LOCIZE_PANEL_NS = 'data_catalog';

const dlLinks = {
  'de-CH': 'https://services.swissenergyplanning.ch/metadata/metadata_de.csv',
  'it-CH': 'https://services.swissenergyplanning.ch/metadata/metadata_it.csv',
  'fr-CH': 'https://services.swissenergyplanning.ch/metadata/metadata_fr.csv',
};

// bust the cache for the content that gets loaded from github
const schemaUrl = `https://raw.githubusercontent.com/geoimpactag/ckanext-geoimpact/main/ckanext/geoimpact/scheming/schemas/main_schema.json?v=${Date.now()}`;
const downloadCSV = (lang) => axios.get(dlLinks[lang]).then((r) => r.data);
const splitCellName = (cellAddress) => {
  // Use regular expressions to split the cell name into column and row
  const matches = cellAddress.match(/([A-Z]+)([0-9]+)/);
  if (matches) {
    const column = matches[1]; // The column part (e.g., "AB")
    const row = parseInt(matches[2], 10); // The row part (e.g., 345)
    return {
      row, column,
    };
  }
  return {
    row: null, column: null,
  };
};

const replaceAllCommaSeparatedValues = (ckanSchema, cell, fieldName, targetLanguage) => {
  const values = cell.v.split(',').map((provider) => provider.trim());
  const translatedValues = values.map((value) => ckanSchema
    .dataset_fields
    .find((field) => field.field_name === fieldName)
    .choices.find((choice) => choice.value === value).label[targetLanguage]);
  return translatedValues.join(', ');
};

const replaceLabelsWithTranslations = (wb, ckanSchema, language) => {
  // Iterates the whole Excel file and replaces labels that we have
  // translated in the main_schema.json of CKAN,
  // that is hosted on GitHub

  // Identify the sheet by name
  const sheetName = 'Sheet1';
  const sheet = wb.Sheets[sheetName];

  // The column map is used to store the alphabetical letter that corresponds to the column titles.
  // e.g. "Titel" --> Column "A", "Notes" --> Column "G"
  const columnMapping = {};
  const fieldNameMapping = {
    Title: 'title_translated',
    ShortNotes: 'short_notes',
    Unit: 'unit',
    DataLevel: 'data_level',
    Notes: 'notes_translated',
    Periodicity: 'periodicity',
    DataProviders: 'data_providers',
    DataProcessing: 'data_processing',
    ExampleValues: 'example_values',
    StatsCount: 'stats_count',
    StatsCoverage: 'stats_coverage',
    StatsCount_Not_0: 'stats_count_not_0',
    ExternalDocu: 'external_docu',
    ApiDocu: 'api_docu',
  };
  // Loop through the cells to find and replace "hello"
  Object.keys(sheet).forEach((cellAddress) => {
    const { row, column } = splitCellName(cellAddress);
    if (!row || !column) return; // cellAddress might contain specials cells such as "!ref"
    const cell = sheet[cellAddress];
    const targetLanguage = ckanSchema
      .form_languages
      .find((ckanLang) => language.startsWith(ckanLang));
    try {
      if (row === 1) { // replace all column names
        if (cell.v === 'Title') {
          columnMapping.Title = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.Title).label[targetLanguage];
        }
        if (cell.v === 'ShortNotes') {
          columnMapping.ShortNotes = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.ShortNotes)
            .label[targetLanguage];
        }
        if (cell.v === 'Unit') {
          columnMapping.Unit = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.Unit).label[targetLanguage];
        }
        if (cell.v === 'DataLevel') {
          columnMapping.DataLevel = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.DataLevel).label[targetLanguage];
        }
        if (cell.v === 'Notes') {
          columnMapping.Notes = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.Notes).label[targetLanguage];
        }
        if (cell.v === 'Periodicity') {
          columnMapping.Periodicity = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.Periodicity)
            .label[targetLanguage];
        }
        if (cell.v === 'DataProviders') {
          columnMapping.DataProviders = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.DataProviders)
            .label[targetLanguage];
        }
        if (cell.v === 'DataProcessing') {
          columnMapping.DataProcessing = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.DataProcessing)
            .label[targetLanguage];
        }
        if (cell.v === 'ExampleValues') {
          columnMapping.ExampleValues = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.ExampleValues)
            .label[targetLanguage];
        }
        if (cell.v === 'StatsCount') {
          columnMapping.StatsCount = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.StatsCount)
            .label[targetLanguage];
        }
        if (cell.v === 'StatsCoverage') {
          columnMapping.StatsCoverage = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.StatsCoverage)
            .label[targetLanguage];
        }
        if (cell.v === 'StatsCount_Not_0') {
          columnMapping.StatsCount_Not_0 = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.StatsCount_Not_0)
            .label[targetLanguage];
        }
        if (cell.v === 'ExternalDocu') {
          columnMapping.ExternalDocu = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.ExternalDocu)
            .label[targetLanguage];
        }
        if (cell.v === 'ApiDocu') {
          columnMapping.ApiDocu = column;
          cell.v = ckanSchema
            .dataset_fields
            .find((field) => field.field_name === fieldNameMapping.ApiDocu)
            .label[targetLanguage];
        }
      } else if (row > 1) { // replace all cell values, that are not titles
        if (column === columnMapping.Unit) {
          cell.v = replaceAllCommaSeparatedValues(
            ckanSchema,
            cell,
            fieldNameMapping.Unit,
            targetLanguage,
          );
        }
        if (column === columnMapping.DataLevel) {
          cell.v = replaceAllCommaSeparatedValues(
            ckanSchema,
            cell,
            fieldNameMapping.DataLevel,
            targetLanguage,
          );
        }
        if (column === columnMapping.Periodicity) {
          cell.v = replaceAllCommaSeparatedValues(
            ckanSchema,
            cell,
            fieldNameMapping.Periodicity,
            targetLanguage,
          );
        }
        if (column === columnMapping.DataProviders) {
          cell.v = replaceAllCommaSeparatedValues(
            ckanSchema,
            cell,
            fieldNameMapping.DataProviders,
            targetLanguage,
          );
        }
        if (column === columnMapping.DataProcessing) {
          cell.v = replaceAllCommaSeparatedValues(
            ckanSchema,
            cell,
            fieldNameMapping.DataProcessing,
            targetLanguage,
          );
        }
      }
    } catch (e) {
      e.errorData = {
        cellAddress, row, column, cell, fieldNameMapping, ckanSchema,
      };
      throw e;
    }
  });
  return wb;
};

function DataCatalog() {
  const { t } = useTranslation(LOCIZE_PANEL_NS);

  const [schema, setSchema] = useState(null);
  const [errorLanguage, setErrorLanguage] = useState(null);

  useEffect(() => {
    (async () => {
      const latestSchema = (await axios.get(schemaUrl).then((r) => r).catch(() => (
        {
          data: defaultCKANSchema,
        }
      ))).data;
      setSchema(latestSchema);
    })();
  }, []);

  const handleDownload = useCallback(async (language) => {
    try {
      const csv = await downloadCSV(language);
      let wb = convertCSVtoXLSX(csv);
      wb = replaceLabelsWithTranslations(wb, schema, language);
      downloadWorkbook(wb, `data-catalog-${language}.xlsx`);
    } catch (e) {
      log.error('Could not converted the CSV and download it as XLSX', {
        schema, e,
      });
      setErrorLanguage(language);
      setTimeout(() => {
        setErrorLanguage(null);
      }, [4000]);
    }
  }, [schema]);

  return (
    <Stack spacing={1}>
      <Typography component="h3" variant="b">
        {t('data_catalog:title')}
      </Typography>
      <Typography>
        {t('data_catalog:description')}
      </Typography>
      {!schema && <LinearProgress />}
      {schema && (
        <Stack mt={3} direction="row" spacing={1} justifyContent="space-between">
          {Object.keys(dlLinks).map((language) => (
            <Button
              key={language}
              onClick={() => handleDownload(language)}
              variant="outlined"
            >
              {`${t('download-button')} (${language})`}
            </Button>
          ))}
        </Stack>
      )}
      {errorLanguage && (
        <Alert severity="error">
          {`${t('error-downloading')} (data-catalog-${errorLanguage}.xlsx)`}
        </Alert>
      )}
    </Stack>
  );
}

export default DataCatalog;
