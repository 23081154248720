import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText} from "@mui/material";

function ConfirmationDialog({ title, message, onClose, open }) {
    const { t } = useTranslation('various');

    const handleClose = (isConfirmed) => {
        onClose(isConfirmed);
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    {t('label-button-confirm-cancel')}
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" variant="contained" autoFocus>
                    {t('label-button-confirm-ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;
