import axios from 'axios';
import * as loglevel from 'loglevel';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export const getFavoriteByAddresses = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getFavoriteByRegions = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/favoriteregions`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

const createFavoriteSortingMethod = (jwt) => axios({
  method: 'post',
  url: `${env.API_GATEWAY_BASE}/api/appsettings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
  data: {
    settingsType: 'favorites',
    settings: JSON.stringify({
      sorting: 'alphabetically',
    }),
  },
});

export const getFavoriteSortingMethod = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/appsettings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
})
  .then(async (res) => {
    const s = res.data.filter((settings) => settings.settingsType === 'favorites')[0];
    if (!s) { // if there are no settings, create new ones and return default sorting
      const newSettingsRes = await createFavoriteSortingMethod(jwt);
      if (newSettingsRes.status !== 200) {
        return {
          sort: 'alphabetically',
          id: null,
        };
      }
      const newS = newSettingsRes.data.filter((settings) => settings.settingsType === 'favorites')[0];

      return {
        sort: JSON.parse(newS.settings).sorting,
        id: newS.id,
      };
    }

    return {
      sort: JSON.parse(s.settings).sorting,
      id: s.id,
    };
  })
  .catch((error) => {
    log.error('Can not get favorites sorting method: ', error);

    return {
      sort: 'alphabetically',
      id: null,
    };
  });

export const updateFavoriteSortingMethod = (jwt, id, sorting) => axios({
  method: 'put',
  url: `${env.API_GATEWAY_BASE}/api/appsettings/${id}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
  data: {
    settingsType: 'favorites',
    settings: JSON.stringify({
      sorting,
    }),
  },
});

export const getFavoriteRegionsCount = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/favoriteregions-count`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getFavoriteAddressesCount = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-count`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getEventTypesForAddresses = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/eventtypesforaddresses`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getEventTypesForRegions = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/eventtypesforregions`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getFavoritesByPolygons = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/favoriteselections`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getExistingRecipients = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/recipients`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});
