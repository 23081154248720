import {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import * as L from 'leaflet';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import './Selection.css';
import { themes } from '../../mui/themes';
import { useSelection } from '../../../contexts/selection/selection-context';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import { getBuildingByPoint, getBuildingGeometry } from './API';
import { useMap } from '../../../contexts/map/map-context';
import env from '../../../env/env';

const LOCIZE_PANEL_NS = 'notification_map';
const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export default function Selection() {
  // TODO:
  //  1. Use the points from the MapProvider.
  //  2. Every click on the map should convert it to Leaflet LatLng https://leafletjs.com/reference.html#latlng
  //  3. and save them in the selection setPoints
  //  4. create useEffect that reacts to changes of the points and creates a marker layer
  //  5. update the navigation to the building in
  //     src/components/favorites/components/favorite-item/FavoriteItem.js getMapLinkComponent
  //     it should first create the leaflet LatLng and then update the setPoints
  //     and then navigate to /map without any query parameters
  //  5.1 update the navigation to the building in
  //     src/components/events/Events.js getMapLink
  //     it should do the same steps as in FavoriteItem.js getMapLinkComponent
  //  6. Move all the data retrieval logic into the selection provider.
  //     Inside the selection provider, we need useEffect, which listens to changes
  //     in the points state and retrieves the information based on that state.
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const [selection, setSelection] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [mapClickEvents, setMapClickEvents] = useState([]);
  const { mapRef, isMapInitialized } = useMap();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const {
    selection: ctxSelection, setSelection: ctxSetSelection, setPoints, isLocked, isPolygonMode,
  } = useSelection();

  const buildingGeometryGroupRef = useRef(null);
  const buildingMarkerGroupRef = useRef(null);

  const getSelectionData = useCallback(async (clickEvent, eventIndex) => {
    try {
      const buildingInfo = await getBuildingByPoint(env, jwt, clickEvent);
      buildingInfo.eventIndex = eventIndex;
      const buildingGeometry = await getBuildingGeometry(env, jwt, buildingInfo);
      buildingGeometry.eventIndex = eventIndex;
      buildingGeometry.coordinates = {
        latitude: clickEvent.latlng.lat,
        longitude: clickEvent.latlng.lng,
      };
      return { buildingInfo, buildingGeometry, eventIndex };
    } catch (e) {
      return { buildingInfo: null, buildingGeometry: null, eventIndex };
    }
  }, [jwt]);

  // temp fix for https://github.com/Leaflet/Leaflet/issues/7255
  const onMapClick = useCallback((event) => {
    if (isLocked || isPolygonMode) {
      return;
    }
    const newClicks = mapClickEvents.concat([event]);
    iziToast.show({
      timeout: 3000,
      theme: 'dark',
      class: 'loading',
      color: themes.geoimpact.palette.primary.main,
      zindex: 5,
      message: `${t(`${LOCIZE_PANEL_NS}:building-selection-loading`)}`,
      position: 'topCenter',
      drag: false,
    });
    const buildingSelectionEvent = new CustomEvent('kibana-event', {
      detail: {
        type: 'building-selection',
        eventInfo: {
          info: 'Building has been selected.',
        },
      },
    });
    window.dispatchEvent(buildingSelectionEvent);
    setMapClickEvents(newClicks);
  }, [isLocked, mapClickEvents, t, isPolygonMode]);

  const onMapClickSimulation = useCallback((customEvent) => {
    if (isPolygonMode) return;
    const points = JSON.parse(customEvent.detail);
    const simulatedEvents = [];
    points.forEach((point) => {
      const simulatedEvent = {
        latlng: { lat: point.lat, lng: point.lng },
      };
      simulatedEvents.push(simulatedEvent);
    });

    setMapClickEvents(simulatedEvents);
  }, [isPolygonMode]);

  const removeSelection = useCallback(() => {
    setPoints([]);
    setMapClickEvents([]);
  }, [setPoints]);

  useEffect(() => {
    if (isPolygonMode) {
      window.removeEventListener('sep_deeplink:mapclick', onMapClickSimulation);
    } else {
      window.addEventListener('sep_deeplink:mapclick', onMapClickSimulation);
    }

    return () => {
      window.removeEventListener('sep_deeplink:mapclick', onMapClickSimulation);
    };
  }, [onMapClickSimulation, isPolygonMode]);

  useEffect(() => {
    if (!isMapInitialized) return;
    const geoJSONColor = getComputedStyle(document.documentElement).getPropertyValue('--gi_color_dark_primary_color');
    const geometryStyle = {
      color: `${geoJSONColor}`,
      weight: 5,
      opacity: 0.62,
    };
    if (buildingGeometryGroupRef.current) {
      mapRef.current.removeLayer(buildingGeometryGroupRef.current);
    }
    const layers = [];
    Object.keys(displayedData).forEach((key) => {
      const geoJSON = L.geoJSON(displayedData[key].geometry.data, {
        style: geometryStyle,
      });
      layers.push(geoJSON);
    });
    buildingGeometryGroupRef.current = L.layerGroup(layers);
    buildingGeometryGroupRef.current.addTo(mapRef.current);
  }, [isMapInitialized, displayedData, mapRef]);

  useEffect(() => {
    if (!isMapInitialized) return;
    if (buildingMarkerGroupRef.current) {
      mapRef.current.removeLayer(buildingMarkerGroupRef.current);
    }
    const data = [];
    Object.keys(displayedData).forEach((key) => {
      data.push(
        displayedData[key],
      );
    });

    const markers = [];

    // sort the data by click order
    data.sort((a, b) => {
      if (a.geometry.eventIndex > b.geometry.eventIndex) {
        return 1;
      } if (a.geometry.eventIndex < b.geometry.eventIndex) {
        return -1;
      }
      return 0;
    });

    data.forEach((dataElement, index) => {
      const { latitude, longitude } = dataElement.geometry.coordinates;

      const pinNumber = index + 1;
      let svgXPosition = 0;
      if (pinNumber >= 1 && pinNumber <= 9) {
        svgXPosition = 24;
      }
      if (pinNumber >= 10 && pinNumber <= 99) {
        svgXPosition = 15;
      }
      if (pinNumber >= 100 && pinNumber <= 999) {
        svgXPosition = 5;
      }

      const color = themes.geoimpact.palette.primary.main;
      const svgString = `<svg width="70" height="100" xmlns="http://www.w3.org/2000/svg">
                 <metadata id="metadata-building-marker">image/svg+xml</metadata>
                 <g>
                  <title>background</title>
                  <rect fill="none" id="canvas_background" height="102" width="72" y="-1" x="-1"/>
                 </g>
                 <g>
                  <title>Layer 1</title>
                  <path fill-opacity="0.8" stroke="null" fill="${color}" id="path4127" d="m34.96515,99.35066c-0.21626,-0.52746 -4.70929,-4.93912 -7.92679,-8.94788c-19.06234,-25.41221 -40.31534,-49.02389 -15.49846,-77.7181c10.98839,-10.65954 24.53781,-11.26764 38.46711,-6.29901c39.2213,22.788 10.8246,59.96254 -7.821,84.40073l-7.22086,8.56426l0.00001,0l-0.00001,0z"/>
                  <text xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="35" id="svg_1" y="52.21932" x="${svgXPosition}" stroke-opacity="null" stroke-width="0" stroke="null" fill="#ffffff">${pinNumber}</text>
                 </g>
                </svg>`;
      const iconUrl = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;

      const greenIcon = L.icon({
        iconUrl,
        iconSize: [36, 52], // size of the icon
        iconAnchor: [18, 52], // point of the icon which will correspond to marker's location
        className: 'sep-building-selection-marker',
      });
      const marker = L.marker([latitude, longitude], { icon: greenIcon });
      markers.push(marker);
    });

    buildingMarkerGroupRef.current = L.layerGroup(markers);
    buildingMarkerGroupRef.current.addTo(mapRef.current);

    const mySelection = [];
    data.forEach((dataElement) => {
      mySelection.push({
        // TODO: all we need information is inside dataElement.geometry
        // we should slowly remove the usage of buildings
        buildings: dataElement.info.data,
        geoJSON: dataElement.geometry.data,
      });
    });

    const oldSelectedBuildings = ctxSelection?.simple || [];
    try {
      const toasts = document.querySelectorAll('.loading');
      toasts.forEach((toast) => {
        iziToast.hide({}, toast);
      });
    } catch (e) {
      log.warn('iziToast error', e);
    }
    if (mapClickEvents.length > 0 && mySelection.length > oldSelectedBuildings.length) {
      iziToast.show({
        timeout: 3000,
        theme: 'dark',
        class: 'loading',
        color: themes.geoimpact.palette.primary.main,
        zindex: 5,
        message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-add`)}`,
        position: 'topCenter',
        drag: false,
      });
    }
    if (mapClickEvents.length > 0 && mySelection.length < oldSelectedBuildings.length) {
      iziToast.show({
        timeout: 3000,
        theme: 'dark',
        class: 'loading',
        color: themes.geoimpact.palette.primary.main,
        zindex: 5,
        message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-remove`)}`,
        position: 'topCenter',
        drag: false,
      });
    }
    if (mapClickEvents.length === Object.keys(displayedData).length
      && mySelection.length === oldSelectedBuildings.length
      && mapClickEvents.length > 0) {
      iziToast.show({
        timeout: 3000,
        theme: 'dark',
        class: 'loading',
        color: themes.geoimpact.palette.primary.main,
        zindex: 5,
        message: `${t(`${LOCIZE_PANEL_NS}:message-building-selection-nothing-selected`)}`,
        position: 'topCenter',
        drag: false,
      });
    }

    /* Inject the building selection into the selection-context.
    The new way to get the building
    selection is to push and retrieve it from the context. */
    ctxSetSelection({
      ...ctxSelection,
      simple: mySelection,
    });
    // eslint-disable-next-line
  }, [JSON.stringify(displayedData), isMapInitialized])

  useEffect(() => {
    const selectionMix = [];
    selection.forEach((selectionElement) => {
      selectionMix.push(selectionElement.buildingInfo);
      selectionMix.push(selectionElement.buildingGeometry);
    });

    // filter out invalid data
    const validSelection = selectionMix
      .filter((selectionElement) => selectionElement !== null
        && selectionElement.buildingInfo !== null
        && selectionElement.buildingGeometry !== null);

    // filter out the deselected buildings
    const infos = [];
    const geometries = [];
    validSelection.forEach((selectionElement) => {
      if (
        selectionElement.type === 'info'
      ) {
        infos.push(selectionElement);
      } else if (
        selectionElement.type === 'geometry'
      ) {
        geometries.push(selectionElement);
      }
    });
    // map the occurrence count
    const mappedGeometries = geometries.map((geometry) => ({
      ...geometry,
      count: geometries
        .filter((geometryTemp) => geometryTemp.buildingId === geometry.buildingId).length,
    }));
    const mappedInfos = infos.map((info) => ({
      ...info,
      count: infos.filter((infoTemp) => infoTemp.buildingId === info.buildingId).length,
    }));

    const geomMem = [];
    const infoMem = [];
    const displayedDataMap = {};
    mappedGeometries.forEach((geometry) => {
      if (geometry.count % 2 === 1 && geomMem.includes(geometry.buildingId) === false) {
        // count is odd, add it
        geomMem.push(geometry.buildingId);
        displayedDataMap[geometry.buildingId] = displayedDataMap[geometry.buildingId] || {};
        displayedDataMap[geometry.buildingId].geometry = {
          data: geometry.data,
          eventIndex: geometry.eventIndex,
          coordinates: geometry.coordinates,
        };
      }
    });
    mappedInfos.forEach((info) => {
      if (info.count % 2 === 1 && infoMem.includes(info.buildingId) === false) {
        // count is odd, add it
        infoMem.push(info.buildingId);
        displayedDataMap[info.buildingId] = displayedDataMap[info.buildingId] || {};
        displayedDataMap[info.buildingId].info = {
          data: info.data,
          eventIndex: info.eventIndex,
        };
      }
    });

    setDisplayedData(displayedDataMap);
  }, [selection]);

  useEffect(() => {
    if (!mapClickEvents.length) {
      setSelection([]);
      return;
    }

    (async () => {
      const allData = mapClickEvents
        .map((event, index) => getSelectionData(event, index + 1));

      const data = await Promise.all(allData);

      setSelection(data);
    })();
  }, [mapClickEvents, getSelectionData]);

  useEffect(() => {
    if (!isMapInitialized) return undefined;
    const map = mapRef.current;
    if (!map) return undefined;
    if (isPolygonMode) {
      map.off('click', onMapClick);
    }

    map.on('click', onMapClick);

    return () => {
      map.off('click', onMapClick);
    };
  }, [isMapInitialized, mapRef, onMapClick, isPolygonMode]);

  useEffect(() => {
    window.addEventListener('sep_map-overlay_controls:trash-selection', removeSelection);
    return () => {
      window.removeEventListener('sep_map-overlay_controls:trash-selection', removeSelection);
    };
  }, [removeSelection]);

  return (
    <div className="Selection" />
  );
}
