const giEnv = process.env.REACT_APP_GI_ENV;
const env = {};

if (giEnv === 'production') {
  env.REACT_APP_GI_ENV = giEnv;
  env.API_GATEWAY_BASE = 'https://services.swissenergyplanning.ch';
  env.MS_LOGIN_URL = 'https://mslogin.services.swissenergyplanning.ch';
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net';
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0';
  env.LOCIZE_API_KEY = '61308123-7b73-4f41-b022-b4f5884afd37';
  env.LOCIZE_PROJECT_VERSION = 'latest';
} else if (giEnv === 'development') {
  env.REACT_APP_GI_ENV = giEnv;
  env.API_GATEWAY_BASE = 'https://development.services.swissenergyplanning.ch';
  env.MS_LOGIN_URL = 'https://mslogin-development.services.swissenergyplanning.ch';
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net';
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0';
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f';
  env.LOCIZE_PROJECT_VERSION = 'development';
} else if (giEnv === 'staging') {
  env.REACT_APP_GI_ENV = giEnv;
  env.API_GATEWAY_BASE = 'https://staging.services.swissenergyplanning.ch';
  env.MS_LOGIN_URL = 'https://mslogin-staging.services.swissenergyplanning.ch';
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net';
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0';
  env.LOCIZE_API_KEY = '335f22a7-c8a5-4506-a269-12785af8c2c7';
  env.LOCIZE_PROJECT_VERSION = 'staging';
} else if (giEnv === 'test') {
  env.REACT_APP_GI_ENV = giEnv;
  env.API_GATEWAY_BASE = 'https://development.services.swissenergyplanning.ch';
  env.MS_LOGIN_URL = 'https://mslogin-development.services.swissenergyplanning.ch';
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net';
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0';
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f';
  env.LOCIZE_PROJECT_VERSION = 'development';
} else if (giEnv === 'experimental') {
  env.REACT_APP_GI_ENV = giEnv;
  env.API_GATEWAY_BASE = 'https://development.services.swissenergyplanning.ch';
  env.MS_LOGIN_URL = 'https://mslogin-development.services.swissenergyplanning.ch';
  env.AZURE_STORAGE_BASE = 'https://geoimpactstorage.blob.core.windows.net';
  env.LOCIZE_PROJECT_ID = 'f9cf0720-4091-4c6c-8140-90e77341d1f0';
  env.LOCIZE_API_KEY = '4f55bf84-ae4d-4124-9dae-666d5c33340f';
  env.LOCIZE_PROJECT_VERSION = 'development';
} else {
  throw new Error(`Invalid REACT_APP_GI_ENV environment variable: ${giEnv}`);
}

export default env;
