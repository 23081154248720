/* eslint brace-style: ["error", "stroustrup"] */
import {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import * as L from 'leaflet';
import { debounce } from 'lodash';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import SEPContext from '../sep-context/SEPContext';
import {
  defaultBounds, LAYERS, CENTER_POINT, MAP_SERVER, MAP_SERVER_LANGUAGE_MAPPER, MAP_SERVER_TEST,
} from './constants';
import env from '../../env/env';
import { getLayers } from './API';
import { useDeepState } from '../deepstate/DeepStateProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const MapContext = createContext(null);

export default function MapProvider({ children }) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;

  const { deepLinkState: deepState, deepStateLoading } = useDeepState();
  const { i18n } = useTranslation();

  // The disadvantage of initiating the state with the layer
  // is that the user will see at the initial load the initial layer
  // and after that the layer from the shared link will be rendered.
  // This could be resolved by introducing loading state which we currently don't have
  const [baseLayer, setBaseLayerState] = useState(LAYERS.swisstopoGrey);
  const [overlayLayers, setOverlayLayers] = useState([]);
  const [availableLayers, setAvailableLayers] = useState([]);
  const [zoom, setZoom] = useState(9);
  const [position, setPosition] = useState(CENTER_POINT);
  const [bounds, setBounds] = useState(defaultBounds);

  const [openLegends, setOpenLegends] = useState({});
  const [zIndexes, setZIndexes] = useState({});
  const [positions, setPositions] = useState({});

  const [isMapInitialized, setMapInitialized] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [desiredView, setDesiredView] = useState(null);

  const mapRef = useRef(null);
  const activeLayerRefs = useRef({});
  const overlayLayerRefs = useRef({});

  const setBaseLayer = useCallback((layer) => {
    if (!isMapReady) return;

    // We don't share with the app the setBaseLayerState directly
    // instead the setBaseLayer is available so we have control
    // over the layer change and further actions
    if (activeLayerRefs.current[baseLayer.name]) {
      mapRef.current.removeLayer(activeLayerRefs.current[baseLayer.name]);
      delete activeLayerRefs.current[baseLayer.name];
    }

    if (baseLayer.overlay) {
      const overlayLayerKey = `${baseLayer.name}-overlay`;
      if (activeLayerRefs.current[overlayLayerKey]) {
        mapRef.current.removeLayer(activeLayerRefs.current[overlayLayerKey]);
        delete activeLayerRefs.current[overlayLayerKey];
      }
    }

    setBaseLayerState(layer);

    const newBaseLayer = layer.nonTiled
      ? L.nonTiledLayer.wms(layer.url, layer.options)
      : L.tileLayer(layer.url, layer.options);
    newBaseLayer.addTo(mapRef.current);
    activeLayerRefs.current[layer.name] = newBaseLayer;

    // Some base layers have an overlay layer, so we need to add it as well
    if (layer.overlay) {
      const overlayLayer = LAYERS[layer.overlay];
      const overlayInstance = overlayLayer.nonTiled
        ? L.nonTiledLayer.wms(overlayLayer.url, overlayLayer.options)
        : L.tileLayer.wms(overlayLayer.url, overlayLayer.options);
      overlayInstance.addTo(mapRef.current);
      activeLayerRefs.current[`${layer.name}-overlay`] = overlayInstance;
    }

    // Re-add overlay layers to ensure they are on top
    Object.keys(overlayLayerRefs.current).forEach((layerName) => {
      if (overlayLayerRefs.current[layerName]) {
        overlayLayerRefs.current[layerName].bringToFront();
      }
    });
  }, [baseLayer.name, baseLayer.overlay, isMapReady]);

  const addOverlayLayer = (layer) => {
    setOverlayLayers((prevLayers) => [...prevLayers, layer]);
  };

  const removeOverlayLayer = (layerId) => {
    setOverlayLayers((prevLayers) => prevLayers.filter((layer) => layer.id !== layerId));
  };

  const reorderOverlayLayer = (layerId, newPosition) => {
    setOverlayLayers((prevLayers) => {
      const layerIndex = prevLayers.findIndex((layer) => layer.id === layerId);
      if (layerIndex === -1 || newPosition < 0 || newPosition >= prevLayers.length) {
        return prevLayers; // Invalid input, return the current state
      }

      const updatedLayers = [...prevLayers];
      const [movedLayer] = updatedLayers.splice(layerIndex, 1);
      updatedLayers.splice(newPosition, 0, movedLayer);

      return updatedLayers;
    });
  };

  useEffect(() => {
    if (!jwt) return;

    (async () => {
      const url = `${MAP_SERVER}?SERVICE=WMS&VERSION=1.3.0&request=GetCapabilities&language=${MAP_SERVER_LANGUAGE_MAPPER[i18n.language || 'de-CH']}&jwt=${jwt}`;
      const urlLegend = (layerName) => `${MAP_SERVER}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image/png&SLD_VERSION=1.1.0&LAYER=${layerName}&language=${MAP_SERVER_LANGUAGE_MAPPER[i18n.language || 'de-CH']}&jwt=${jwt}`;

      try {
        const layers = await getLayers(url, urlLegend, jwt);

        setAvailableLayers(layers);

        if (env.REACT_APP_GI_ENV === 'development') {
          const urlTest = `${MAP_SERVER_TEST}?SERVICE=WMS&VERSION=1.3.0&request=GetCapabilities&language=${MAP_SERVER_LANGUAGE_MAPPER[i18n.language || 'de-CH']}&jwt=${jwt}`;
          const urlTestLegend = (layerName) => `${MAP_SERVER_TEST}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image/png&SLD_VERSION=1.1.0&LAYER=${layerName}&language=${MAP_SERVER_LANGUAGE_MAPPER[i18n.language || 'de-CH']}&jwt=${jwt}`;

          const layersTest = await getLayers(urlTest, urlTestLegend, jwt);

          setAvailableLayers((pre) => [...pre, ...layersTest]);
        }
      }
      catch (e) {
        log.error(e);
      }
    })();
  }, [i18n.language, jwt]);

  useEffect(() => {
    // NOTE: the logic inside of this useEffect
    // is for the backward compatibility of the share
    // functionality and should be changed with attention.
    // Also note that this useEffect is fired only on load time
    // of SEP and not triggered anymore
    if (!deepState) return;
    const selectedLayerName = deepState?.mapState?.selectedBaseLayer?.name;
    const newSelectedLayer = LAYERS[selectedLayerName];
    if (selectedLayerName !== 'swisstopoGrey' && newSelectedLayer) {
      setBaseLayerState(newSelectedLayer);
    }

    const boundsFromUrl = deepState?.mapState?.bounds;
    setDesiredView({ bounds: boundsFromUrl });
  }, [deepState]);

  useEffect(() => {
    if (!isMapInitialized) return undefined;

    const map = mapRef.current;

    const handleMoveEnd = debounce((e) => {
      const newZoom = e.target.getZoom();
      const newBounds = e.target.getBounds();
      const newCenter = e.target.getCenter();

      setZoom((p) => (p !== newZoom ? newZoom : p));
      setBounds((p) => (!p.equals(newBounds) ? newBounds : p));
      setPosition((p) => (!p.equals(newCenter) ? newCenter : p));
    }, 100);

    map.on('moveend', handleMoveEnd);

    // Clean up the event listener when the component is unmounted
    // or when the effect is re-run
    return () => {
      map.off('moveend', handleMoveEnd);
    };
  }, [isMapInitialized]);

  useEffect(() => {
    if (isMapInitialized && !deepState) mapRef.current.fitBounds(bounds);
  }, [bounds, deepState, isMapInitialized]);

  // Separate useEffect for initializing the default position
  useEffect(() => {
    if (isMapInitialized && !deepStateLoading) {
      mapRef.current.setView(position, zoom, { animate: false });
      setIsMapReady(true);

      if (env.REACT_APP_GI_ENV === 'development') {
        const m = document.getElementById('sep-map-leaflet');
        m.mapInstance = mapRef.current;
      }
    }
    else {
      setIsMapReady(false);
    }
  }, [isMapInitialized, deepStateLoading, position, zoom]);

  useEffect(() => {
    if (isMapReady && desiredView) {
      if (desiredView.point) {
        mapRef.current.setView(desiredView.point, desiredView.zoom);
      }
      if (desiredView.bounds) {
        mapRef.current.fitBounds(desiredView.bounds);
      }
      setDesiredView(null); // Reset after navigating
    }
  }, [desiredView, isMapReady]);

  useEffect(() => {
    if (!isMapReady) return;

    // Remove all current overlay layers
    Object.keys(overlayLayerRefs.current).forEach((layerId) => {
      if (overlayLayerRefs.current[layerId]) {
        mapRef.current.removeLayer(overlayLayerRefs.current[layerId]);
      }
    });

    // Add overlay layers in the correct order
    overlayLayers.forEach((layer) => {
      const newLayer = L.tileLayer.wms(layer.url, layer.options);
      newLayer.addTo(mapRef.current);
      overlayLayerRefs.current[layer.id] = newLayer;
    });
  }, [overlayLayers, isMapReady]);

  useEffect(() => {
    if (isMapReady && !deepStateLoading) {
      setBaseLayer(baseLayer);
    }
  }, [isMapReady, baseLayer, setBaseLayer, deepStateLoading]);

  const api = useMemo(() => ({
    mapRef,
    isMapInitialized,
    isMapReady,
    setMapInitialized,
    setDesiredView,
    activeLayerRefs,
    baseLayer,
    setBaseLayer,
    overlayLayers,
    availableLayers,
    setOverlayLayers,
    addOverlayLayer,
    removeOverlayLayer,
    reorderOverlayLayer,
    zoom,
    position,
    bounds,
    openLegends,
    setOpenLegends,
    zIndexes,
    setZIndexes,
    positions,
    setPositions,
  }), [
    setBaseLayer,
    overlayLayers,
    availableLayers,
    isMapInitialized,
    isMapReady,
    bounds,
    baseLayer,
    position,
    zoom,
    openLegends,
    zIndexes,
    positions,
  ]);

  return (
    <MapContext.Provider value={api}>{children}</MapContext.Provider>
  );
}

/**
 * A custom React hook for accessing the MapContext.
 * It provides access to the map's state, operations, and references,
 * enabling components to interact with the map.
 *
 * @returns {Object} The context of MapProvider which includes:
 *   - mapRef: A ref object pointing to the current map instance.
 *   - isMapInitialized: A state boolean indicating whether the map has been initialized.
 *   - isMapReady: A state boolean indicating whether the map is ready for interaction.
 *   - setMapInitialized: A function to update the isMapInitialized state.
 *   - setDesiredView: A function to set a desired view, accepting bounds or point and zoom.
 *   - activeLayerRefs: A ref object holding the active layer instances.
 *   - layers: A state array containing the background tile layers.
 *   - setLayers: A function to update the layers state.
 *   - zoom: A state variable holding the current zoom level of the map.
 *   - position: A state variable holding the current center position of the map.
 *   - bounds: A state variable holding the current bounds of the map.
 *   - addLayer: A function to add a new layer to the layers state.
 *   - removeLayer: A function to remove a layer from the layers state by its name.
 *
 * @throws {Error} If the hook is used outside the MapProvider context.
 */
export const useMap = () => {
  const ctx = useContext(MapContext);
  if (!ctx) {
    throw new Error('useMap must be used inside a the MapContextProvider');
  }
  return ctx;
};
