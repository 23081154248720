import React from 'react';
import './SuspenceLoader.css';
import * as loglevel from 'loglevel';
import env from '../../env/env';
// eslint-disable-next-line import/no-cycle
import { LoadingComponent } from '../router/Router';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export default function SuspenceLoader(props) {
  const { text } = props;
  return (
    <div
      className="SuspenceLoader"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <img
        style={{ display: 'flex' }}
        className="logo"
        src={
          // eslint-disable-next-line react/destructuring-assignment
          `${env.AZURE_STORAGE_BASE}/public/logo/sep/Logo-SEP-web.png`
        }
        alt="client logo"
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <LoadingComponent />
        {text ? (
          <div>{text}</div>
        ) : null}
      </div>
    </div>
  );
}
