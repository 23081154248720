/* eslint brace-style: ["error", "stroustrup"] */
import { useTranslation } from 'react-i18next';
import {
  useState, useMemo, useCallback, useContext,
} from 'react';
import * as loglevel from 'loglevel';
import {
  Autocomplete, IconButton, Stack, TextField, Box,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
// import { useConfirm } from 'material-ui-confirm';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import ModalNotification from '../favorite-modals/ModalNotification';
import ModalRecipients from '../favorite-modals/ModalRecipients';
import { API } from '../favorite-modals/utils';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteGroupFilter({ closeMenu }) {
  const {
    groups,
    filterGroup,
    setFilterGroup,
    favorites,
    fetchFavorites,
    setSelectedTypes,
    setIsLoading,
    filterType,
    sortedFavorites,
    setItemsPerPage,
    setSelectedFavorites,
    setLoadingFavorites,
    setCurrentPage,
  } = useFavorites();
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { t } = useTranslation('favorites');
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false);
  // const confirm = useConfirm();

  const targetFavorites = useMemo(() => Object.values(favorites)
    .filter((f) => f.group === filterGroup), [favorites, filterGroup]);
  const selectedTypes = useMemo(() => {
    const types = new Set();
    targetFavorites.forEach((f) => types.add(f.favoriteType));
    // we currently do not support handling events for polygons
    return Array.from(types).filter((type) => type !== FAVORITE_TYPE.POLYGON);
  }, [targetFavorites]);

  const reloadFavorites = useCallback(async () => {
    const withLoading = false;
    await fetchFavorites(withLoading);
  }, [fetchFavorites]);

  const setLoadingTargets = useCallback((isLoading) => {
    const loading = targetFavorites.reduce((acc, curr) => ({
      ...acc,
      [`${curr.id}_${curr.favoriteType}`]: isLoading,
    }), {});

    setLoadingFavorites((prevLoading) => ({
      ...prevLoading,
      ...loading,
    }));
  }, [setLoadingFavorites, targetFavorites]);

  const options = useMemo(() => [
    'all',
    ...groups,
  ], [groups]);

  const getOptionLabel = (option) => {
    switch (option) {
      case 'all':
        return t('selectbox-group-all');
      default:
        return option;
    }
  };

  const rowsPerPageOptions = useMemo(() => {
    const dataLength = filterGroup === 'all' && filterType === 'ALL'
      ? Object.values(favorites).length : sortedFavorites.length;
    const maxOption = Math.min(50, dataLength);
    const o = [5, 10, 25].filter((option) => option <= maxOption);

    if (maxOption === dataLength) {
      o.push(dataLength); // Include actual length if it does not exceed 50
    }
    else {
      o.push(50); // Otherwise, include 50 as the last option
    }
    return o;
  }, [favorites, filterGroup, filterType, sortedFavorites.length]);

  const handleDeleteAllFavorites = useCallback(async () => {
    const alertText = `${targetFavorites.length} ${t('are-you-sure-you-want-delete-selected-1')} ${t('are-you-sure-you-want-delete-selected-2')}`;
    // const isOk = await confirm({
    //   title: t('delete-confirm-title'),
    //   description: alertText,
    //   confirmationText: t('delete-confirm-ok'),
    //   confirmationButtonProps: {
    //     color: 'warning',
    //   },
    //   cancellationText: t('delete-confirm-cancel'),
    // })
    //   .then(() => true)
    //   .catch(() => false);
    // eslint-disable-next-line no-alert
    const isOk = window.confirm(alertText);
    if (isOk) {
      try {
        setIsLoading(true);
        const requests = targetFavorites.map((f) => {
          const { favoriteType } = f;
          const apiCall = API[favoriteType].deleteFavorite;
          return apiCall(jwt, f.id)
            .catch((error) => {
              log.error(`Failed to delete favorite: ${error}`);
            });
        });
        await Promise.all(requests);
        closeMenu();
        setFilterGroup('all');
        await fetchFavorites();
        const nextValidOption = [25, 10, 5].find((option) => rowsPerPageOptions
          .includes(option)) || rowsPerPageOptions[0];
        setItemsPerPage(nextValidOption);
        setIsLoading(false);
      }
      catch (error) {
        log.error(`Failed to delete favorite: ${error}`);
        setIsLoading(false);
      }
    }
  }, [
    rowsPerPageOptions,
    setItemsPerPage,
    targetFavorites,
    fetchFavorites,
    setFilterGroup,
    setIsLoading,
    closeMenu,
    jwt,
    t,
  ]);

  return (
    <Stack direction="row" gap={1}>
      <Autocomplete
        className="FavoriteSelectionBox"
        disableClearable
        label={t('filter-by-group-label')}
        placeholder={t('filter-by-group-label')}
        value={filterGroup}
        fullWidth
        sx={{ minWidth: 220 }}
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params} // eslint-disable-line react/jsx-props-no-spreading
            label={t('filter-by-group-label')}
          />
        )}
        onChange={(e, newGroup) => {
          setCurrentPage(1);
          setSelectedFavorites({});
          setFilterGroup(newGroup);
        }}
        size="small"
      />
      <Box>
        <IconButton
          onClick={() => {
            setSelectedTypes(selectedTypes);
            setNotificationModalOpen(true);
          }}
          disabled={filterGroup === 'all'}
          sx={{ color: 'black' }}
        >
          <NotificationsIcon />
        </IconButton>
        <IconButton
          onClick={() => setRecipientsModalOpen(true)}
          disabled={filterGroup === 'all'}
          sx={{ color: 'black' }}
        >
          <GroupsIcon />
        </IconButton>
        <IconButton
          onClick={async () => handleDeleteAllFavorites()}
          disabled={filterGroup === 'all'}
          sx={{ color: 'black' }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      {notificationModalOpen && (
        <ModalNotification
          open={notificationModalOpen}
          onNotificationModalClose={async (isPristine) => {
            setNotificationModalOpen(false);
            if (!isPristine) { // if something was changed trigger reload
              setLoadingTargets(true);
              await reloadFavorites();
              setLoadingTargets(false);
            }
          }}
          targetFavorites={targetFavorites}
        />
      )}
      {recipientsModalOpen && (
        <ModalRecipients
          open={recipientsModalOpen}
          onRecipientsModalClose={async (isPristine) => {
            setRecipientsModalOpen(false);
            if (!isPristine) { // if something was changed trigger reload
              setLoadingTargets(true);
              await reloadFavorites();
              setLoadingTargets(false);
            }
          }}
          targetFavorites={targetFavorites}
        />
      )}
    </Stack>
  );
}
