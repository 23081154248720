import React from 'react';
import TextField from '@mui/material/TextField';

// This fake form input is used as a honeypot for spam bots.
// Use the hook for functional components and the SpamBotDetection for class components.
export function SpamBotDetection(props) {
    return (
        <TextField id={props.id} label="Outlined" variant="outlined" onChange={props.onChange} style={props.style || {display: "none"}}/>
    )
}

export default function useSpamBotDetection({id}){
    const [isBot, setIsBot] = React.useState(false);
    const [hiddenComponent] = React.useState(<SpamBotDetection id={id} onChange={(e) => {
        setIsBot(true);
    }}/>)
    return [isBot, hiddenComponent]
}