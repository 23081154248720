import {
  Box,
  IconButton,
  Typography,
  Stack,
  DialogTitle,
  DialogContent, Dialog, Paper, useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, isEqual } from 'lodash';
import { useMap } from '../../../contexts/map/map-context';

function CustomPaperComponent({ layerId, ...rest }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const nodeRef = useRef(null);
  const {
    setZIndexes,
    positions,
    setPositions,
  } = useMap();
  const [localPosition, setLocalPosition] = useState(positions[layerId] || { x: 0, y: 0 });

  useEffect(() => {
    setLocalPosition((prev) => {
      if (isEqual(prev, positions[layerId])) {
        return prev;
      }
      return positions[layerId] || { x: 0, y: 0 };
    });
  }, [positions, layerId]);

  // eslint-disable-next-line no-unused-vars
  const handleDrag = useCallback((e, data) => {
    setLocalPosition({ x: data.x, y: data.y });
    const debounceUpdate = debounce(() => {
      setPositions((prev) => ({
        ...prev,
        [layerId]: { x: data.x, y: data.y },
      }));

      setZIndexes((prev) => {
        const maxZIndex = Math.max(...Object.values(prev));
        const newZIndexes = { ...prev };

        const currentZIndex = newZIndexes[layerId];

        // decrease zIndex of all layers above the current one
        Object.keys(newZIndexes).forEach((key) => {
          if (newZIndexes[key] > currentZIndex) {
            newZIndexes[key] -= 1;
          }
        });

        newZIndexes[layerId] = maxZIndex;

        return newZIndexes;
      });
    }, 200);

    debounceUpdate();
  }, [layerId, setPositions, setZIndexes]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (isMobile) return (<Paper {...rest} />);

  return (
    <Draggable
      handle="#draggable-dialog-title"
      nodeRef={nodeRef}
      onStop={handleDrag}
      position={localPosition}
    >
      <Paper
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
        ref={nodeRef}
      />
    </Draggable>
  );
}

function LayerLegends() {
  const {
    openLegends, setOpenLegends, setZIndexes, zIndexes,
  } = useMap();
  const { t } = useTranslation('controls_map');

  const handleCloseModal = useCallback((layer) => {
    setOpenLegends((prevLegends) => {
      const newLegends = { ...prevLegends };
      delete newLegends[layer.id];
      return newLegends;
    });

    setZIndexes((prev) => {
      const newZIndexes = { ...prev };
      const removedZIndex = newZIndexes[layer.id];
      delete newZIndexes[layer.id];

      Object.keys(newZIndexes).forEach((key) => {
        if (newZIndexes[key] > removedZIndex) {
          newZIndexes[key] -= 1;
        }
      });

      return newZIndexes;
    });
  }, [setOpenLegends, setZIndexes]);

  const customPaperComponent = useCallback((props) => (
    <CustomPaperComponent
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  ), []);

  return Object.keys(openLegends).map((key) => {
    const dialog = openLegends[key];
    return (
      <Dialog
        hideBackdrop
        disableEnforceFocus
        key={dialog.id}
        open={Boolean(openLegends[dialog.id])}
        scroll="paper"
        maxWidth="sm"
        fullWidth
        PaperComponent={(props) => customPaperComponent({ ...props, layerId: dialog.id })}
        sx={{
          zIndex: zIndexes[dialog.id] || 1300, // 1300 default zIndex of the Dialog component
          pointerEvents: 'none',
          '& .MuiDialog-container': {
            pointerEvents: 'none',
          },
          '& .MuiPaper-root': {
            pointerEvents: 'auto',
          },
        }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {dialog.title}
        </DialogTitle>
        <IconButton
          onClick={() => handleCloseModal(dialog)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack gap={1}>
            <Typography>
              {dialog.abstract}
            </Typography>
            <Box>
              {dialog.legendUrl ? (
                <img
                  src={dialog.legendUrl}
                  alt={`${dialog.title} legend`}
                />
              ) : (
                <Typography>
                  {t('no-legend-available')}
                </Typography>
              )}
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  });
}

export default LayerLegends;
