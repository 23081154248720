import { useTranslation } from 'react-i18next';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import * as loglevel from 'loglevel';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { FAVORITES_SORT_BY } from '../../../../contexts/favorites/utils';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteSort() {
  const { t } = useTranslation('favorites');
  const { sortingBy, setSortingBy, setSelectedFavorites } = useFavorites();

  const handleChange = (event) => {
    setSelectedFavorites({});
    setSortingBy(event.target.value);
  };

  return (
    <FormControl className="FavoritesSort" fullWidth>
      <InputLabel id="favorite-sort-label">{t('sorting-placeholder')}</InputLabel>
      <Select
        fullWidth
        labelId="favorite-sort-label"
        value={sortingBy || 'alphabetically'}
        label={t('sorting-placeholder')}
        onChange={handleChange}
        size="small"
      >
        <MenuItem value={FAVORITES_SORT_BY.LAST_UPDATED_ASC}>{t('sorting-by-date')}</MenuItem>
        <MenuItem value={FAVORITES_SORT_BY.LAST_UPDATED_DES}>{t('sorting-by-date-old-first')}</MenuItem>
        <MenuItem value={FAVORITES_SORT_BY.NAME_ASC}>{t('sorting-by-alphabet')}</MenuItem>
        <MenuItem value={FAVORITES_SORT_BY.NAME_DES}>{t('sorting-by-alphabet-z-a')}</MenuItem>
      </Select>
    </FormControl>
  );
}
