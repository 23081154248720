export const getPanelId = (panelSetting, jwtParsed) => {
  if (!panelSetting.value.campaignId && panelSetting.panelType === 'energysimulation') {
    return `${jwtParsed.Mandant}_${panelSetting.panelType}`;
  }
  return `${jwtParsed.Mandant}_${panelSetting.value.campaignId}`;
};

export const getPanelName = (panelSetting, language) => {
  if (!panelSetting.value.campaignId && panelSetting.panelType === 'energysimulation') {
    return panelSetting.value.localized[language.toLocaleLowerCase()].panelName;
  }
  return panelSetting.value.customText[language.toLocaleLowerCase()].panel.name;
};

export const getPanelDescription = (panelSetting, language) => {
  if (!panelSetting.value.campaignId && panelSetting.panelType === 'energysimulation') {
    return panelSetting.value.localized[language.toLocaleLowerCase()].description;
  }
  return panelSetting.value.customText[language.toLocaleLowerCase()].settings.description;
};
