/* eslint-disable react/jsx-props-no-spreading */

import {
  BrowserRouter, Navigate,
  Route, Routes,
} from 'react-router-dom';

import './Router.css';
import * as loglevel from 'loglevel';
import { CircularProgress } from '@mui/material';
import Home from '../home/Home';
// eslint-disable-next-line import/no-cycle
import UserManagement from '../user_management/UserManagement';
import Favorites from '../favorites/Favorites';
// eslint-disable-next-line import/no-named-as-default
import Events from '../events/Events';
// eslint-disable-next-line import/no-cycle
import SepMapRoute from '../sep_map/component_route/SepMapRoute';
import SEPContext from '../../contexts/sep-context/SEPContext';
import FavoritesConfirmation from '../favorites/confirmation/Confirmation';
import { PanelConfig } from '../admin/panels/PanelConfig';
import env from '../../env/env';
import LayerLegends from '../sep_map/overlay_controls/LayerLegends';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export function LoadingComponent() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
    >
      <CircularProgress />
    </div>
  );
}
/*
* Checks if a user is logged in with a valid jwt token. Depens on env for the base path of the api.
* */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
export function ProtectedRoute(props) {
  if (
    props.appState.isAuthenticated === false
  ) {
    return props.redirectComponent;
  }
  return props.children;
}
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */

function Router(props) {
  if (
    props.appState.hasLoaded === false
  ) {
    return (
      <LoadingComponent />
    );
  }
  const getHomeComponent = (reactProps, routerProps, additionalProps) => (
    <SEPContext.Consumer>
      {(context) => (
        <Home
          {...routerProps}
          {...reactProps}
          {...additionalProps}
          {...context}
        />
      )}
    </SEPContext.Consumer>
  );
  return (
    <div className="Router">
      <BrowserRouter className="router">
        <Routes>
          <Route
            exact
            path="/index.html"
            element={getHomeComponent(props, {}, { })}
          />
          <Route
            exact
            path="/"
            element={getHomeComponent(props, {}, { })}
          />
          <Route
            exact={false}
            strict={false}
            path="/user/:page/*"
            element={(
              <UserManagement
                {...props}
              />
            )}
          />
          <Route
            exact={false}
            path="/map/"
            element={(
              <SEPContext.Consumer>
                {(context) => (
                  <>
                    <LayerLegends />
                    <SepMapRoute {...props} {...context} />
                  </>
                )}
              </SEPContext.Consumer>
          )}
          />
          <Route
            exact
            path="/favorites/"
            element={(
              <SEPContext.Consumer>
                {(context) => {
                  // protect this route from unauthenticated access
                  if (props.appState.isAuthenticated === false) {
                    return <Navigate to="/user/signin/?continuePath=/favorites/" />;
                  }
                  return (
                    <Favorites {...props} {...context} />
                  );
                }}
              </SEPContext.Consumer>
              )}
          />
          <Route
            exact={false}
            path="/favorites/confirmation/*"
            element={
              <FavoritesConfirmation {...props} />
            }
          />
          <Route
            exact={false}
            path="/events/"
            element={(
              <SEPContext.Consumer>
                {(context) => {
                  // protect this route from unauthenticated access
                  if (props.appState.isAuthenticated === false) {
                    return <Navigate to="/user/signin/?continuePath=/events/" />;
                  }
                  return (
                    <Events {...props} {...context} />
                  );
                }}
              </SEPContext.Consumer>
)}
          />
          <Route
            exact
            path="/admin/panels/"
            element={(
              <PanelConfig
                {...props}
              />
)}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Router;
