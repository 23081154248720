import Locize from 'i18next-locize-backend';
import env from "../env/env"
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import setupLocales from './moment-setup'
import LanguageDetector from 'i18next-browser-languagedetector';
setupLocales();
import * as loglevel from "loglevel";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)
export async function getI18nInstance(client = "sep"){
  let config = {
    fallbackLng: 'de-CH',
    supportedLngs: [
        'de-CH',
        'it-CH',
        'fr-CH'
    ],
    ns: [
      "documedia",
      "dynamic_panel_demo",
      "dynamic_energysimulation",
      "dynamic_panel_heat",
      "dynamic_panel_marketsense",
      "dynamic_panel_marketsense_v4",
      "cookies",
      "home",
      "legacy",
      "menu",
      "settings",
      "sign_in",
      "sign_up",
      "search_bar",
      "notification_map",
      "controls_map",
      "panel_building_info",
      "panel_construction_projects",
      "panel_electricity",
      "panel_solar_electricity",
      "panel_usage",
      "panel_energy_project",
      "panel_innovenergy_batteries",
      "panel_solar_electricity",
      "panel_usage",
      "panel_co2",
      "profile",
      "recover",
      "reusable_autocomplete",
      "search_bar",
      "settings",
      "sign_in",
      "sign_up",
      "various",
      "dashboard",
      "favorites",
      "feedback",
      "events",
      "ewz_solarfourcompany",
      "lead_management",
      "export",
    ],
    backend: {
      projectId: env.LOCIZE_PROJECT_ID,
      apiKey: env.LOCIZE_API_KEY,
      version: env.LOCIZE_PROJECT_VERSION,
      referenceLng: 'de-CH' // as described in https://docs.locize.com/integration/instrumenting-your-code
    },
    interpolation: {
      escapeValue: false
    },
    // LanguageDetector options
    detection: {
      order: ['navigator'],
    }
  };
  // Each time the user changes the display language,
  // this should also be set in the <html> tag.
  i18n.on('languageChanged', function(lng) {
    document.documentElement.setAttribute("lang", lng);
  });
  await i18n
      .use(LanguageDetector) // or any other implementation
      .use(initReactI18next) // passes i18n down to react-i18next
      .use(Locize)
      .init(config);
  return i18n;
}
