import {useTranslation} from "react-i18next";
import {Link, Typography} from "@mui/material";

function TermsOfUseAgreement() {
  const { t } = useTranslation('various');

  const renderTextWithLinks = () => {
    const fullText = t('terms-of-use-privacy-agreement');
    const parts = fullText.split(/(\{link[12]\})/);

    return parts.map((part, index) => {
      if (part === '{link1}') {
        return (
          <Link
            key={JSON.stringify(index)}
            sx={{ fontWeight: 800 }}
            href={t('geoimpact-agb-link')}
            target="_blank"
          >
            {t('terms-of-use-agreement-link-text')}
          </Link>
        );
      } else if (part === '{link2}') {
        return (
          <Link
            key={JSON.stringify(index)}
            sx={{ fontWeight: 800 }}
            href={t('geoimpact-privacy-link')}
            target="_blank"
          >
            {t('privacy-agreement-link-text')}
          </Link>
        );
      }
      return part;
    });
  };

  return (
    <Typography>{renderTextWithLinks()}</Typography>
  );
}

export default TermsOfUseAgreement;
