import { useMemo } from 'react';
import 'izitoast/dist/css/iziToast.css';
import { Box } from '@mui/system';
import { MenuItem, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import * as L from 'leaflet';
import AddFavorite from '../../reusable/AddFavorite';
import { themes } from '../../mui/themes';
import searchResultType from './services/constants';
import { useMap } from '../../../contexts/map/map-context';
import { createMarkerIcon } from './services/api';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);
const LOCIZE_PANEL_NS = 'search_bar';

function GoogleChip() {
  return (
    <Chip
      className="google-chip"
      size="small"
      variant="outlined"
      label={(
        <Box sx={{ color: 'blue' }}>
          <span style={{ color: '#4285F4' }}>G</span>
          <span style={{ color: '#DB4437' }}>o</span>
          <span style={{ color: '#F4B400' }}>o</span>
          <span style={{ color: '#4285F4' }}>g</span>
          <span style={{ color: '#0F9D58' }}>l</span>
          <span style={{ color: '#DB4437' }}>e</span>
        </Box>
        )}
    />
  );
}

function ColoredChip({ label, color }) {
  return (
    <Chip
      className={`sep-chip-${label}`}
      size="small"
      variant="outlined"
      label={label}
      sx={{
        backgroundColor: color,
        color: themes.geoimpact.palette.getContrastText(color),
      }}
    />
  );
}

export default function SearchOption(props) {
  const {
    option, setIsOpen, setSearchString, searchMarkerRef,
  } = props;
  const { mapRef } = useMap();
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });

  const chipColors = useMemo(() => ({
    REGION_TOWN: '#689f38',
    REGION_PLZ: '#689f38',
    ADDRESS: '#99d066',
    PARCEL: '#0e72b5',
    GOOGLE: '#e0e0e0',
    GOOGLE_AUTOCOMPLETE: '#e0e0e0',
  }), []);

  const onOptionClick = () => {
    const setMapViewAndChangeMarker = (coords) => {
      if (!mapRef.current) return;
      mapRef.current.setView([coords.latitude, coords.longitude], 15);
      const icon = createMarkerIcon();
      const searchMarker = L.marker([coords.latitude, coords.longitude], { icon });
      if (searchMarkerRef.current) {
        mapRef.current.removeLayer(searchMarkerRef.current);
      }
      searchMarkerRef.current = searchMarker.addTo(mapRef.current);
    };

    setMapViewAndChangeMarker({ latitude: option.latitude, longitude: option.longitude });

    setIsOpen(false);
    setSearchString(option.label);
  };

  const renderChip = (myOption) => {
    const isGoogleLabel = myOption.chipLabel.startsWith('GOOGLE');
    return (
      <Stack direction="row" spacing={1}>
        {isGoogleLabel && <GoogleChip />}
        {!isGoogleLabel && (
          <ColoredChip
            label={t(`${LOCIZE_PANEL_NS}:option-chip-${myOption.chipLabel}`)}
            color={chipColors[myOption.type]}
          />
        )}
      </Stack>
    );
  };

  const renderFavoriteButton = (myOption) => {
    const isRegion = [
      searchResultType.REGION_PLZ.NAME,
      searchResultType.REGION_TOWN.NAME,
    ].includes(myOption.type);
    const isAddress = [searchResultType.ADDRESS.NAME].includes(myOption.type);

    if (!isAddress && !isRegion) return null;

    return (
      <AddFavorite
        type={isAddress ? 'address' : 'region'}
        result={{
          payload: myOption.row,
        }}
      />
    );
  };

  return (
    <MenuItem
      sx={{ flexWrap: 'wrap' }}
      onClick={() => {
        onOptionClick();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          overflowWrap: 'break-word',
          whiteSpace: 'auto',
        }}
      >
        {option.label}
      </Box>
      <Stack direction="row" ml="auto" spacing={0.5}>
        <>
          {renderFavoriteButton(option)}
          {renderChip(option)}
        </>
      </Stack>
    </MenuItem>
  );
}
