import axios from 'axios/index'
import { jwtDecode } from "jwt-decode";
import * as loglevel from "loglevel";
import env from "../../../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.DEBUG : loglevel.levels.WARN
)

class UserUtilClass {
  constructor () {
    this.constants = {
      USER_UPDATE_ERROR: 'Could not update the authentication token',
      defaultPermissions: {
        selectedClientIds: [
          0
        ],
        clients: [
          {
            id: 0,
            name: 'default',
            selectedRoleIds: [
              0
            ],
            roles: [
              { id: 1, name: 'Guest' },
              { id: 2, name: 'User' }
            ]
          }
        ]
      }
    }
  }


  async getMandant (jwt, env) {
    return axios(
        {
          method: 'get',
          url: `${env.API_GATEWAY_BASE}/api/current-mandant`,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${jwt}`
          }
        }
    )
  }

  hasClientAndRole (sepContext, client, role) {
    return (
        !!sepContext?.user?.jwtParsed?.Mandant === true
        && sepContext.user.jwtParsed.Mandant === client
        && sepContext.user.jwtParsed[`http://schemas.microsoft.com/ws/2008/06/identity/claims/role`] === role
    )
  }
  hasRole (sepContext, role) {
    return (
        !!sepContext?.user?.jwtParsed?.Mandant === true
        && sepContext.user.jwtParsed[`http://schemas.microsoft.com/ws/2008/06/identity/claims/role`] === role
    )
  }

  parseJWT(jwtString){
    try {
      return jwtDecode(jwtString);
    } catch (e){
      console.error("Could not parse jwt", jwtString);
      return null;
    }
  }
}

export const UserUtil = new UserUtilClass()
