import {useState, useEffect, useRef} from 'react'
import SEPMap from '../SEPMap'
import './SepMapRoute.css'
import Navbar from '../component_navbar/Navbar'
import { Translation } from 'react-i18next'
import HyperMenu from '../../hyper_menu/HyperMenu'
import qs from 'qs'
import { UserUtil } from '../../user_management/util/user-util'


import * as loglevel from "loglevel";
import env from "../../../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

export default function SepMapRoute (props) {
  const hookName = 'SepMapRoute'
  // const [menu, setMenu] = useState(null)
  const menuRef = useRef(null);
  // const menuRef = useRef(null);

  return (
    <Translation ns={['menu']}>
      {(t) => {
        return (
          <div className="Map">
            <HyperMenu
              {...props}
              ref={menuRef}
              content={
                (
                  <div className="SepMapRoute">
                    <Navbar
                      {...props}
                      onMenuToggle={() => {
                        let isOpen = menuRef.current.isOpen;
                        menuRef.current.setIsOpen(!isOpen);
                      }}
                      isSearchDisplayed={true}
                    />
                    <SEPMap
                      {...props}
                    />
                  </div>
                )
              }
            />
          </div>
        )
      }}
    </Translation>
  )
}
