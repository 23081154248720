/* eslint brace-style: ["error", "stroustrup"] */
import { useState } from 'react';
import './Recover.css';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import SupportRequest from '../../reusable/SupportRequest';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function Recover() {
  const [t] = useTranslation(['recover', 'sign_up'], { useSuspense: true });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState('');
  const recover = () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/recovery`;
    return axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        email,
      }),
    });
  };

  const submit = async () => {
    setMessages((p) => p.concat([
      t('recover:message-elaborating-restore-account'),
    ]));
    setIsLoading(true);
    const res = await recover().catch((e) => {
      if (e.response.status === 401) {
        setErrors((p) => p.concat([
          t('recover:message-no-account-with-this-mail'),
        ]));
      }
      else {
        setErrors((p) => p.concat([
          t(`${e.message}`),
        ]));
      }
      setIsLoading(false);
      return null;
    });
    if (res !== null) {
      setIsLoading(false);
      setIsSuccess(true);
      setMessages((p) => p.concat([
        t('recover:message-you-get-an-email'),
      ]));
    }
  };

  return (
    <div className="Recover">
      <div className="recover-form">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          <h1>{t('recover:label-recover-account')}</h1>
        </div>
        <Form loading={isLoading} className={`isSuccess-${isSuccess}`}>
          <div className="column-flex">
            <div className="recovery">
              {t('recover:label-type-your-mail')}
            </div>
          </div>
          <Form.Field>
            <label htmlFor="email">{t('sign_up:label-email')}</label>
            <input
              id="email"
              placeholder={t('sign_up:label-email')}
              type="email"
              autoComplete="email"
              onChange={(event) => {
                const val = event.target.value;
                setEmail(val);
              }}
            />
          </Form.Field>
          {messages.length >= 1 && errors.length === 0 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: messages[messages.length - 1],
              }}
            />
          )}
          {errors.length >= 1 && (
            <h5
              style={{ color: 'red' }}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: errors[errors.length - 1],
              }}
            />
          )}
          <div className="column-flex">
            <Button
              primary
              type="submit"
              onClick={async () => submit()}
            >
              {t('recover:label-recover-account')}
            </Button>
          </div>
        </Form>
        <div className="footer">
          <SupportRequest />
        </div>
      </div>
    </div>
  );
}
