import moment from "moment";

// import * as deCH from "moment/locale/de-ch";
// import * as itCH from "moment/locale/it-ch";
// import * as frCH from "moment/locale/fr-ch";

import "moment/locale/de-ch";
import "moment/locale/it-ch";
import "moment/locale/fr-ch";

function setupLocales(){
  console.log("setting up moment locales..");
  // moment.locale('fr-CH', frCH);
  // moment.locale('de-CH', deCH);
  // moment.locale('it-CH', itCH);

  // the last loaded language will be the default language
  moment.locale('fr-ch');
  moment.locale('it-ch');
  moment.locale('de-ch');

  console.log("setting up moment locales..done.");
  console.debug(`moment: the available moment locales are`, moment.locales());
}

export default setupLocales;