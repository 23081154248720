/* eslint brace-style: ["error", "stroustrup"] */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from 'react';
import './SignIn.css';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import {
  Link, Stack, Typography, Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SupportRequest from '../../reusable/SupportRequest';
import env from '../../../env/env';
import MicrosoftLoginButton from '../../reusable/MicrosoftLoginButton';
import TermsOfUseAgreement from '../../reusable/TermsOfUseAgreement';
import PasswordInput from '../../reusable/PasswordInput';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function SignIn(props) {
  const { onSignIn } = props;
  const [t] = useTranslation(['sign_in'], { useSuspense: true });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const isUserLockedOut = (responseData) => {
    try {
      return (responseData.success === false && responseData.message === 'Locked out. Account disabled');
    }
    catch (e) {
      return false;
    }
  };

  const signIn = useCallback(async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/login`;
    const loginResponse = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        email,
        password,
      }),
    }).catch((error) => {
      log.error('Could not log in', error.message);
      if (error?.response?.data && isUserLockedOut(error.response.data)) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsSubmitEnabled(false);
        setErrors((p) => p.concat(t('account_locked_error_message')));
      }
      else if (error?.response?.status !== undefined) {
        if (error.response.status === 401 || error.response.status === 422) {
          setErrors((p) => p.concat([
            t('sign_in:message-wrong-credentials'),
          ]));
        }
        else {
          setErrors((p) => p.concat([
            `${error.message}`,
          ]));
        }
      }
      return null;
    });
    if (!loginResponse) {
      return;
    }
    setMessages((p) => p.concat([
      t('sign_in:message-signin-ok'),
    ]));
    onSignIn(loginResponse.headers.jwt);
    setIsLoading(false);
    setIsSuccess(true);
    setIsSubmitEnabled(true);
  }, [email, onSignIn, password, t]);

  return (
    <div className="SignIn">
      <div className="signin-form">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          <h1>{t('sign_in:label-signin')}</h1>
        </div>
        <Form loading={isLoading} className={`isSuccess-${isSuccess}`}>
          <Form.Field>
            <label htmlFor="email">{t('sign_in:label-email')}</label>
            <input
              id="email"
              placeholder="E-Mail"
              type="email"
              autoComplete="on"
              onPaste={(event) => {
                const val = event.target.value;
                setEmail(val);
              }}
              onChange={(event) => {
                const val = event.target.value;
                setEmail(val);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="password">{t('sign_in:label-password')}</label>
            <PasswordInput
              id="password"
              placeholder="Passwort"
              value={password}
              onChange={(event) => {
                const val = event.target.value;
                setPassword(val);
              }}
            />
          </Form.Field>
          {messages.length >= 1 && errors.length === 0 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: messages[messages.length - 1],
              }}
            />
          )}
          {errors.length >= 1 && (
            <h5
              style={{ color: 'red' }}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: errors[errors.length - 1],
              }}
            />
          )}
          <Stack textAlign="center">
            <div className="column-flex">
              <div className="recover">
                <h5>
                  {t('sign_in:label-password-lost-1')}
                  {' '}
                  <Link
                    sx={{ fontWeight: 800 }}
                    onClick={() => {
                      navigate('/user/recover/');
                    }}
                  >
                    {t('sign_in:label-password-lost-2')}
                  </Link>
                  {t('sign_in:label-password-lost-3')}
                </h5>
              </div>
            </div>
            <div className="column-flex">
              <div className="recover">
                <h5>
                  {t('sign_in:label-no-account-1')}
                  {' '}
                  <Link
                    sx={{ fontWeight: 800 }}
                    onClick={() => {
                      navigate('/user/signup/');
                    }}
                  >
                    {t('sign_in:label-no-account-2')}
                  </Link>
                  {t('sign_in:label-no-account-3')}
                </h5>
              </div>
            </div>
            <div className="column-flex login-button sep">
              <Button
                primary
                type="submit"
                disabled={isSubmitEnabled === false}
                onClick={async () => {
                  setErrors([]);
                  setMessages([]);
                  await signIn();
                }}
              >
                {t('sign_in:label-signin')}
              </Button>
            </div>
            <Box className="column-flex">
              <Typography fontWeight="800">{t('sign_in:or-continue-with')}</Typography>
            </Box>
            <div className="column-flex login-button microsoft">
              <MicrosoftLoginButton
                isDisabled={isSubmitEnabled === false}
              />
            </div>
            <Box className="column-flex">
              <TermsOfUseAgreement />
            </Box>
          </Stack>
        </Form>
        <div className="footer">
          <SupportRequest />
        </div>
      </div>
    </div>
  );
}
