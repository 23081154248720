import { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import NonTiledLayer from 'leaflet.nontiledlayer/dist/NonTiledLayer';
import {
  Dialog, DialogTitle, DialogContent, Button, DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLeafletBounds, parseWKT } from './utils';
import { LAYERS } from '../../../../contexts/map/constants';

function ModalWktPreview({
  wkt, open, onClose, zoom, layerName, bounds,
}) {
  const { t } = useTranslation('favorites');
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (open && wkt) {
      setTimeout(() => {
        if (mapContainerRef.current && !mapRef.current) {
          const points = parseWKT(wkt);
          const map = L.map(mapContainerRef.current, {
            center: [points[0][0], points[0][1]],
            zoom,
            minZoom: 12,
            maxZoom: 18,
            dragging: true, // Disable dragging
            zoomControl: true, // Hide zoom control
            scrollWheelZoom: true, // Disable scroll wheel zoom
            doubleClickZoom: true, // Disable double click zoom
            touchZoom: true, // Disable touch zoom
          });

          const layerInfo = LAYERS[layerName];
          if (layerInfo) {
            if (layerInfo.nonTiled) {
              new NonTiledLayer.WMS(layerInfo.url, layerInfo.options).addTo(map);
            } else {
              L.tileLayer(layerInfo.url, layerInfo.options).addTo(map);
            }
          } else {
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
          }

          const polygon = L.polygon(points, { color: 'red' }).addTo(map);
          const center = polygon.getBounds().getCenter();
          map.setView(center, zoom);
          map.fitBounds(getLeafletBounds(bounds));
          mapRef.current = map;
        }
      }, 300);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [wkt, open, layerName, zoom, bounds]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('polygon-preview-dialog-title')}</DialogTitle>
      <DialogContent>
        <div ref={mapContainerRef} id="map-polygon-preview" style={{ height: 400 }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('polygon-preview-dialog-close-button')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalWktPreview;
