import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

function Dimmer({ message }) {
  const dimmerRef = useRef(null);
  const [height, setHeight] = useState('100%');

  useEffect(() => {
    function updateHeight() {
      if (dimmerRef.current && dimmerRef.current.parentElement) {
        const newHeight = dimmerRef.current.parentElement.scrollHeight || '100%';
        setHeight(`${newHeight}px`);
      }
    }

    function handleMutation(mutationsList) {
      for (let i = 0; i < mutationsList.length; i += 1) {
        const mutation = mutationsList[i];
        if (mutation.attributeName === 'class' || mutation.type === 'childList') {
          updateHeight();
        }
      }
    }

    // Create an observer to monitor changes in the portal element
    const observer = new MutationObserver(handleMutation);
    if (dimmerRef.current && dimmerRef.current.parentElement) {
      observer.observe(dimmerRef.current.parentElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    // Initial update
    updateHeight();

    // Update on window resize
    window.addEventListener('resize', updateHeight);

    // Clean up
    return () => {
      window.removeEventListener('resize', updateHeight);
      observer.disconnect();
    };
  }, []);

  return (
    <Box
      ref={dimmerRef}
      sx={{
        display: 'flex',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height,
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '1em',
        background: 'rgba(0,0,0,.85)',
        lineHeight: 1,
        webkitAnimationFillMode: 'both',
        animationFillMode: 'both',
        webkitAnimationDuration: '.5s',
        animationDuration: '.5s',
        transition: 'opacity .5s linear',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        webkitUserSelect: 'none',
        userSelect: 'none',
        willChange: 'opacity',
        zIndex: '1000',
      }}
      className="loading-dimmer"
    >
      <CircularProgress
        sx={{
          color: '#fff',
        }}
        size="4.57142857rem"
        thickness={3}
      />
      <Typography
        sx={{
          zIndex: '1001',
          color: '#fff',
          fontSize: '1.71428571em',
          lineHeight: 1.2,
          wordWrap: 'break-word',
        }}
      >
        Loading
        {message !== '' && (
        <>
          <br />
          {message}
        </>
        )}
      </Typography>
    </Box>
  );
}

export default Dimmer;
