import React from 'react';
import { Button } from 'semantic-ui-react';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import env from '../../env/env';
import MSSVGIcon from './MSSVGIcon';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);
function MicrosoftLoginButton({ isDisabled = false, buttonText = 'signin' }) {
  const { t } = useTranslation(['sign_in', 'sign_up']);

  const ns = buttonText === 'signin' ? 'sign_in' : 'sign_up';

  return (
    <Button
      basic
      disabled={isDisabled}
      onClick={async () => {
        window.location.replace(env.MS_LOGIN_URL);
      }}
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '0.1px solid #689F38',
        boxShadow: 'none',
        fontWeight: '700',
        lineHeight: '0.9',
      }}
    >
      <div style={{ margin: '0 12px', position: 'absolute', left: '0' }}><MSSVGIcon /></div>
      {t(`${ns}:label-${buttonText}-ms`)}
    </Button>
  );
}

export default MicrosoftLoginButton;
