/* eslint brace-style: ["error", "stroustrup"] */
import {
  useContext, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert, AlertTitle, Stack, CircularProgress, Box, useMediaQuery,
  Divider,
} from '@mui/material';
import * as loglevel from 'loglevel';
import Navbar from '../sep_map/component_navbar/Navbar';
import 'izitoast/dist/css/iziToast.css';
import SEPContext from '../../contexts/sep-context/SEPContext';
import FavoriteList from './components/favorite-list/FavoriteList';
import FavoriteFilter from './components/favorite-filter/FavoriteFilter';
import { LOCIZE_PANEL_NS } from './constants';
import FavoritePagination from './components/favorite-pagination/FavoritePagination';
import HyperMenu from '../hyper_menu/HyperMenu';
import env from '../../env/env';
import { useFavorites } from '../../contexts/favorites/FavoriteProvider';
import FavoriteSelectionCheckbox from './components/favorite-pagination/FavoriteSelectionCheckbox';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function Favorites(props) {
  const menuRef = useRef(null);
  const { t } = useTranslation('favorites');
  const { favorites, isLoading } = useFavorites();
  const { user: { jwtParsed } } = useContext(SEPContext).SEPContext;
  const role = jwtParsed
    ? jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    : undefined;
  const client = jwtParsed?.Mandant;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const getContent = () => {
    if (client === 'guest' && role === 'Guest') {
      return (
        <div>
          <h5 style={{ textAlign: 'center', marginTop: '15px' }}>{t(`${LOCIZE_PANEL_NS}:license-needed`)}</h5>
        </div>
      );
    }

    return (
      <Stack
        gap={2}
        sx={{
          p: 2,
          my: 2,
          minWidth: '320px',
          ...(!isMobile && {
            minWidth: '800px',
          }),
        }}
      >
        <Stack>
          <FavoriteFilter />
        </Stack>
        {!isLoading && (
          <Stack width="100%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="32px"
            >
              <FavoriteSelectionCheckbox />
              <FavoritePagination />
            </Stack>
            <Divider flexItem />
          </Stack>
        )}
        <FavoriteList />
        {Object.keys(favorites).length >= 8000 && (
          <div className="alert-wrapper">
            <div className="alert-container">
              <Alert severity="warning" style={{ width: '100%' }}>
                <AlertTitle>{t('exceeded-max-amount-of-favorites', { ns: 'favorites' })}</AlertTitle>
                {t('please-contact-administration', { ns: 'favorites' })}
              </Alert>
            </div>
          </div>
        )}
      </Stack>
    );
  };

  if (role === undefined || client === undefined) {
    return (
      <div
        className="favorites"
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="favorites">
      <HyperMenu
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        ref={menuRef}
        content={
          (
            <div className="menu-content-wrapper">
              <Navbar
                {...props} // eslint-disable-line react/jsx-props-no-spreading
                onMenuToggle={() => {
                  const { isOpen } = menuRef.current;
                  menuRef.current.setIsOpen(!isOpen);
                }}
                isSearchDisplayed={false}
              />
              <Box
                className="Favorites"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'auto',
                }}
              >
                {getContent()}
              </Box>
            </div>
          )
        }
      />
    </div>
  );
}

export default Favorites;
