import * as L from 'leaflet';

const OberEggenburg = [46.948387301863534, 8.357849121093752]; // e.g. center of the swiss
// create leaflet cetner point
export const CENTER_POINT = L.latLng(OberEggenburg[0], OberEggenburg[1]);

export const LEAFLET_MAX_NATIVE_ZOOM_LEVEL = 18;

export const defaultBounds = L.latLngBounds(
  L.latLng(46.126556302418514, 5.721130371093751),
  L.latLng(47.7577909789764, 10.994567871093752),
);

const defaultOptions = {
  maxZoom: 21,
  maxNativeZoom: LEAFLET_MAX_NATIVE_ZOOM_LEVEL,
  opacity: 1,
};

export const LAYERS = {
  swisstopoGrey: {
    name: 'swisstopoGrey',
    url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg',
    options: { ...defaultOptions, className: 'custom-layer swisstopoGrey' },
    overlay: 'swisstopoStreetNames',
  },
  swisstopoColor: {
    name: 'swisstopoColor',
    url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
    options: { ...defaultOptions, className: 'custom-layer swisstopoColor' },
    overlay: 'swisstopoStreetNames',
  },
  swisstopoStreetNames: {
    name: 'swisstopoStreetNames',
    url: 'https://wms.geo.admin.ch/?',
    options: {
      ...defaultOptions,
      layers: 'ch.swisstopo.amtliches-strassenverzeichnis',
      format: 'image/png',
      transparent: true,
      bgcolor: 'ffffff',
      opacity: 0.82,
      className: 'custom-layer swisstopoStreetNames',
      minZoom: 18,
    },
  },
  swisstopoSatellite: {
    name: 'swisstopoSatellite',
    url: 'https://wmts14.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
    options: { ...defaultOptions, className: 'custom-layer swisstopoSatellite' },
    overlay: 'swisstopoStreetNames',
  },
  cadastralWebMapColor: {
    name: 'cadastralWebMapColor',
    url: 'https://wms.geo.admin.ch/?',
    nonTiled: true,
    options: {
      ...defaultOptions,
      className: 'custom-layer cadastralWebMapColor',
      layers: 'ch.kantone.cadastralwebmap-farbe',
      pane: 'tilePane',
    },
  },
};

export const MAP_SERVER = 'https://mapping.services.swissenergyplanning.ch/wms';
export const MAP_SERVER_TEST = 'https://mapping.services.swissenergyplanning.ch/wmstest';

export const MAP_SERVER_LANGUAGE_MAPPER = {
  'de-CH': 'ger',
  'fr-CH': 'fre',
  'it-CH': 'ita',
};
