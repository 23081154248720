import axios from 'axios';
import env from '../../env/env';

export const getPanelSettings = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/panelsettings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const getUserSettings = (jwt) => axios({
  url: `${env.API_GATEWAY_BASE}/api/appsettings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

export const createUserSettings = (jwt, settingsType, settings) => axios({
  method: 'POST',
  url: `${env.API_GATEWAY_BASE}/api/appsettings`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
  data: {
    settingsType,
    settings,
  },
});

export const updateUserSettings = (jwt, settingsId, settingsType, settings) => axios({
  method: 'PUT',
  url: `${env.API_GATEWAY_BASE}/api/appsettings/${settingsId}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
  data: {
    settingsType,
    settings,
  },
});
