import axios from 'axios';
import * as L from 'leaflet';
import * as loglevel from 'loglevel';
import searchResultType from './constants';
import env from '../../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export function createMarkerIcon() {
  /* eslint-disable no-tabs */
  const svgString = `
    <svg width="70" height="100" xmlns="http://www.w3.org/2000/svg">
      <metadata id="metadata2822">image/svg+xml</metadata>
      <g>
        <title>background</title>
        <rect x="-1" y="-1" width="72" height="102" id="canvas_background" fill="none" />
      </g>
      <g>
        <title>Layer 1</title>
        <path d="m34.96515,99.35066c-0.21626,-0.52746 -4.70929,-4.93912 -7.92679,-8.94788c-19.06234,-25.41221 -40.31534,-49.02389 -15.49846,-77.7181c10.98839,-10.65954 24.53781,-11.26764 38.46711,-6.29901c39.2213,22.788 10.8246,59.96254 -7.821,84.40073l-7.22086,8.56426l0.00001,0l-0.00001,0z" id="path4127" fill="rgba(3, 169, 244, 0.82)" stroke="null" />
        <path stroke="null" id="svg_1" d="m52.204,55.34367l-9.65583,-10.0154c1.30081,-2.22486 2.06369,-4.8278 2.06504,-7.61907c-0.00136,-8.14191 -6.35772,-14.73639 -14.20325,-14.7392c-7.84688,0.00281 -14.20596,6.59729 -14.20596,14.73779c0,8.13629 6.35908,14.73077 14.20596,14.73077c2.69241,0 5.2019,-0.79128 7.34688,-2.14054l9.65854,10.01681l4.78862,-4.97116zm-30.5813,-17.63588c0.00813,-5.03441 3.9336,-9.10466 8.78591,-9.1159c4.84959,0.01124 8.77778,4.08149 8.78591,9.1159c-0.00949,5.03159 -3.93631,9.10185 -8.78591,9.11309c-4.8523,-0.01124 -8.77778,-4.08149 -8.78591,-9.11309z" fill-opacity="null" stroke-opacity="null" stroke-width="10" fill="#ffffff" />
      </g>
    </svg>
  `;
  /* eslint-disable no-tabs */
  const iconUrl = `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;

  return L.icon({
    iconUrl,
    iconSize: [36, 52], // size of the icon
    iconAnchor: [18, 52], // point of the icon which will correspond to marker's location
  });
}

export function createNewOption(result, row) {
  return {
    type: result.config.meta.name,
    group: result.config.meta.group,
    groupPriority: result.config.meta.groupPriority,
    query: result.query,
    row,
  };
}

export function createSearchPromises(jwt, endpoints) {
  return endpoints.map((endpoint) => axios({
    ...endpoint,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
      ...(endpoint.headers || {}),
    },
  }));
}

export function detectMatches(option) {
  const { type, row } = option;

  if (type !== searchResultType.PARCEL.NAME && type !== searchResultType.ADDRESS.NAME) {
    return { ...option, matches: [] };
  }

  const matchesFromServer = row.match.split('|');
  const matches = matchesFromServer.map((match) => {
    const fieldValue = row.fields[match];
    return { key: match, value: fieldValue };
  });
  return { ...option, matches };
}

export function formDataWithSearchText(searchQuery) {
  const formData = new FormData();
  formData.append('searchtext', searchQuery);
  return formData;
}

async function getCoordinatesByZipCode(zip, jwt) {
  return axios({
    url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-plz-coordinates/${zip}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  });
}

export async function getGooglePlace(placeId, jwt) {
  return axios({
    url: `${env.API_GATEWAY_BASE}/google-places/details/json?place_id=${encodeURIComponent(placeId)}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  });
}

export const googleDecode = (jwt, searchQuery, cancelToken, sessionToken) => axios({
  url: `${env.API_GATEWAY_BASE}/google-geocode/json?address=${encodeURIComponent(searchQuery)}&components=country:CH&sessiontoken=${sessionToken}`,
  headers: { Authorization: `Bearer ${jwt}` },
  cancelToken,
});

export const googleAutocomplete = (jwt, searchQuery, cancelToken, sessionToken) => axios({
  url: `${env.API_GATEWAY_BASE}/google-places-autocomplete/json?input=${encodeURIComponent(searchQuery)}&types=address&components=country:ch&sessiontoken=${sessionToken}`,
  headers: { Authorization: `Bearer ${jwt}` },
  cancelToken,
});

export function getSearchEndpoints(cancelToken, string) {
  return [
    {
      meta: {
        name: searchResultType.ADDRESS.NAME,
        group: searchResultType.ADDRESS.GROUP,
        groupPriority: searchResultType.ADDRESS.GROUP_PRIORITY,
      },
      url: `${env.API_GATEWAY_BASE}/api/marketsense/searchaddress-advanced`,
      method: 'post',
      cancelToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formDataWithSearchText(string),
      query: string,
    },
    {
      meta: {
        name: searchResultType.PARCEL.NAME,
        group: searchResultType.PARCEL.GROUP,
        groupPriority: searchResultType.PARCEL.GROUP_PRIORITY,
      },
      url: `${env.API_GATEWAY_BASE}/api/marketsense/searchparcel`,
      method: 'post',
      cancelToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formDataWithSearchText(string),
      query: string,
    },
    {
      meta: {
        name: searchResultType.REGION_TOWN.NAME,
        group: searchResultType.REGION_TOWN.GROUP,
        groupPriority: searchResultType.REGION_TOWN.GROUP_PRIORITY,
      },
      url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-town-swisszipcode?town=${encodeURIComponent(string)}`,
      method: 'get',
      cancelToken,
      query: string,
    },
    {
      meta: {
        name: searchResultType.REGION_PLZ.NAME,
        group: searchResultType.REGION_PLZ.GROUP,
        groupPriority: searchResultType.REGION_PLZ.GROUP_PRIORITY,
      },
      url: `${env.API_GATEWAY_BASE}/api/addresspoints-find-swisszipcode-town?zipcode=${encodeURIComponent(string)}`,
      method: 'get',
      cancelToken,
      query: string,
    },
  ];
}

async function fetchCoordinates(option, jwt) {
  let latitude; let
    longitude;

  const { type } = option;
  if (type === searchResultType.GOOGLE.NAME || type === searchResultType.GOOGLE_AUTOCOMPLETE.NAME) {
    const { data } = await getGooglePlace(option.row.place_id, jwt);
    const googlePlace = data.result;
    latitude = googlePlace.geometry.location.lat;
    longitude = googlePlace.geometry.location.lng;
    return { latitude, longitude, googlePlace };
  } if (type === searchResultType.REGION_PLZ.NAME || type === searchResultType.REGION_TOWN.NAME) {
    const { swissZipCode } = option.row;
    const approximateCoordinatesResponse = await getCoordinatesByZipCode(swissZipCode, jwt);
    latitude = approximateCoordinatesResponse.data.lat;
    longitude = approximateCoordinatesResponse.data.long;
  } else {
    try {
      [latitude, longitude] = option.row.fields.lat_long.split(';').map((coord) => parseFloat(coord));
    } catch (e) {
      latitude = undefined;
      longitude = undefined;
      log.debug('onOptionClick - can not set the map view', { option, e });
    }
  }

  return { latitude, longitude };
}

/**
 * Build a formatted address string from Google Places API address components.
 *
 * @param {Array} addressComponents - An array of address components from Google Places API.
 * @returns {string|null} - A formatted address string, or null if there are no address components.
 */
function buildAddressString(addressComponents) {
  // Return null if there are insufficient address components.
  if (addressComponents.length <= 3) {
    return null;
  }

  // Define the structure of the address using an object.
  const address = {
    route: '',
    street_number: '',
    postal_code: '',
    locality: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
  };

  // Iterate through the address components and map them to the according fields.
  addressComponents.forEach((component) => {
    const type = component.types.find((t) => Object.keys(address).includes(t));
    if (type) {
      address[type] = component.long_name;
    }
  });

  if (address.administrative_area_level_2 !== '' && address.administrative_area_level_2.includes(' District')) {
    address.administrative_area_level_2 = address.administrative_area_level_2.replace(' District', '');
  }

  // Create an array to store the different parts of the formatted address.
  const formattedAddressParts = [
    // Add the route and street number, if available.
    `${address.route}${address.street_number ? ` ${address.street_number}` : ''}`,
    // Add the postal code and locality, if available.
    address.postal_code ? `${address.postal_code} ${address.locality}`.trim() : address.locality,
    // Add the administrative area only if it's not equal to the locality.
    address.administrative_area_level_2
    && address.administrative_area_level_2 !== address.locality
      ? address.administrative_area_level_2
      : '',
    // Add the administrative area only if it's not equal to the locality.
    address.administrative_area_level_1
    && address.locality !== address.administrative_area_level_1
    && address.administrative_area_level_1 !== address.administrative_area_level_2
      ? address.administrative_area_level_1
      : '',
  ];

  // Filter out any empty parts, and join the remaining parts with a comma and a space.
  return formattedAddressParts.filter((part) => part).join(', ');
}

function generateLabel(option, t, googlePlace = null) {
  let label;

  switch (option.type) {
    case searchResultType.ADDRESS.NAME:
      label = `${option.row.fields.address}`;
      option.matches.reverse().forEach((match) => {
        if (['egid', 'egrid', 'egaid', 'parz_nr'].includes(match.key)) {
          label = `${label} (${t(`search_bar:res-address-${match.key}`)} ${option.row.fields[match.key]})`;
        }
      });
      break;
    case searchResultType.PARCEL.NAME:
      label = `${t('search_bar:res-parcel')} ${option.row.fields.parz_nr}, ${option.row.fields.districts.split('|').join('/')}`;
      option.matches.reverse().forEach((match) => {
        if (['egrid'].includes(match.key) && !['town', 'districts'].includes(match.key)) {
          label = `${label} (${t(`search_bar:res-parcel-${match.key}`)} ${option.row.fields[match.key]})`;
        }
      });
      break;
    case searchResultType.GOOGLE.NAME:
      if (!buildAddressString(option.row.address_components)) {
        label = null;
      } else if (!buildAddressString(option.row.address_components)
        .toLocaleLowerCase().includes(option.query.toLocaleLowerCase())) {
        label = `${googlePlace.name} (${buildAddressString(option.row.address_components)})`;
      } else {
        label = buildAddressString(option.row.address_components);
      }
      break;
    case searchResultType.GOOGLE_AUTOCOMPLETE.NAME:
      label = `${option.row.description}`;
      if (label.includes(' District')) {
        label = label.replace(' District', '');
      }
      break;
    case searchResultType.REGION_TOWN.NAME:
    case searchResultType.REGION_PLZ.NAME:
      label = `${option.row.swissZipCode} ${option.row.town}`;
      break;
    default:
      label = JSON.stringify(option.row, null, 4);
      break;
  }

  return label;
}

function generateChipLabel(option) {
  if (option.type === searchResultType.REGION_PLZ.NAME) return 'PLZ';
  if (option.type === searchResultType.REGION_TOWN.NAME) return 'PLZ';
  return option.type;
}

export async function generateOptionLabelsAndCoordinates(jwt, option, t) {
  const { latitude, longitude, googlePlace } = await fetchCoordinates(option, jwt);
  let label = null;
  if (latitude && longitude) {
    label = generateLabel(option, t, googlePlace);
  }
  const chipLabel = generateChipLabel(option);

  return {
    ...option, latitude, longitude, label, chipLabel,
  };
}

export const processDataMapping = {
  [searchResultType.ADDRESS.NAME]: { dataKey: 'rows', condition: (result) => !result.data.error },
  [searchResultType.PARCEL.NAME]: { dataKey: 'rows', condition: (result) => !result.data.error },
  [searchResultType.GOOGLE.NAME]: { dataKey: 'results' },
  [searchResultType.GOOGLE_AUTOCOMPLETE.NAME]: { dataKey: 'predictions' },
  [searchResultType.REGION_TOWN.NAME]: { dataKey: undefined },
  [searchResultType.REGION_PLZ.NAME]: { dataKey: undefined },
};

export function processResult(result, processData) {
  const options = [];
  const dataArray = processData.dataKey ? result.data[processData.dataKey] : result.data;
  if (!processData.condition || processData.condition(result)) {
    dataArray.forEach((row) => {
      options.push(createNewOption(result, row));
    });
  }

  return options;
}
