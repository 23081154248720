import { useCallback, useState } from 'react';
import { Button } from 'semantic-ui-react';
import './Navbar.css';
import * as loglevel from 'loglevel';
import Search from '../component_search/Search';
import env from '../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

export default function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const setStateProperty = useCallback((propertyName, propertyValue) => new Promise((res) => {
    if (propertyName === 'isOpen') {
      setIsOpen(propertyValue);
      res({
        isOpen,
      });
    } else {
      // eslint-disable-next-line no-alert
      alert(`unknown setStateProperty ${propertyValue}`);
    }
  }), [isOpen]);
  const getComponents = () => {
    if (
    // eslint-disable-next-line react/destructuring-assignment
      props.isSearchDisplayed
    ) {
      return (
        <div className="input-wrapper">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Search {...props} />
        </div>
      );
    }
    return null;
  };
  const state = {
    isOpen,
  };
  const thisRef = {
    state,
    props,
    setStateProperty,
    getComponents,
  };
  return (
    <div className="Navbar">
      <div className="navbar-container">
        <div className={`element isOpen-${thisRef.state.isOpen}`}>
          <Button
            primary
            active={thisRef.state.isOpen}
            icon="bars"
            size="big"
            style={{ display: 'block' }}
            onClick={() => {
              thisRef.setStateProperty('isOpen', !thisRef.state.isOpen)
                .then(() => {
                  thisRef.props.onMenuToggle();
                });
            }}
          />
        </div>
        {thisRef.getComponents()}
      </div>
    </div>
  );
}
