import {Fragment} from 'react'
import './OverlayDashboard.css'
import { Button, Icon } from 'semantic-ui-react'
import PanelContainer from './panel_container/PanelContainer'
import {useMap} from "../../../contexts/map/map-context";
import * as loglevel from "loglevel";
import env from "../../../env/env";
import {useDashboard} from "../../../contexts/dashboard/DashboardProvider";
import Dimmer from "../../reusable/Dimmer";
import {DASHBOARD} from "../../../contexts/dashboard/constants";
let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)

export function OverlayDashboard (props) {
  const { coverage, setCoverage, isLoading } = useDashboard();

  const { baseLayer } = useMap();

  const getHeight = () => {
    if (coverage === DASHBOARD.HIGH) {
      return 'high'
    }
    if (coverage === DASHBOARD.MID) {
      return 'mid'
    }
    if (coverage === DASHBOARD.LOW) {
      return 'low'
    }
  }

  const getOverlayDashboardButton = (targetCoverage, iconName) => (
    <Button
      key={iconName}
      className={'arrow'}
      icon
      onClick={() => setCoverage(targetCoverage)}>
      <Icon name={iconName} />
    </Button>
  );

  const getOverlayDashboardHandle = () => {
    let buttons = [];
    switch (coverage) {
      case DASHBOARD.LOW:
        buttons.push(getOverlayDashboardButton(DASHBOARD.MID, 'chevron up'));
        break;
      case DASHBOARD.MID:
        buttons.push(getOverlayDashboardButton(DASHBOARD.HIGH, 'chevron up'));
        buttons.push(getOverlayDashboardButton(DASHBOARD.LOW, 'chevron down'));
        break;
      case DASHBOARD.HIGH:
        buttons.push(getOverlayDashboardButton(DASHBOARD.MID, 'chevron down'));
        break;
      default:
        break;
    }

    return <h4>{buttons}</h4>;
  }

  const getMapAttribution = () => {
    const swisstopoAttr = {
      label: "swisstopo",
      link: "https://www.swisstopo.admin.ch/"
    }

    const attributionMap = {
      swisstopoGrey: { ...swisstopoAttr },
      swisstopoColor: { ...swisstopoAttr },
      swisstopoStreetNames: { ...swisstopoAttr },
      swisstopoSatellite: { ...swisstopoAttr },
      cadastralWebMapColor: {
        label: "cadastre",
        link: "https://www.cadastre.ch/"
      }
    };

    const attribution = attributionMap[baseLayer.name];
    if (attribution) {
      return (
        <div className={'map-attribution'}>
          <a href={attribution.link} target={'_blank'}>
            {attribution.label}
          </a>
        </div>
      );
    }

    return null;
  }

  return (
    <div className={`OverlayDashboard ${getHeight()}`}>
      {getMapAttribution()}
      <div className={'handle'}>
        {getOverlayDashboardHandle()}
        <div className={`geoimpact-link`}>
          <Fragment>
            SEP by <a href={`https://www.geoimpact.ch/`} target={`_blank`}>geoimpact</a>
          </Fragment>
        </div>
      </div>
      {isLoading && <Dimmer />}
      <PanelContainer {...props} useSuspense={true} fallback={<div>Loading...</div>}/>
    </div>
  )
}
