/* eslint brace-style: ["error", "stroustrup"] */
import {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import PlaceIcon from '@mui/icons-material/Place';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupsIcon from '@mui/icons-material/Groups';
import ShareIcon from '@mui/icons-material/Share';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import * as L from 'leaflet';
import {
  Autocomplete, IconButton, TextField, Checkbox, Box,
  ListItem, createFilterOptions, CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './FavoriteItem.css';
import * as loglevel from 'loglevel';
// import { useConfirm } from 'material-ui-confirm';
import { patchFavorite } from '../../services/FavoriteApi';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import { useSelection } from '../../../../contexts/selection/selection-context';
import { useMap } from '../../../../contexts/map/map-context';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import ModalNotification from '../favorite-modals/ModalNotification';
import ModalRecipients from '../favorite-modals/ModalRecipients';
import { API, getLeafletBounds } from '../favorite-modals/utils';
import { FAVORITE_TYPE } from '../../../../contexts/favorites/utils';
import { SELECTION_MODES } from '../../../../contexts/selection/constants';
import { LAYERS } from '../../../../contexts/map/constants';
import ModalWktPreview from '../favorite-modals/ModalWktPreview';
import ModalShare from '../favorite-modals/ModalShare';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteItem(props) {
  const { favorite } = props;
  const isPolygon = favorite && favorite.favoriteType === FAVORITE_TYPE.POLYGON;
  const context = useMemo(() => {
    if (!favorite) return {};
    try {
      return JSON.parse(favorite.context);
    }
    catch (e) {
      return {};
    }
  }, [favorite]);
  const {
    groups,
    selectedFavorites,
    setSelectedFavorites,
    loadingFavorites,
    setSelectedTypes,
    fetchFavorites,
    filterGroup,
    filterType,
    favorites,
    sortedFavorites,
    setItemsPerPage,
    setLoadingFavorites,
    paginatedFavorites,
  } = useFavorites();

  const targetFavorites = useMemo(
    () => Object.keys(selectedFavorites)
      .filter((favoriteKey) => paginatedFavorites.includes(favoriteKey))
      .map((favoriteKey) => favorites[favoriteKey]),
    [selectedFavorites, favorites, paginatedFavorites],
  );

  const { t } = useTranslation('favorites');
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { setPoints, setPolygons, setSelectionMode } = useSelection();
  const { setDesiredView, setBaseLayer, baseLayer } = useMap();
  const navigate = useNavigate();
  // const confirm = useConfirm();

  const [groupValue, setGroupValue] = useState(favorite?.group || []);
  const [groupOptions, setGroupOptions] = useState(groups);
  const [checked, setChecked] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false);
  const [wktModalOpen, setWktModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [layerName, setLayerName] = useState('swisstopoGrey');

  useEffect(() => {
    if (!favorite) return;
    if (selectedFavorites[`${favorite.id}_${favorite.favoriteType}`]) {
      setChecked(true);
    }
    else {
      setChecked(false);
    }
  }, [selectedFavorites, favorite]);

  useEffect(() => {
    if (!favorite) return;

    setGroupValue(favorite.group);
  }, [favorite]);

  const handleLatLngNavigation = useCallback(() => {
    navigate('/map');
    if (isPolygon) {
      const { wkt } = favorite;
      setPolygons([wkt]);
      setSelectionMode(SELECTION_MODES.POLYGON);
      setBaseLayer(LAYERS[baseLayer.name || 'swisstopoGrey']);
      setDesiredView({ bounds: getLeafletBounds(context?.map?.bounds) });
    }
    else {
      setSelectionMode(SELECTION_MODES.COMPLETE_BUILDING);
      const point = L.latLng(favorite.lat, favorite.long);
      setDesiredView({ point, zoom: 19 });
      setPoints([point]);
    }
  }, [
    baseLayer.name,
    context?.map?.bounds,
    favorite,
    isPolygon,
    navigate,
    setBaseLayer,
    setDesiredView,
    setPoints,
    setPolygons,
    setSelectionMode,
  ]);

  const rowsPerPageOptions = useMemo(() => {
    const dataLength = filterGroup === 'all' && filterType === 'ALL'
      ? Object.values(favorites).length : sortedFavorites.length;
    const maxOption = Math.min(50, dataLength);
    const o = [5, 10, 25].filter((option) => option <= maxOption);

    if (maxOption === dataLength) {
      o.push(dataLength); // Include actual length if it does not exceed 50
    }
    else {
      o.push(50); // Otherwise, include 50 as the last option
    }
    return o;
  }, [favorites, filterGroup, filterType, sortedFavorites.length]);

  const reloadFavorites = useCallback(async () => {
    const withLoading = false;
    await fetchFavorites(withLoading);
  }, [fetchFavorites]);

  const setLoadingFavorite = useCallback((isLoading) => setLoadingFavorites((prevLoading) => ({
    ...prevLoading,
    [`${favorite.id}_${favorite.favoriteType}`]: isLoading,
  })), [setLoadingFavorites, favorite]);

  const handleFavoriteGroupChange = useCallback(async (newGroup) => {
    if (!favorite) return;
    const group = typeof newGroup === 'object' ? newGroup.inputValue : newGroup;
    try {
      setLoadingFavorite(true);
      await patchFavorite(jwt, favorite, { group });
      await reloadFavorites();
      setGroupValue(group);
      setGroupOptions((prevState) => {
        if (!prevState.includes(group)) {
          return [...prevState, group];
        }
        return prevState;
      });
      setLoadingFavorite(false);
    }
    catch (e) {
      log.error('Cannot update favorite group', e);
      setLoadingFavorite(false);
    }
  }, [favorite, jwt, setLoadingFavorite, reloadFavorites]);

  const handleFavoriteSelection = useCallback((event) => {
    if (!favorite) return;
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      setSelectedFavorites((prevState) => ({
        ...prevState,
        [`${favorite.id}_${favorite.favoriteType}`]: true,
      }));
    }
    else {
      setSelectedFavorites((prevState) => {
        const newState = { ...prevState };
        delete newState[`${favorite.id}_${favorite.favoriteType}`];
        return newState;
      });
    }
  }, [favorite, setSelectedFavorites]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });

  return favorite && (
    <ListItem disablePadding sx={{ py: 0.5 }} className="FavoriteItem">
      {!!loadingFavorites[`${favorite.id}_${favorite.favoriteType}`] && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'rgba(0,0,0,.85)',
            userSelect: 'none',
            pointerEvents: 'none',
            zIndex: '1000',
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <div className="container">
        <Checkbox
          checked={checked}
          onChange={handleFavoriteSelection}
        />
        <div className="info">
          {`${favorite.name} (${t(`event-type-${favorite.favoriteType.toLowerCase()}`)})`}
        </div>
        <div className="group-dropdown" style={{ wordBreak: 'break-all' }}>
          <Autocomplete
            freeSolo
            disableClearable
            value={groupValue}
            fullWidth
            sx={{ minWidth: 100 }}
            size="small"
            options={groupOptions}
            getOptionLabel={(option) => (typeof option === 'object' ? option.inputValue : option)}
            renderInput={(params) => (
              <TextField
                {...params} // eslint-disable-line react/jsx-props-no-spreading
                label={t('favorites:select-target-group')}
              />
            )}
            onChange={(e, newGroup) => handleFavoriteGroupChange(newGroup)}
            filterOptions={(opts, params) => {
              const filtered = filterOptions(opts, params);
              if (filtered.length === 0 && params.inputValue !== '') {
                return [{
                  label: `${params.inputValue} (${t('create-new-group')})`,
                  inputValue: params.inputValue,
                }];
              }
              return filtered;
            }}
            renderOption={(optionProps, option) => {
              if (typeof option === 'object' && option.label) {
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <li {...optionProps}>{option.label}</li>;
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <li {...optionProps}>{option}</li>;
            }}
          />
        </div>
        <div className="actions">
          <IconButton
            disabled={
              (!isPolygon && (!favorite.lat || !favorite.long))
              || (isPolygon && !favorite.wkt)
              || (isPolygon && !context?.map?.bounds)
            }
            onClick={() => handleLatLngNavigation(favorite)}
            sx={{ color: 'black' }}
          >
            <PlaceIcon />
          </IconButton>
          {!Object.keys(targetFavorites).length > 0 && (
            <>
              {!isPolygon && (
                <>
                  <IconButton
                    onClick={() => {
                      setSelectedTypes([favorite.favoriteType]);
                      setNotificationModalOpen(true);
                    }}
                    sx={{ color: 'black' }}
                  >
                    <NotificationsIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setRecipientsModalOpen(true);
                    }}
                    sx={{ color: 'black' }}
                  >
                    <GroupsIcon />
                  </IconButton>
                </>
              )}
              {isPolygon && (
                <>
                  <IconButton
                    onClick={() => {
                      setWktModalOpen(true);
                      setLayerName(baseLayer.name || 'swisstopoGrey');
                    }}
                    sx={{ color: 'black' }}
                  >
                    <TravelExploreIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setShareModalOpen(true)}
                    sx={{ color: 'black' }}
                  >
                    <ShareIcon />
                  </IconButton>
                </>
              )}
              <IconButton
                onClick={async () => {
                  const alertText = t('are-you-sure-you-want-delete-the-favorite');
                  // const isOk = await confirm({
                  //   title: t('delete-confirm-title'),
                  //   description: alertText,
                  //   confirmationText: t('delete-confirm-ok'),
                  //   confirmationButtonProps: {
                  //     color: 'warning',
                  //   },
                  //   cancellationText: t('delete-confirm-cancel'),
                  // })
                  //   .then(() => true)
                  //   .catch(() => false);
                  // eslint-disable-next-line no-alert
                  const isOk = window.confirm(alertText);
                  if (isOk) {
                    try {
                      await API[favorite.favoriteType].deleteFavorite(jwt, favorite.id);
                      await fetchFavorites();
                      const nextValidOption = [25, 10, 5].find((option) => rowsPerPageOptions
                        .includes(option)) || rowsPerPageOptions[0];
                      setItemsPerPage(nextValidOption);
                    }
                    catch (error) {
                      log.error(`Failed to delete favorite: ${error}`);
                    }
                  }
                }}
                sx={{ color: 'black' }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
      </div>
      {notificationModalOpen && (
        <ModalNotification
          open={notificationModalOpen}
          onNotificationModalClose={async (isPristine) => {
            setNotificationModalOpen(false);
            if (!isPristine) { // if something was changed trigger reload
              setLoadingFavorite(true);
              await reloadFavorites();
              setLoadingFavorite(false);
            }
          }}
          targetFavorites={[favorite]}
        />
      )}
      {recipientsModalOpen && (
        <ModalRecipients
          open={recipientsModalOpen}
          onRecipientsModalClose={async (isPristine) => {
            setRecipientsModalOpen(false);
            if (!isPristine) { // if something was changed trigger reload
              setLoadingFavorite(true);
              await reloadFavorites();
              setLoadingFavorite(false);
            }
          }}
          targetFavorites={[favorite]}
        />
      )}
      {wktModalOpen && (
        <ModalWktPreview
          wkt={favorite.wkt}
          open={wktModalOpen}
          onClose={() => setWktModalOpen(false)}
          layerName={layerName}
          bounds={context?.map?.bounds}
          zoom={context?.map?.zoom}
        />
      )}
      {shareModalOpen && (
        <ModalShare
          wkt={favorite.wkt}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          layerName={layerName}
          bounds={context?.map?.bounds}
        />
      )}
    </ListItem>
  );
}
