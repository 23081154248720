import {
  LinearProgress, List, ListItem, ListItemButton, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import axios from 'axios';
import { Download } from '@mui/icons-material';
import * as loglevel from 'loglevel';
import IconButton from '@mui/material/IconButton';
import { DateTime } from 'luxon';
import env from '../../env/env';
import SEPContext from '../../contexts/sep-context/SEPContext';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function EnergyMonitoring() {
  const { t, i18n } = useTranslation('account');
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [hasFiles, setHasFiles] = useState(false);
  const { user: { jwt, jwtParsed } } = useContext(SEPContext).SEPContext;
  useEffect(() => {
    (async () => {
      const res = await axios({
        url: `https://opendata.geoimpact.ch/customers/${jwtParsed.Mandant}/${jwtParsed.Mandant}.json`,
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }).catch((e) => {
        log.error('Could not list files', e);
        setFiles([]);
        setIsLoading(false);
        setHasFiles(false);
      });
      if (res) {
        setFiles(res.data.files);
        setIsLoading(false);
        setHasFiles(true);
      }
    })();
  }, [jwt, jwtParsed]);

  const downloadFile = useCallback(async (file) => {
    const res = await axios({
      url: `https://opendata.geoimpact.ch/customers/${jwtParsed.Mandant}/${file}`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      responseType: 'blob', // This ensures the response is a blob
    });
    // Create a URL for the blob
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    // Create a temporary anchor element
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', file); // Set the file name for download
    document.body.appendChild(fileLink); // Append to the body
    fileLink.click(); // Programmatically click the link to trigger the download
    // Clean up by removing the temporary link
    document.body.removeChild(fileLink);
  }, [jwt, jwtParsed.Mandant]);

  const getCreationDate = (creationDate) => DateTime.fromISO(creationDate)
    .setLocale(i18n?.language)
    .toLocaleString(DateTime.DATE_FULL);

  return hasFiles && (
    <Stack spacing={1}>
      <Typography component="h3" variant="b">
        {t('energy-monitoring-title')}
      </Typography>
      {isLoading && <LinearProgress />}
      {!isLoading && files.length !== 0 && (
        <Stack spacing={1}>
          <Typography>
            {t('energy-monitoring-description')}
          </Typography>
          <List mt={2} className="file-list">
            {files.map((file, index) => (
              <ListItemButton key={JSON.stringify({ file, index })}>
                <ListItem
                  sx={{ wordBreak: 'break-all' }}
                  primary={file.name}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await downloadFile(file.name);
                  }}
                  secondaryAction={(
                    <IconButton
                      onClick={async (e) => {
                        e.stopPropagation();
                        await downloadFile(file.name);
                      }}
                    >
                      <Download />
                    </IconButton>
                  )}
                >
                  {`${file.name} (${getCreationDate(file.creation_date)})`}
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      )}
      {!isLoading && files.length === 0 && (
        <Typography>{t('no-files-to-download')}</Typography>
      )}
    </Stack>
  );
}

export default EnergyMonitoring;
