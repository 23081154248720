/* eslint brace-style: ["error", "stroustrup"] */
import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import * as loglevel from 'loglevel';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { IconButton, Stack } from '@mui/material';

// import { useConfirm } from 'material-ui-confirm';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { API } from '../favorite-modals/utils';
import ModalRenameGroup from '../favorite-modals/ModalRenameGroup';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteActionBar() {
  const {
    selectedFavorites,
    setIsLoading,
    fetchFavorites,
    setSelectedFavorites,
    favorites,
    paginatedFavorites,
    setLoadingFavorites,
  } = useFavorites();
  const { t } = useTranslation('favorites');
  // const confirm = useConfirm();
  const { user: { jwt } } = useContext(SEPContext).SEPContext;

  const [groupRenameModalOpen, setGroupRenameModalOpen] = useState(false);

  const targetFavorites = useMemo(
    () => Object.keys(selectedFavorites)
      .filter((favoriteKey) => paginatedFavorites.includes(favoriteKey))
      .map((favoriteKey) => favorites[favoriteKey]),
    [selectedFavorites, favorites, paginatedFavorites],
  );

  const reloadFavorites = useCallback(async () => {
    const withLoading = false;
    await fetchFavorites(withLoading);
  }, [fetchFavorites]);

  const setLoadingTargets = useCallback((isLoading) => {
    const loading = targetFavorites.reduce((acc, curr) => ({
      ...acc,
      [`${curr.id}_${curr.favoriteType}`]: isLoading,
    }), {});

    setLoadingFavorites((prevLoading) => ({
      ...prevLoading,
      ...loading,
    }));
  }, [setLoadingFavorites, targetFavorites]);

  const handleDeleteAllFavorites = useCallback(async () => {
    try {
      setIsLoading(true);
      const requests = targetFavorites.map((favorite) => {
        const { id, favoriteType } = favorite;
        const apiCall = API[favoriteType].deleteFavorite;
        return apiCall(jwt, id)
          .catch((error) => log.error('handleDeleteAllFavorites', error));
      });
      await Promise.all(requests);
      setIsLoading(false);
    }
    catch (error) {
      log.error('handleDeleteAllFavorites', error);
      setIsLoading(false);
    }
  }, [targetFavorites, setIsLoading, jwt]);

  return (
    <Stack direction="row" className="FavoriteActionBar">
      <IconButton
        onClick={() => setGroupRenameModalOpen(true)}
        sx={{ color: 'black' }}
      >
        <FolderIcon />
      </IconButton>
      <IconButton
        style={{
          display: 'flex',
          height: 'fit-content',
          justifySelf: 'center',
          alignSelf: 'center',
        }}
        onClick={async () => {
          const alertText = `${targetFavorites.length} ${t('are-you-sure-you-want-delete-selected-1')} ${t('are-you-sure-you-want-delete-selected-2')}`;
          // const isOk = confirm({
          //   title: t('delete-confirm-title'),
          //   description: alertText,
          //   confirmationText: t('delete-confirm-ok'),
          //   confirmationButtonProps: {
          //     color: 'warning',
          //   },
          //   cancellationText: t('delete-confirm-cancel'),
          // })
          //   .then(() => true)
          //   .catch(() => false);
          // eslint-disable-next-line no-alert
          const isOk = window.confirm(alertText);
          if (isOk) {
            await handleDeleteAllFavorites();
            await fetchFavorites();
            setSelectedFavorites({});
          }
        }}
        sx={{ color: 'black' }}
      >
        <DeleteIcon />
      </IconButton>
      {groupRenameModalOpen && (
        <ModalRenameGroup
          open={groupRenameModalOpen}
          targetFavorites={targetFavorites}
          onRenameModalClose={async (isPristine) => {
            setGroupRenameModalOpen(false);
            if (!isPristine) {
              setLoadingTargets(true);
              await reloadFavorites();
              setLoadingTargets(false);
            }
          }}
        />
      )}
    </Stack>
  );
}
