import { useContext, useState } from 'react';
import {
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  IconButton,
  Collapse,
  Box,
  LinearProgress, Alert,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SortIcon from '@mui/icons-material/Sort';
import { useTranslation } from 'react-i18next';
import { useDashboard } from '../../../../contexts/dashboard/DashboardProvider';
import useToast from '../../../../hooks/useToast';
import SEPContext from '../../../../contexts/sep-context/SEPContext';

export default function UserPanelsSettings() {
  const { t } = useTranslation('account');
  const {
    tenantPanels,
    enablePanel,
    disablePanel,
    isPanelEnabled,
    movePanel,
    isLoading,
  } = useDashboard();
  const toast = useToast();
  const { user: { mandant } } = useContext(SEPContext).SEPContext;

  const [openPanelId, setOpenPanelId] = useState(null);
  const [msOrderMode, setMsOrderMode] = useState(false);
  const [standardOrderMode, setStandardOrderMode] = useState(false);
  const [toggledPanelType, setToggledPanelType] = useState(null);

  const handleToggle = async (panelId) => {
    if (isPanelEnabled(panelId)) {
      const isSuccess = await disablePanel(panelId);
      if (!isSuccess) {
        toast.warning(t('warning-panel-settings-disable'));
      }
    } else {
      await enablePanel(panelId);
    }
  };

  const handleInfoToggle = (panelId) => {
    setOpenPanelId(openPanelId === panelId ? null : panelId);
  };

  const standardPanels = tenantPanels.filter((panel) => panel.standard);
  const msPanels = tenantPanels.filter((panel) => !panel.standard);

  const handleMsOrderModeToggle = () => {
    setMsOrderMode(!msOrderMode);
  };

  const handleStandardOrderModeToggle = () => {
    setStandardOrderMode(!standardOrderMode);
  };

  const renderPanelList = (panels, orderMode, panelType) => (
    panels.length > 0 && (
      <List sx={{ py: 0, '&.MuiList-root': { marginTop: 0 } }}>
        {panels.map((panel, index) => (
          <Box key={panel.panelId}>
            <ListItem disablePadding sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText
                primary={panel.name}
                sx={{
                  flexGrow: 1,
                  pr: 2,
                  wordWrap: 'break-word',
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '38px' }}>
                {orderMode ? (
                  <>
                    <IconButton
                      onClick={async () => {
                        setToggledPanelType(panelType);
                        movePanel(panel.panelId, 'up');
                      }}
                      disabled={index === 0}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      onClick={async () => {
                        setToggledPanelType(panelType);
                        movePanel(panel.panelId, 'down');
                      }}
                      disabled={index === panels.length - 1}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {panel.description && (
                      <IconButton
                        edge="end"
                        onClick={() => handleInfoToggle(panel.panelId)}
                        aria-label="info"
                        sx={{ mr: 0.5 }}
                      >
                        <InfoIcon />
                      </IconButton>
                    )}
                    <Switch
                      checked={isPanelEnabled(panel.panelId)}
                      onChange={async () => handleToggle(panel.panelId)}
                      color="primary"
                      disabled={isLoading}
                    />
                  </>
                )}
              </Box>
            </ListItem>
            {panel.description && (
              <Collapse in={openPanelId === panel.panelId} timeout="auto" unmountOnExit>
                <Box sx={{ pr: 4, pb: 2 }}>
                  <Typography variant="body2">{panel.description}</Typography>
                </Box>
              </Collapse>
            )}
          </Box>
        ))}
      </List>
    )
  );

  const labelPanelSettingsMSPanels = t('label-panel-settings-ms-panels');

  return (
    <Stack spacing={1}>
      <Typography component="h3" variant="b">
        {t('label-panel-settings')}
      </Typography>
      <Typography>
        {t('panel-settings-description')}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="h5" variant="b">
          {labelPanelSettingsMSPanels
            .replace('{tenant}', mandant?.publicName ? mandant.publicName : 'MarketSense')}
        </Typography>
        {msPanels.length > 1 && (
          <IconButton
            onClick={handleMsOrderModeToggle}
            sx={{
              color: msOrderMode ? 'primary.main' : 'text.secondary',
              mx: 1,
            }}
          >
            <SortIcon />
          </IconButton>
        )}
      </Box>
      {isLoading && toggledPanelType === 'ms' && <LinearProgress color="success" />}
      {isLoading && !toggledPanelType && <LinearProgress color="success" />}
      {msPanels.length === 0 && (
        <Alert severity="warning">
          {t('ms-panels-no-panels-configured-yet')}
        </Alert>
      )}
      {renderPanelList(msPanels, msOrderMode, 'ms')}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="h5" variant="b">
          {t('label-panel-settings-standard-panels')}
        </Typography>
        {msPanels.length !== 0 && (
          <IconButton
            onClick={handleStandardOrderModeToggle}
            sx={{
              color: standardOrderMode ? 'primary.main' : 'text.secondary',
              mx: 1,
            }}
          >
            <SortIcon />
          </IconButton>
        )}
      </Box>
      {isLoading && toggledPanelType === 'standard' && <LinearProgress color="success" />}
      {isLoading && !toggledPanelType && <LinearProgress color="success" />}
      {standardPanels.length === 0 && (
        <Alert severity="warning">
          {t('standard-panels-no-panels-configured-yet')}
        </Alert>
      )}
      {renderPanelList(standardPanels, standardOrderMode, 'standard')}
    </Stack>
  );
}
