import * as loglevel from 'loglevel';
import {
  Box, TextField,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import env from '../../../../env/env';
import { getRestoreLink } from '../../../sep_map/overlay_controls/utils';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import { SELECTION_MODES } from '../../../../contexts/selection/constants';
import useToast from '../../../../hooks/useToast';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function ModalShare({
  wkt, open, onClose, layerName, bounds,
}) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const toast = useToast();
  const { t } = useTranslation('controls_map');

  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('');
  const [error, setError] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const newLink = await getRestoreLink(
          layerName,
          bounds,
          { simple: [] },
          jwt,
          [wkt],
          SELECTION_MODES.POLYGON,
        );
        setLink(newLink);
        setIsLoading(false);
      } catch (e) {
        log.error(e);
        setError(e);
        setIsLoading(false);
      }
    })();
  }, [bounds, jwt, layerName, wkt]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('controls_map:copy-to-clip-title')}</DialogTitle>
      <DialogContent>
        <Box display="flex">
          {isLoading && (
            <CircularProgress />
          )}
          {!isLoading && !error && (
            <TextField
              fullWidth
              multiline
              rows={4}
              defaultValue={link}
              InputProps={{ readOnly: true }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          navigator.clipboard.writeText(link).then(() => {
            toast.success(t('various:message-text-copied-ok'));
          }).catch(() => {
            toast.warning(t('various:message-text-copied-error'));
          });
          onClose();
        }}
        >
          {t('controls_map:copy-button-text')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
