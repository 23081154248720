/* eslint brace-style: ["error", "stroustrup"] */
import {
  createContext, useContext, useEffect, useMemo, useState, useRef, useCallback,
} from 'react';
import axios from 'axios';
import * as loglevel from 'loglevel';
import { isEmpty, isEqual } from 'lodash';
import SEPContext from '../sep-context/SEPContext';
import { useMap } from '../map/map-context';
import env from '../../env/env';
import { SELECTION_MODES } from './constants';
import { useDeepState } from '../deepstate/DeepStateProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const SelectionContext = createContext(null);

const createAxiosConfig = (url, jwt) => ({
  url,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${jwt}`,
  },
});

const getAddresses = async (jwt, myEnv, qs, cancelToken) => axios({
  ...createAxiosConfig(`${myEnv.API_GATEWAY_BASE}/api/addresspoints${qs}`, jwt),
  cancelToken,
});

export default function SelectionProvider({ children }) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { mapRef, isMapReady } = useMap();

  const [selection, setSelection] = useState({});
  const [points, setPoints] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [buildingIDs, setBuildingIDs] = useState([]);
  const [buildingCoords, setBuildingCoords] = useState({});
  const [buildingDistrictIdMap, setBuildingDistrictIdMap] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [addressIDs, setAddressIDs] = useState([]);
  const [buildingIDsByAddress, setBuildingIDsByAddress] = useState([]);
  const [egids, setEgids] = useState([]);
  const [isPolygonEditMode, setIsPolygonEditMode] = useState(false);
  const [isDeleteLocked, setIsDeleteLocked] = useState(false);
  const [selectionMode, setSelectionMode] = useState(SELECTION_MODES.COMPLETE_BUILDING);

  const drawnItems = useRef(null);
  const polygonDrawingsRef = useRef([]);

  const cancelTokenSource = useRef(null);

  useEffect(() => {
    // Cancel any previous pending requests
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Cancelling previous address request');
    }

    // Create a new cancel token source for the current request
    cancelTokenSource.current = axios.CancelToken.source();

    (async () => {
      // Here, the selection state is used to fetch and set the state for
      // buildingIDs, addresses, addressIDs, egids, and buildingIDsByAddress
      // This means these state variables are directly dependent on the selection state
      const buildings = selection?.simple?.flatMap((buildingGroup) => buildingGroup.buildings);

      if (!buildings?.length) {
        setBuildingIDs([]);
        setBuildingCoords({});
        setBuildingDistrictIdMap({});
        setAddresses([]);
        setAddressIDs([]);
        setBuildingIDsByAddress([]);
        setEgids([]);
        return;
      }

      const buildingIdsArray = [];
      const buildingCoordinates = {};

      buildings.forEach((building) => {
        buildingIdsArray.push(building.id);
        buildingCoordinates[building.id] = {
          lat: building.coordinates.latitude,
          lng: building.coordinates.longitude,
        };
      });

      setBuildingCoords(buildingCoordinates);

      try {
        // Depending on the selection state, the building IDs, addresses, address IDs,
        // EGIDs and building IDs by address are fetched and set here
        const addr = await getAddresses(jwt, env, `?buildingIdSep=[in]${buildingIdsArray}`, cancelTokenSource.current.token);

        const dataArrays = addr.data.reduce((acc, curr) => {
          acc.egids.push(curr.egid);
          acc.addressIds.push(curr.id);
          acc.buildingIdsByAddress.push(curr.buildingIdSep);
          if (!acc.buildingDistrictIdMap[curr.buildingIdSep]) {
            acc.buildingDistrictIdMap[curr.buildingIdSep] = curr.districtNumber;
          }

          return acc;
        }, {
          // initialize accumulator
          egids: [],
          addressIds: [],
          buildingIdsByAddress: [],
          buildingDistrictIdMap: {},
        });

        const addressIds = [...new Set(dataArrays.addressIds)];
        setAddressIDs(addressIds);

        const uniqueEgids = [...new Set(dataArrays.egids)];
        setEgids(uniqueEgids);

        const uniqueBuildingIdsByAddress = [...new Set(dataArrays.buildingIdsByAddress)];
        setBuildingIDsByAddress(uniqueBuildingIdsByAddress);
        setBuildingIDs(buildingIdsArray);

        setBuildingDistrictIdMap(dataArrays.buildingDistrictIdMap);

        setAddresses(addr.data);
      }
      catch (error) {
        setBuildingIDs(buildingIdsArray);
        if (axios.isCancel(error)) {
          log.warn('Request canceled:', error.message);
        }
        else {
          log.debug('Error:', error.message);
        }
      }
    })();
  }, [jwt, selection?.simple]);

  const { deepLinkState: deepState } = useDeepState();

  useEffect(() => {
    // NOTE: the logic inside of this useEffect
    // is for the backward compatibility of the share
    // functionality and should be changed with attention.
    // Also note that this useEffect is fired only on load time
    // of SEP and not triggered anymore
    if (!deepState) return;

    const newPoints = deepState?.selection?.points;
    const newMode = deepState?.selection?.selectionMode;
    const newPolygons = deepState?.selection?.polygons;
    setPoints((prevState) => {
      if (isEmpty(newPoints)) return prevState;
      return isEqual(prevState, newPoints) ? prevState : newPoints;
    });
    setSelectionMode((prevState) => {
      if (isEmpty(newMode)) return prevState;
      return isEqual(prevState, newMode) ? prevState : newMode;
    });
    setPolygons((prevState) => {
      if (isEmpty(newPolygons)) return prevState;
      return isEqual(prevState, newPolygons) ? prevState : newPolygons;
    });
  }, [deepState]);

  useEffect(() => {
    if (!isMapReady || !points.length) return;
    const selectionEvents = new CustomEvent('sep_deeplink:mapclick', { detail: JSON.stringify(points) });
    window.dispatchEvent(selectionEvents);
    setPoints([]);
  }, [isMapReady, points]);

  const handleClearDrawings = useCallback(() => {
    if (!isMapReady) return;
    if (drawnItems.current) {
      drawnItems.current.clearLayers();
      polygonDrawingsRef.current.forEach((layer) => mapRef.current.removeLayer(layer));
      polygonDrawingsRef.current = [];
    }
    setPolygons([]);
  }, [isMapReady, mapRef]);

  useEffect(() => {
    if (selectionMode === SELECTION_MODES.POLYGON) {
      setSelection({
        simple: [],
      });
      const event = new Event('sep_map-overlay_controls:trash-selection');
      window.dispatchEvent(event);
    }
    if (selectionMode === SELECTION_MODES.COMPLETE_BUILDING) {
      handleClearDrawings();
    }
  }, [selectionMode, handleClearDrawings]);

  const api = useMemo(() => ({
    selection,
    buildingIDs,
    buildingCoords,
    buildingDistrictIdMap,
    addresses,
    addressIDs,
    egids,
    setPoints,
    buildingIDsByAddress,
    setSelection,
    isLocked: isDeleteLocked || isPolygonEditMode,
    isPolygonEditMode,
    setIsLocked: setIsDeleteLocked,
    isPolygonMode: selectionMode === SELECTION_MODES.POLYGON,
    isBuildingMode: selectionMode === SELECTION_MODES.COMPLETE_BUILDING,
    isPBuildingMode: selectionMode === SELECTION_MODES.PARCEL_BUILDING,
    selectionMode,
    setSelectionMode,
    polygons,
    setPolygons,
    clearPolygons: handleClearDrawings,
    drawnItems,
    polygonDrawingsRef,
    setIsPolygonEditMode,
  }), [
    addressIDs,
    addresses,
    buildingCoords,
    buildingDistrictIdMap,
    buildingIDs,
    buildingIDsByAddress,
    egids,
    selection,
    isDeleteLocked,
    isPolygonEditMode,
    selectionMode,
    polygons,
    handleClearDrawings,
  ]);

  return (
    <SelectionContext.Provider value={api}>{children}</SelectionContext.Provider>
  );
}

/**
 * Custom React hook to access the SelectionContext.
 *
 * @returns {Object} The context of SelectionProvider which includes:
 *   - selection: The 'simple' property of 'selection' is intended to store
 *     current version of the selection data. current version of the selection data.
 *   - points: An array of points selected on the map.
 *   - polygons: An array of polygons selected on the map. This state holds the polygons
 *     as "Well Known Text (WKT)".
 *   - buildingIDs: An array of building IDs based on the current selection.
 *   - buildingCoords: An object of building coordinates based on the current selection.
 *   - buildingDistrictIdMap: An object of building district IDs based on the current selection.
 *   - addresses: A state variable holding an array of addresses corresponding
 *     to the building IDs from the current selection.
 *   - addressIDs: A state variable holding an array of address IDs corresponding
 *     to the addresses from the current selection.
 *   - buildingIDsByAddress: An array of building IDs that are associated
 *     with the addresses from the current selection. By comparing its length with
 *     that of buildingIDs, it is possible to determine if there are selections
 *     that have no associated addresses.
 *   - egids: An array of EGIDs (unique identifiers for addresses) based
 *     on the current selection.
 *   - isLocked: A boolean indicating if the selection is currently locked.
 *     Mainly used to disallow the user to remove the selections.
 *   - selectionMode: The current mode of selection. Supports the following modes:
 *     'building', 'polygon'. Use the SELECTION_MODES constant.
 *   - setSelection: A function to update the selection state. Any changes to the
 *     selection state will trigger updates in the other state variables,
 *     as they are directly dependent on the selection state.
 *   - setPoints, setPolygons, setIsLocked, setSelectionMode: Functions to update respective states.
 *   - isPolygonMode, isBuildingMode, isPBuildingMode: Computed booleans.
 *
 * @throws {Error} If the hook is used outside SelectionProvider, an error is thrown.
 */
export const useSelection = () => {
  const ctx = useContext(SelectionContext);
  if (!ctx) {
    throw new Error('useSelection must be used inside a the SelectionContextProvider');
  }
  return ctx;
};
