import {
  Alert, Box, Button, Collapse, Container, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { OpenInNew } from '@mui/icons-material';
import ConfirmationDialog from '../../reusable/ConfirmationDialog';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import EnergyMonitoring from '../../energy_monitoring/EnergyMonitoring';
import UserPanelsSettings from './user_panels_settings/UserPanelsSettings';
import env from '../../../env/env';
import DataCatalog from './data_catalog/DataCatalog';

function UserAccount() {
  const { user } = useContext(SEPContext).SEPContext;
  const { t, i18n } = useTranslation('account');

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const getDataCatalogLink = () => {
    const link = t('data-catalog-link');
    return `${link}/${i18n.language.split('-')[0]}/`;
  };

  const handleSelfInvite = async () => {
    setLoading(true);
    try {
      const selfInviteResponse = await axios(`${env.API_GATEWAY_BASE}/api/register-ckan?jwt=${user.jwt}`);
      // Check if success is false and set the appropriate error message
      if (!selfInviteResponse.data.success) {
        setError(t('data-catalog-self-invite-generic-error'));
        return;
      }

      if (selfInviteResponse.data.success && selfInviteResponse.data.result.state === 'pending') {
        setSuccess(t('data-catalog-self-invite-pending'));
      } else {
        setSuccess(t('data-catalog-self-invite-success'));
      }
    } catch (e) {
      setError(t('data-catalog-self-invite-generic-error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box style={{ overflow: 'auto' }}>
      <Container maxWidth="sm">
        <Box my={3} sx={{ textAlign: 'center' }}>
          <Typography component="h1" variant="b">
            {t('label-page-title')}
          </Typography>
        </Box>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography component="h3" variant="b">
              {t('label-data-catalog')}
            </Typography>
            <Typography>
              {t('data-catalog-description-text')}
            </Typography>
            <Typography>
              {t('label-data-catalog-link')}
            </Typography>
            <Stack spacing={1}>
              {error !== null && (
                <Typography color="error">
                  {error}
                </Typography>
              )}
              <Collapse in={success !== null}>
                <Alert
                  icon={false}
                  severity="info"
                  action={(
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => setSuccess(null)}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                )}
                  sx={{ mb: 2 }}
                >
                  {success}
                </Alert>
              </Collapse>
              <Stack spacing={1} alignItems="center">
                <Button
                  href={getDataCatalogLink()}
                  target="_blank"
                  variant="contained"
                  endIcon={<OpenInNew />}
                  sx={{
                    color: '#fff !important', // important needed since semantic forces link color
                    minWidth: '180px',
                  }}
                >
                  {t('data-catalog-link-text')}
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={() => setOpen(true)}
                  sx={{ minWidth: '180px' }}
                >
                  {t('label-button-data-catalog-self-invite')}
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
          {user?.mandant?.name === 'geoimpact' && (
            <DataCatalog />
          )}
          <EnergyMonitoring />
          <UserPanelsSettings />
        </Stack>
        <ConfirmationDialog
          title={t('data-catalog-self-invite-confirmation-dialog-title')}
          message={t('data-catalog-self-invite-confirmation-dialog-message')}
          open={open}
          onClose={async (isConfirmed) => {
            setOpen(false);
            if (isConfirmed) {
              await handleSelfInvite();
            }
          }}
        />
      </Container>
    </Box>
  );
}

export default UserAccount;
