import {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import * as loglevel from 'loglevel';
import { isEqual } from 'lodash';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

const PortalContext = createContext(null);

export default function PortalProvider({ children }) {
  const [portals, setPortals] = useState({});

  const setPortal = (id, element) => {
    setPortals((prev) => {
      if (prev[id] && isEqual(prev[id], element)) return prev;
      return { ...prev, [id]: element };
    });
  };

  const removePortal = (id) => {
    setPortals((prev) => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
  };

  const getPortal = useCallback((id) => portals[id], [portals]);

  const api = useMemo(() => ({
    portals,
    getPortal,
    setPortal,
    removePortal,
  }), [getPortal, portals]);

  return (
    <PortalContext.Provider value={api}>{children}</PortalContext.Provider>
  );
}

/**
 * Custom hook to access the portal management context.
 * It allows components within the `PortalProvider` to register,
 * retrieve, and remove portal targets.
 *
 * This hook provides an object with the following methods:
 * - `setPortal(id, element)`:  Registers a new portal or updates an
 *                              existing one with the given `id` and DOM `element`.
 * - `getPortal(id)`: Retrieves the DOM element of the portal identified by `id`.
 * - `removePortal(id)`: Removes the portal identified by `id` from the registry.
 *
 * @returns {object}  An object containing `setPortal`, `getPortal`,
 *                    `removePortal`, and `portals` (the current state of all portals).
 *
 * @example
 * // In a component that provides a portal target:
 * const { setPortal } = usePortal();
 *
 * return (
 *   <div ref={(node) => {
 *     if (!node) return;
 *     setPortal(
 *       `unique-portal-id`,
 *       node,
 *     );
 *   }} />
 * );
 *
 * @example
 * // In a component that needs to render into a portal:
 * const { getPortal } = usePortal();
 * const portalElement = getPortal('unique-portal-id');
 * if (!portalElement) return undefined;
 * ReactDOM.createPortal(children, portalElement);
 *
 * // Use `portalElement` with ReactDOM.createPortal, if not null.
 *
 * @throws Will throw an error if used outside a `PortalProvider`.
 */
export const usePortal = () => {
  const ctx = useContext(PortalContext);
  if (!ctx) {
    throw new Error('usePortal must be used inside a the PortalProvider');
  }
  return ctx;
};
