/* eslint brace-style: ["error", "stroustrup"] */
import {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import './EditProfile.css';
import {
  Alert, Button, Switch,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import * as loglevel from 'loglevel';
import { isEmpty } from 'lodash';
import iziToast from 'izitoast';
import { UserUtil } from '../util/user-util';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import env from '../../../env/env';
import { themes } from '../../mui/themes';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function UserInfoDisplay() {
  const context = useContext(SEPContext).SEPContext;
  const { t } = useTranslation(['profile', 'recover'], { useSuspense: true });
  return (
    <div className="UserInfoDisplay">
      <div className="heading">
        {(() => {
          if (context?.user?.mandant?.publicName) {
            return (
              <div className="logo-container">
                <img className="logo" src={`${context?.user?.mandant?.logoUrl}`} alt="client logo" />
              </div>
            );
          }
          return null;
        })()}
        <h1>{t('profile:label-edit-profile')}</h1>
      </div>
      <div className="info-email">
        <h4>{t('profile:label-your-email')}</h4>
        <p>
          {context.user.jwtParsed.sub}
        </p>
      </div>
      <div className="info-role">
        {(() => (
          <div>
            <h4>{t('profile:label-your-permissions')}</h4>
            <div
              className="client-role"
            >
              {t('profile:label-client')}
              {' '}
              {context?.user?.mandant?.publicName}
              : &nbsp;
              {' '}
              {context.user.jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']}
            </div>
          </div>
        ))()}
      </div>
    </div>
  );
}

export default function EditProfile() {
  const [t] = useTranslation(['profile'], { useSuspense: true });

  const [password, setPassword] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [monthlyStatistics, setMonthlyStatistics] = useState(false);
  const [leadAssignment, setLeadAssignment] = useState(false);
  const [settingsId, setSettingsId] = useState(null);

  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
  const sourceRef = useRef(null);

  const [messages, setMessages] = useState([]);

  const [isCommonLogin, setIsCommonLogin] = useState(true);

  const context = useContext(SEPContext).SEPContext;
  const { user: { jwt } } = context;

  const setUserDefaultNotificationSettings = useCallback(async () => {
    const appSettingsEndpoint = `${env.API_GATEWAY_BASE}/api/appsettings`;
    return axios({
      method: 'post',
      url: appSettingsEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        settingsType: 'notifications',
        settings: JSON.stringify({
          monthlyStatistics: true,
          leadAssignment: true,
        }),
      },
    }).then(() => {
      setLeadAssignment(true);
      setMonthlyStatistics(true);
    });
  }, [jwt]);
  const getUserNotificationSettings = useCallback(async () => {
    const appSettingsEndpoint = `${env.API_GATEWAY_BASE}/api/appsettings`;
    const response = await axios({
      url: appSettingsEndpoint,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status !== 200) {
      return {
        notifications: {
          monthlyStatistics: true,
          leadAssignment: true,
          id: null,
        },
      };
    }

    const notificationSettings = response.data.filter((settings) => settings.settingsType === 'notifications')[0];

    if (!notificationSettings) {
      await setUserDefaultNotificationSettings();
      return {
        notifications: {
          monthlyStatistics: true,
          leadAssignment: true,
          id: null,
        },
      };
    }

    return {
      notifications: {
        ...JSON.parse(notificationSettings.settings),
        id: notificationSettings.id,
      },
    };
  }, [jwt, setUserDefaultNotificationSettings]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          url: `${env.API_GATEWAY_BASE}/is-ms-user/`,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        const { isMsUser } = response.data;
        setIsCommonLogin(!isMsUser);
      }
      catch (e) {
        log.error(e);
      }
    })();
  }, [jwt]);

  useEffect(() => {
    const updateUI = async () => {
      const settings = await getUserNotificationSettings();
      setLeadAssignment(settings.notifications.leadAssignment);
      setMonthlyStatistics(settings.notifications.monthlyStatistics);
      setSettingsId(settings.notifications.id);
    };
    updateUI();
  }, [getUserNotificationSettings]);

  useEffect(() => {
    if (!password.length) {
      setPasswordValidationErrors([]);
      return;
    }

    (async () => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${encodeURIComponent(password)}`;
      const { CancelToken } = axios;
      const source = CancelToken.source();
      if (sourceRef.current) {
        sourceRef.current.cancel('Cancelled obsolete request');
      }
      if (password === '') {
        return;
      }
      const response = await axios(
        {
          method: 'get',
          url: endpoint,
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );
      if (response) {
        if (response.data.success) {
          setPasswordValidationErrors([]);
        }
        else if (response.data.success === false) {
          setPasswordValidationErrors([
            response.data.message,
          ]);
        }
      }
    })();
  }, [password]);

  const showPasswordError = (
    password.length > 0
      && passwordRepeat.length > 0
      && password !== passwordRepeat
  );

  const updateRemoteNotificationSettings = async (settings, toggle) => {
    const appSettingsEndpoint = `${env.API_GATEWAY_BASE}/api/appsettings/${settingsId}`;
    return axios({
      method: 'put',
      url: appSettingsEndpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        settingsType: 'notifications',
        settings: JSON.stringify({
          ...{
            monthlyStatistics,
            leadAssignment,
            id: settingsId,
          },
          [settings]: toggle,
        }),
      },
    });
  };

  const isFormComplete = showPasswordError === false;

  const edit = async () => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/changePassword`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({
      oldPassword: passwordOld,
      newPassword: password,
    }),
  });

  const deleteAccount = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/deleteUser`;
    return axios({
      url: endpoint,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  const isDefaultGuest = UserUtil.hasClientAndRole(context, 'guest', 'Guest');
  return (
    <div className="EditProfile" style={{ display: 'flex', flexDirection: 'column' }}>
      {!isEmpty(context) && !isEmpty(context.user) && (
        <UserInfoDisplay />
      )}
      <div className="edit-form">
        {
          !isDefaultGuest ? (
            <div className="email-notification-settings">
              <h4>{t('profile:label-email-notification-settings')}</h4>
              <div className="email-notification-settings-container">
                <div className="notification-switch monthly-email-notification">
                  <p>{t('profile:label-monthly-email-notification')}</p>
                  <Switch
                    checked={monthlyStatistics === true}
                    onChange={async (e) => {
                      setMonthlyStatistics(e.target.checked);
                      await updateRemoteNotificationSettings('monthlyStatistics', e.target.checked);
                    }}
                  />
                </div>
                <div className="notification-switch lead-assignment-notification">
                  <p>{t('profile:label-lead-assignment-notification')}</p>
                  <Switch
                    checked={leadAssignment}
                    onChange={async (e) => {
                      setLeadAssignment(e.target.checked);
                      await updateRemoteNotificationSettings('leadAssignment', e.target.checked);
                    }}
                  />
                </div>
                <div className="notification-switch event-notification">
                  <Alert severity="info" className="alert-root">
                    {/* profile:label-info-event-notification contains
                 a link to the favorites page, that's why we use html. */}
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{
                      __html: t('profile:label-info-event-notification'),
                    }}
                    />
                  </Alert>
                </div>
              </div>
            </div>
          ) : null
        }
        {isCommonLogin && (
          <>
            <h4>{t('profile:label-change-password')}</h4>
            <TextField
              fullWidth
              required
              id="outlined-old-password-input"
              type="password"
              label={t('profile:label-old-password')}
              onChange={(event) => {
                const val = event.target.value;
                setPasswordOld(val);
              }}
              value={passwordOld}
            />
            <Box sx={{ m: 1 }} />
            <TextField
              fullWidth
              required
              id="outlined-new-password-input"
              type="password"
              label={t('profile:label-new-password')}
              onChange={(event) => {
                const val = event.target.value;
                setPassword(val);
              }}
              value={password}
            />
            <Box sx={{ m: 1 }} />
            <div className="password-hints">
              {(() => {
                if (passwordValidationErrors.length > 0) {
                  const errorsString = passwordValidationErrors[passwordValidationErrors.length - 1] || '';
                  const errors = errorsString.split('|');
                  const items = [];
                  errors.forEach((e, i) => {
                    items.push((
                      <li key={JSON.stringify({ e, i })}>{e}</li>
                    ));
                  });
                  return <ul>{items}</ul>;
                }
                return '';
              })()}
            </div>
            <Box sx={{ m: 1 }} />
            <TextField
              fullWidth
              required
              id="outlined-repeat-password-input"
              type="password"
              error={showPasswordError}
              helperText={(() => {
                if (showPasswordError === false) {
                  return false;
                }
                return t('profile:label-new-password-repeat-mismatch');
              })()}
              label={t('profile:label-new-password-repeat')}
              onChange={(event) => {
                const val = event.target.value;
                setPasswordRepeat(val);
              }}
              value={passwordRepeat}
            />
            <Box sx={{ m: 1 }} />
            <div className="column-flex">
              <Button
                variant="contained"
                disabled={!isFormComplete || passwordValidationErrors.length > 0 || (
                  password !== passwordRepeat
                )}
                onClick={async () => edit()
                  .then(() => {
                    iziToast.show({
                      timeout: 3000,
                      theme: 'dark',
                      color: themes.geoimpact.palette.primary.main,
                      zindex: 1301,
                      message: `${t('recover:label-reset-ok-1')}`,
                      position: 'topCenter',
                      drag: false,
                    });
                  })
                  .catch((e) => {
                    log.error('Could not reset your password', e);
                    iziToast.show({
                      timeout: 3000,
                      theme: 'dark',
                      color: themes.geoimpact.palette.warning.main,
                      zindex: 1301,
                      message: `${t('recover:label-reset-error-1')}`,
                      position: 'topCenter',
                      drag: false,
                    });
                  })}
              >
                {t('profile:button-set-password')}
              </Button>
            </div>
            <div className="column-flex">
              <div className="account-delete">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    // eslint-disable-next-line no-alert
                    const namePrompt = prompt(t('profile:delete-prompt'));
                    if (namePrompt !== context.user.jwtParsed.sub) {
                      setMessages((p) => p.concat([
                        `${t('profile:delete-account-not-deleted')}<br/>`
                        + `${t('profile:delete-wrong-email')}<br/><br/><br/><br/><br/><br/>`,
                      ]));
                      document.getElementById('messages').scrollIntoView();
                    }
                    else {
                      return deleteAccount().catch((e) => {
                        log.error('Could not delete your account', e);
                      });
                    }
                    return null;
                  }}
                >
                  {t('profile:button-delete-account')}
                </Button>
              </div>
            </div>
            <div className="footer" id="messages">
              <h5>
                <div
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{
                    __html: (
                      () => {
                        if (messages.length > 0) {
                          return `<div>${messages[messages.length - 1]}</div>`;
                        }
                        return null;
                      }
                    )(),
                  }}
                />
              </h5>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
