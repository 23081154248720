import axios from 'axios';
import { WMSCapabilities } from 'ol/format';
import * as loglevel from 'loglevel';
import { MAP_SERVER, MAP_SERVER_TEST } from './constants';
import env from '../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

// eslint-disable-next-line import/prefer-default-export
export const getLayers = async (url, urlLegend, jwt) => {
  try {
    const response = await axios.get(url);
    const text = response.data;
    const parser = new WMSCapabilities();
    const capabilities = parser.read(text);

    return (capabilities?.Capability?.Layer?.Layer || []).map((layer) => {
      const legendUrl = urlLegend(layer.Name);
      return {
        id: legendUrl.includes('wmstest') ? `${layer.Name}-test` : layer.Name,
        name: layer.Name,
        title: layer.Title,
        abstract: layer.Abstract || '',
        url: legendUrl.includes('wmstest') ? `${MAP_SERVER_TEST}?jwt=${jwt}` : `${MAP_SERVER}?jwt=${jwt}`,
        legendUrl,
        options: {
          layers: layer.Name,
          format: 'image/png',
          transparent: true,
          maxZoom: 21,
        },
      };
    })
      .sort((a, b) => b.title.localeCompare(a.title));
  } catch (error) {
    log.error('Error getting layers', error);
    return [];
  }
};
