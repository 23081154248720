/* eslint brace-style: ["error", "stroustrup"] */
import { useCallback, useEffect, useState } from 'react';
import * as loglevel from 'loglevel';
import { Checkbox, FormControl } from '@mui/material';

import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function FavoriteSelectionCheckbox() {
  const {
    isLoading,
    paginatedFavorites,
    selectedFavorites,
    setSelectedFavorites,
  } = useFavorites();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    if (isLoading || !paginatedFavorites.length) return;
    const allSelected = paginatedFavorites
      .every((favorite) => Object.keys(selectedFavorites).includes(favorite));
    const someSelected = paginatedFavorites
      .some((favorite) => Object.keys(selectedFavorites).includes(favorite));

    setChecked(allSelected);
    setIndeterminate(someSelected && !allSelected);
  }, [paginatedFavorites, selectedFavorites, isLoading]);

  const handleCheckboxChange = useCallback(() => {
    // If currently checked or indeterminate,
    // it should deselect all (set to unchecked and clear selections)
    if (checked || indeterminate) {
      setSelectedFavorites((prevState) => {
        const newState = { ...prevState };
        paginatedFavorites.forEach((favorite) => {
          delete newState[favorite];
        });
        return newState;
      });
      setChecked(false);
      setIndeterminate(false);
    }
    // If currently unchecked, it should select all (set to checked and select all)
    else {
      setSelectedFavorites((prevState) => {
        const newState = { ...prevState };
        paginatedFavorites.forEach((favorite) => {
          newState[favorite] = true;
        });
        return newState;
      });
      setChecked(true);
    }
  }, [checked, indeterminate, paginatedFavorites, setSelectedFavorites]);

  return (
    <FormControl
      sx={{ flexDirection: 'row' }}
      className="FavoriteSelectionBox"
    >
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={handleCheckboxChange}
        sx={{ paddingTop: 0, paddingBottom: 0 }}
        disabled={!paginatedFavorites.length}
      />
    </FormControl>
  );
}
