import './Resend.css';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { useState } from 'react';
import SupportRequest from '../../../reusable/SupportRequest';
import env from '../../../../env/env';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function Resend() {
  const [t] = useTranslation();
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const submit = async () => {
    setMessages((p) => p.concat([
      'Bearbeitung...',
    ]));
    setIsLoading(true);
    const endpoint = `${env.API_GATEWAY_BASE}/api/resend-email`;
    const res = await axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        email,
      }),
    }).catch((e) => {
      const { response } = e;
      if (response.status === 422) {
        const msg = t('sign_up:message-check-form');
        setErrors((p) => p.concat([
          msg,
        ]));
      } else if (e.response.status === 401) {
        setErrors((p) => p.concat([
          t('recover:message-no-account-with-this-mail'),
        ]));
      } else {
        setErrors((p) => p.concat([
          e.message,
        ]));
      }
      setTimeout(() => {
        setErrors([]);
      }, 4000);
      setIsLoading(false);
      return null;
    });
    if (res !== null) {
      setMessages((p) => p.concat([
        'Bearbeitung..ok.',
        t('sign_up:message-confirm-link'),
      ]));
      setIsLoading(false);
      setIsSuccess(true);
    }
  };
  return (
    <div className="Resend">
      <div className="resend-form">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          <h1>{t('sign_up:label-resend-again')}</h1>
        </div>
        <Form loading={isLoading} className={`isSuccess-${isSuccess}`}>
          <div className="column-flex">
            <div className="info">
              {t('sign_up:label-account-already-exists')}
            </div>
          </div>
          <Form.Field>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>{t('sign_up:label-email')}</label>
            <input
              placeholder={t('sign_up:label-email')}
              type="email"
              autoComplete="email"
              onChange={(event) => {
                const val = event.target.value;
                setEmail(val);
              }}
              value={email}
            />
          </Form.Field>
          {messages.length >= 1 && errors.length === 0 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: messages[messages.length - 1],
              }}
            />
          )}
          {errors.length >= 1 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: errors[errors.length - 1],
              }}
            />
          )}
          <div className="column-flex">
            <Button
              primary
              type="submit"
              disabled={email.length <= 0}
              onClick={async () => {
                await submit();
              }}
            >
              {t('sign_up:label-resend')}
            </Button>
          </div>
        </Form>
        <div className="footer">
          <SupportRequest />
        </div>
      </div>
    </div>
  );
}
