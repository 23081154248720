import axios from "axios";

import * as loglevel from "loglevel";
import env from "../env/env";

let log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
    env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN
)


const initLanguageHeaderInterceptor = (i18n) => {
  // Add a request interceptor
  return axios.interceptors.request.use(function (config) {

    // inject the language propery
    try {
      config.headers["Accept-Language"] = i18n.language;
    } catch (e) {
      log.error("language-request-interceptor: intercept error", {config, i18n, e});
      return Promise.reject(e.message);
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
}

const removeLanguageHeaderInterceptor = (interceptor) => {
  // Add a request interceptor
  try{
    axios.interceptors.request.eject(interceptor);
  } catch (e) {
    log.error(e);
  }
}

export {
  initLanguageHeaderInterceptor,
  removeLanguageHeaderInterceptor
}
