/* eslint brace-style: ["error", "stroustrup"] */
import axios from 'axios';
import * as loglevel from 'loglevel';
import * as L from 'leaflet';
import env from '../../../env/env';
import { SELECTION_MODES } from '../../../contexts/selection/constants';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

// eslint-disable-next-line import/prefer-default-export
export async function getRestoreLink(
  layerName,
  bounds,
  selection,
  jwt,
  polygons = [],
  selectionMode = SELECTION_MODES.COMPLETE_BUILDING,
) {
  try {
    const points = [];
    selection.simple.forEach((buildingsElement) => {
      points.push({
        lat: buildingsElement.buildings[0].coordinates.latitude,
        lng: buildingsElement.buildings[0].coordinates.longitude,
      });
    });
    const state = {
      version: 1,
      mapState: {
        selectedBaseLayer: {
          name: layerName,
        },
        bounds,
      },
      selection: {
        points,
        polygons,
        selectionMode,
      },
    };

    // post to get the link to saved shared app state
    const response = await axios({
      method: 'post',
      url: `${env.API_GATEWAY_BASE}/api/shared-settings`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: (() => {
        const formData = new FormData();
        formData.append('entry', JSON.stringify(state));
        return formData;
      })(),
    });

    if (response.data && response.data.key) {
      const link = `${window.location.origin}/map/?sharedState=${response.data.key}`;
      log.debug('shared-settings link: ', link);
      return link;
    }

    return null;
  }
  catch (e) {
    log.error('Can not get shared link: ', e);
    return null;
  }
}

export async function validatePolygon(wktString, jwt) {
  try {
    const response = await axios({
      method: 'post',
      url: `${env.API_GATEWAY_BASE}/api/marketsense/egidfeatures-statistics-by-polygon?srid=4326`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`,
      },
      data: (() => {
        const formData = new FormData();
        formData.append('wkt', wktString);
        return formData;
      })(),
    });

    // Check if numRecords is -1, indicating an exceeded limit
    if (response.data && response.data.numRecords === -1) {
      return 'exceeded';
    }

    return 'valid';
  }
  catch (e) {
    if (e.response && e.response.status === 400) {
      // Return 'invalid' for a 400 status response
      return 'invalid';
    }
    log.error(e);
    return 'error';
  }
}

export function latLngsToWkt(latlngs) {
  // Ensure the polygon is closed by appending the first point to the end
  const coordinates = [...latlngs, latlngs[0]].map((latlng) => `${latlng.lng} ${latlng.lat}`).join(', ');
  return `POLYGON((${coordinates}))`;
}

export const parseWKT = (wktString) => wktString
  .replace('POLYGON((', '')
  .replace('))', '')
  .split(', ')
  .map((pair) => {
    const [lng, lat] = pair.split(' ');
    return [parseFloat(lat), parseFloat(lng)]; // Leaflet uses lat, lng order
  });

export const customSVGIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect x="5" y="5" width="14" height="14" stroke="red" fill="rgba(200, 0, 0, 0.5)" stroke-width="1"/>
</svg>`;

export const svgIcon = new L.DivIcon({
  className: '',
  html: customSVGIcon,
  iconSize: [24, 24],
});

export const columns = {
  gdenr: (t) => t('column_gdenr'),
  parznr: (t) => t('column_parznr'),
  egrid: () => 'EGRID',
  egid: () => 'EGID',
  addresstext: (t) => t('column_addresstext'),
  baujahrstart: (t) => t('column_baujahrstart'),
  baujahrende: (t) => t('column_baujahrende'),
  renovationsjahrstart: (t) => t('column_renovationsjahrstart'),
  renovationsjahrende: (t) => t('column_renovationsjahrende'),
  baugesuch: (t) => t('column_baugesuch'),
  gebkategorieshort: (t) => t('column_gebkategorieshort'),
  gebklasse: (t) => t('column_gebklasse'),
  anzahlwohnungen: (t) => t('column_anzahlwohnungen'),
  energiebezugsflaeche: (t) => t('column_energiebezugsflaeche'),
  bedarfheizung: (t) => t('column_bedarfheizung'),
  bedarfwarmwasser: (t) => t('column_bedarfwarmwasser'),
  bedarfheizleistung: (t) => t('column_bedarfheizleistung'),
  waermeerzeuger_heizung1: (t) => t('column_waermeerzeuger_heizung1'),
  waermequelle_heizung1: (t) => t('column_waermequelle_heizung1'),
  informationsquelle_heizung1: (t) => t('column_informationsquelle_heizung1'),
  aktualisierungsdatum_heizung1: (t) => t('column_aktualisierungsdatum_heizung1'),
  zone: (t) => t('column_zone'),
  zefix_count_egid: (t) => t('column_zefix_count_egid'),
  elec_demand_total_pred: (t) => t('column_elec_demand_total_pred'),
  sumstromertrag_parcel_1: (t) => t('column_sumstromertrag_parcel_1'),
  sumstromertrag_parcelbuilding_1: (t) => t('column_sumstromertrag_parcelbuilding_1'),
  pvprod_power_egid: (t) => t('column_pvprod_power_egid'),
  sumflaeche_parcel_1: (t) => t('column_sumflaeche_parcel_1'),
  sumflaeche_parcelbuilding_1: (t) => t('column_sumflaeche_parcelbuilding_1'),
};

export const getBuildingClass = (t, gebklasse) => t(`label-building-classes-${gebklasse}`, { ns: 'dynamic_panel_marketsense_v4' });
export const getBuildingCategory = (t, gebkategorieshort) => t(`value-gebKategorieShort-${gebkategorieshort}`, { ns: 'dynamic_panel_marketsense_v4' });
export const getHeatGeneration = (t, heatGeneration) => t(`heating-info-value-waermeerzeugerHeizung1-${heatGeneration}`, { ns: 'dynamic_panel_heat' });
export const getHeatSource = (t, heatSource) => t(`gwr-b-value-waermequelleHeizung1-${heatSource}`, { ns: 'dynamic_panel_marketsense_v4' });
export const getHeatInformationSource = (t, heatInformationSource) => t(`heating-info-value-informationsquelleHeizung1-${heatInformationSource}`, { ns: 'dynamic_panel_heat' });
export const getZone = (t, zone) => t(`value-zone-${zone}`, { ns: 'dynamic_panel_marketsense_v4' });
