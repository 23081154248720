import {Stack, Typography, IconButton, Link} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {useTranslation} from "react-i18next";

export default function SupportRequest() {
    const { t } = useTranslation('various');
    return (
        <Stack alignItems="center" className='SupportRequest'>
            <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                {t("various:label-support-request")}
            </Typography>
            <Stack direction="column">
                <Stack direction="row" sx={{
                    alignItems: 'center'
                }}>
                    <IconButton aria-label="delete" href={`mailto:${t('various:support-request-email')}`}>
                        <EmailIcon />
                    </IconButton>
                    <Link color="inherit" href={`mailto:${t('various:support-request-email')}`}>
                        {t('various:support-request-email')}
                    </Link>
                </Stack>
                <Stack direction="row" sx={{
                    alignItems: 'center'
                }}>
                    <IconButton aria-label="delete" href={`tel:${t('various:support-request-phone')}`}>
                        <PhoneIcon />
                    </IconButton>
                    <Link color="inherit" href={`tel:${t('various:support-request-phone')}`}>
                        {t('various:support-request-phone')}
                    </Link>
                </Stack>
            </Stack>
        </Stack>
    )
}
