/* eslint brace-style: ["error", "stroustrup"] */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import './Reset.css';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { useNavigate } from 'react-router-dom';
import SupportRequest from '../../../reusable/SupportRequest';
import env from '../../../../env/env';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import PasswordInput from '../../../reusable/PasswordInput';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function Reset() {
  const [t] = useTranslation(['recover'], { useSuspense: true });
  const [isLoading, setIsLoading] = useState(false);
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [touchedPasswordRepeat, setTouchedPasswordRepeat] = useState(false);
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const navigate = useNavigate();

  const sourceRef = useRef(null);

  const { user } = useContext(SEPContext).SEPContext;

  useEffect(() => {
    if (!password.length) {
      setPasswordValidationErrors([]);
      return;
    }

    (async () => {
      if (sourceRef.current) {
        sourceRef.current.cancel('Cancelling previous request.');
      }
      const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${encodeURIComponent(password)}`;
      const { CancelToken } = axios;
      const source = CancelToken.source();
      sourceRef.current = source;
      await axios({
        url: endpoint,
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (response.data.success) {
            setPasswordValidationErrors([]);
          }
          else {
            setPasswordValidationErrors([
              response.data.message,
            ]);
          }
          return response;
        }).catch((e) => {
          log.error('Could not check password validity', e);
        });
    })();
  }, [password]);

  const isFormComplete = () => (
    (touchedPassword)
    && (touchedPasswordRepeat)
    && (passwordValidationErrors.length === 0)
    && (password === passwordRepeat)
  );
  const sendResetRequest = async () => {
    const token = window.location.href.split('confirmUri=')[1].split('token=')[1].split('&userid=')[0];
    const userId = window.location.href.split('confirmUri=')[1].split('token=')[1].split('&userid=')[1];

    const url = `${env.API_GATEWAY_BASE}/api/confirm-recovery?userid=${userId}&token=${token}&password=${encodeURIComponent(password)}`;
    const { jwt } = user;
    setIsLoading(true);
    /* TODO handle catch/exceptions */
    const response = await axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response !== null) {
      if (response?.data?.success === false) {
        setErrors((p) => p.concat([
          `${t('recover:label-reset-error-1')}<br/>`
              + `${response.data.message}<br/><br/><br/><br/><br/><br/>`,
        ]));
        setPassword('');
        setPasswordRepeat('');
        setIsLoading(false);
      }
      setMessages((p) => p.concat([
        `${t('recover:label-reset-ok-1')}<br/>`
            + `${t('recover:label-reset-ok-2')}`,
      ]));
      setIsLoading(false);
      // redirect the user to the login
      setTimeout(() => {
        navigate('/user/signin/');
      }, 4000);
    }
  };

  return (
    <div className="Reset">
      <div className="reset-form">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          <h1>{t('recover:label-reset-1')}</h1>
        </div>
        <Form loading={isLoading}>
          <Form.Field>
            <label htmlFor="password">{t('recover:label-new-password')}</label>
            <PasswordInput
              id="password"
              placeholder={t('recover:label-new-password')}
              value={password}
              onChange={(event) => {
                const val = event.target.value;
                setPassword(val);
                setTouchedPassword(true);
              }}
            />
          </Form.Field>
          <div className="password-hints">
            {(() => {
              if (passwordValidationErrors.length > 0) {
                const errorsString = passwordValidationErrors[passwordValidationErrors.length - 1];
                const validationErrors = errorsString.split('|');
                const items = [];
                validationErrors.forEach((e, i) => {
                  items.push((
                    <li key={`${JSON.stringify({ e, i })}`}>{e}</li>
                  ));
                });
                return <ul>{items}</ul>;
              }
              return '';
            })()}
          </div>
          <Form.Field>
            <label htmlFor="passwordRepeat">{t('recover:label-repeat-new-password')}</label>
            <PasswordInput
              id="passwordRepeat"
              placeholder={t('recover:label-repeat-new-password-placeholder')}
              value={passwordRepeat}
              onChange={(event) => {
                const val = event.target.value;
                setPasswordRepeat(val);
                setTouchedPasswordRepeat(true);
              }}
            />
          </Form.Field>
          {messages.length > 0 && errors.length === 0 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: `<div>${messages[messages.length - 1]}</div>`,
              }}
            />
          )}
          {errors.length > 0 && (
            <h5
              style={{ color: 'red' }}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: `<div>${errors[errors.length - 1]}</div>`,
              }}
            />
          )}
          <div className="column-flex">
            <Button
              primary
              type="submit"
              disabled={isFormComplete() === false}
              onClick={async () => {
                await sendResetRequest();
              }}
            >
              {t('recover:label-reset-2')}
            </Button>
          </div>
        </Form>
        <div className="footer">
          <SupportRequest />
        </div>
      </div>
    </div>
  );
}
