import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import iziToast from 'izitoast';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import * as loglevel from 'loglevel';
import SEPContext from '../../contexts/sep-context/SEPContext';
import { themes } from '../mui/themes';
import env from '../../env/env';
import { useFavorites } from '../../contexts/favorites/FavoriteProvider';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN,
);

const LOCIZE_PANEL_NS = 'search_bar';
const updateEventName = 'favorites-update';
export const campaignEventsDescriptions = [
  'region_eventtype_description_7',
  'region_eventtype_description_10',
  'region_eventtype_description_11',
  'region_eventtype_description_9',
  'region_eventtype_description_12',
  'region_eventtype_description_13',
  'region_eventtype_description_14',
  'region_eventtype_description_50',
];
function getIzitoastColor() {
  const color = themes.geoimpact.palette.primary.main;
  return color;
}

export default function AddFavorite(props) {
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });
  const { fetchFavorites } = useFavorites();
  const context = useContext(SEPContext).SEPContext;
  const { result, type } = props;
  const addFavoriteRegion = async (myResult) => {
    const { jwt } = context.user;
    const endpoint = `${env.API_GATEWAY_BASE}/api/favoriteregions`;

    let data = {
      group: 'default',
      name: `${myResult.payload.town}`,
      swissZipCode: null,
      town: null,
    };
    if (myResult.payload.swissZipCode !== null) {
      // not whole switzerland, but actual regions
      data = {
        group: 'default',
        name: `${myResult.payload.swissZipCode} ${myResult.payload.town}`,
        swissZipCode: myResult.payload.swissZipCode,
        town: myResult.payload.town,
      };
    }

    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };
  const getEventTypesForRegions = async () => {
    const { jwt } = context.user;
    const endpoint = `${env.API_GATEWAY_BASE}/api/eventtypesforregions`;
    const data = {};
    return axios(
      {
        method: 'get',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };
  const addEventTypeForRegion = async (favoriteRegionId, eventTypeId) => {
    const { jwt } = context.user;
    const endpoint = `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteRegionId}/eventtypes/${eventTypeId}`;
    const data = {};
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };
  const onRegionAdd = async (myResult) => {
    const addFavoriteRegionResponse = await addFavoriteRegion(myResult);
    const eventTypesForRegionsResponse = await getEventTypesForRegions();
    if (addFavoriteRegionResponse.status === 201) {
      await fetchFavorites();
      try {
        const toasts = document.querySelectorAll('.loading-search-toast');
        toasts.forEach((toast) => {
          iziToast.hide({}, toast);
        });
      } catch (e) {
        log.warn('iziToast error', e);
      }
      const message = myResult.payload.swissZipCode !== null ? `${t(`${LOCIZE_PANEL_NS}:add-region-favorite`)}: ${myResult.payload.swissZipCode} ${myResult.payload.town}`
        : `${t(`${LOCIZE_PANEL_NS}:add-region-favorite`)}: ${myResult.payload.town}`;
      iziToast.show({
        theme: 'dark',
        id: 'loading-search-toast',
        color: getIzitoastColor(props),
        zindex: 1001, // must be higher than the modal
        message,
        position: 'topCenter',
        drag: false,
      });
    } else if (addFavoriteRegionResponse.status === 200) {
      try {
        const toasts = document.querySelectorAll('.loading-delete-selection');
        toasts.forEach((toast) => {
          iziToast.hide({}, toast);
        });
      } catch (e) {
        log.warn('iziToast error', e);
      }
      const message = myResult.payload.swissZipCode !== null ? `${t(`${LOCIZE_PANEL_NS}:add-region-favorite-already-in-group`)} "default": ${myResult.payload.swissZipCode} ${myResult.payload.town}`
        : `${t(`${LOCIZE_PANEL_NS}:add-region-favorite-already-in-group`)} "default": ${myResult.payload.town}`;
      iziToast.show({
        theme: 'dark',
        id: 'loading-search-toast',
        color: '#ff7c00',
        zindex: 1001, // must be higher than the modal
        message,
        position: 'topCenter',
        drag: false,
      });
    }
    Promise.all(eventTypesForRegionsResponse.data.map((eventType) => {
      const favoriteRegion = addFavoriteRegionResponse.data;
      if (myResult.payload.swissZipCode !== null) {
        return addEventTypeForRegion(favoriteRegion.id, eventType.id);
      }
      // whole switzerland, just add the campaign events
      // eslint-disable-next-line no-lonely-if
      if (campaignEventsDescriptions.includes(eventType.descriptionLocize)) {
        // one of campaign event types
        return addEventTypeForRegion(favoriteRegion.id, eventType.id);
      }
      return null;
    }).filter((promise) => promise !== null)).then(() => {
      const event = new Event(updateEventName);
      // Dispatch the event.
      document.body.dispatchEvent(event);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const getEventTypesForAddress = async () => {
    const { jwt } = context.user;
    return axios({
      method: 'get',
      url: `${env.API_GATEWAY_BASE}/api/eventtypesforaddresses`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: JSON.stringify({}),
    });
  };
  const onAddressAdd = async (addressId, addressText) => {
    const { jwt } = context.user;
    const endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-addressid/${addressId}`;
    const data = {};
    return axios(
      {
        method: 'post',
        url: endpoint,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        data: JSON.stringify(data),
      },
    ).then(async (res) => {
      if (res?.data && res.data.length > 0) {
        await fetchFavorites();
        const message = `${addressText} ${t('favorites:single-address-added')}`;
        iziToast.show({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: getIzitoastColor(props),
          zindex: 5,
          message: `${message}`,
          position: 'topCenter',
          drag: false,
        });
      } else if (res?.data && res.data.length === 0) {
        const message = `${addressText} ${t('favorites:single-address-already-added')}`;
        iziToast.warning({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: '#ff7c00',
          zindex: 5,
          message: `${message}`,
          position: 'topCenter',
          drag: false,
        });
      }
    });
  };
  return (
    <IconButton
      onClick={async (e) => {
        e.stopPropagation();
        if (type === 'region') {
          await onRegionAdd(result);
        } else if (type === 'address') {
          await onAddressAdd(result.payload.fields.id, result.payload.fields.address);
        }
      }}
      style={{ paddingBottom: 0, paddingTop: 0 }}
    >
      <StarBorderIcon />
    </IconButton>
  );
}
