/* eslint brace-style: ["error", "stroustrup"] */
import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import * as loglevel from 'loglevel';
import { useTranslation } from 'react-i18next';
import {
  FAVORITE_TYPE,
  FAVORITES_SORT_BY,
  getAddressName,
} from './utils';
import {
  getEventTypesForAddresses, getEventTypesForRegions,
  getFavoriteAddressesCount,
  getFavoriteByAddresses,
  getFavoriteByRegions, getFavoriteRegionsCount,
  getFavoriteSortingMethod,
  updateFavoriteSortingMethod,
  getFavoritesByPolygons, getExistingRecipients,
} from './API';
import env from '../../env/env';
import useLoading from '../../hooks/useLoading';
import SEPContext from '../sep-context/SEPContext';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export const FavoriteContext = createContext(null);

export default function FavoriteProvider({ children }) {
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { t } = useTranslation('events');

  const [loadingStack, setIsLoading] = useLoading();
  const [favorites, setFavorites] = useState({});
  const [sortedFavorites, setSortedFavorites] = useState([]);
  const [selectedFavorites, setSelectedFavorites] = useState({});
  const [loadingFavorites, setLoadingFavorites] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [eventTypes, setEventTypes] = useState({
    [FAVORITE_TYPE.REGION]: [],
    [FAVORITE_TYPE.ADDRESS]: [],
    // [FAVORITE_TYPE.POLYGON]: [],
  });

  // Filtering and sorting of Favorites List Component
  const [sortingBy, setSortingBy] = useState(FAVORITES_SORT_BY.NAME_ASC);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [filterGroup, setFilterGroup] = useState('all');
  const [filterType, setFilterType] = useState('ALL');
  const [nameFilter, setNameFilter] = useState(null);

  // we need counter which is updated by backend data
  // we can't use the Object.keys(favorites).length since backend
  // sends max 8000 entries but the actual counter may be higher
  const [counter, setCounter] = useState(null);
  const [recipients, setRecipients] = useState({});
  const [currentRecipients, setCurrentRecipients] = useState([]);
  const [activeRecipients, setActiveRecipients] = useState({});
  const [favoriteSettingsId, setFavoriteSettingsId] = useState(null);

  const paginatedFavorites = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedFavorites.slice(startIndex, endIndex);
  }, [currentPage, itemsPerPage, sortedFavorites]);

  const fetchFavorites = useCallback(async (withLoading = true) => {
    if (!jwt) return;

    try {
      if (withLoading) setIsLoading(true);
      const [
        regionFavorites,
        addressFavorites,
        polygonFavorites,
        existingRecipients,
      ] = await Promise.all([
        getFavoriteByRegions(jwt),
        getFavoriteByAddresses(jwt),
        getFavoritesByPolygons(jwt),
        getExistingRecipients(jwt),
      ]);

      const newRegionFavorites = {};
      const newGroups = new Set();
      const newRecipients = {};
      const newActiveRecipients = {};

      const extractGroupsAndRecipients = (favorite) => {
        newGroups.add(favorite.group);
        favorite.recipients.forEach((recipient) => {
          if (!newRecipients[recipient.email]) {
            newRecipients[recipient.email] = recipient;
          }
          if (recipient.isConfirmed && !newActiveRecipients[recipient.email]) {
            newActiveRecipients[recipient.email] = recipient;
          }
        });
      };

      regionFavorites.data.forEach((favorite) => {
        const key = `${favorite.id}_${FAVORITE_TYPE.REGION}`;
        newRegionFavorites[key] = {
          ...favorite,
          favoriteType: FAVORITE_TYPE.REGION,
        };
        extractGroupsAndRecipients(favorite);
      });

      const newPolygonFavorites = {};
      polygonFavorites.data.forEach((favorite) => {
        const key = `${favorite.id}_${FAVORITE_TYPE.POLYGON}`;
        newPolygonFavorites[key] = {
          ...favorite,
          recipients: [],
          favoriteType: FAVORITE_TYPE.POLYGON,
        };
        newGroups.add(favorite.group);
        // extractGroupsAndRecipients(favorite);
      });

      const newAddressFavorites = {};
      addressFavorites.data.forEach((favorite) => {
        const key = `${favorite.id}_${FAVORITE_TYPE.ADDRESS}`;
        newAddressFavorites[key] = {
          ...favorite,
          name: getAddressName(favorite),
          favoriteType: FAVORITE_TYPE.ADDRESS,
        };
        extractGroupsAndRecipients(favorite);
      });

      // combining in to {[id_type]: item};
      const combinedFavorites = {
        ...newRegionFavorites,
        ...newAddressFavorites,
        ...newPolygonFavorites,
      };

      setCurrentRecipients(existingRecipients.data);
      setFavorites(combinedFavorites);
      setGroups(Array.from(newGroups));
      setRecipients(newRecipients);
      setActiveRecipients(newActiveRecipients);
      if (withLoading) setIsLoading(false);
    }
    catch (e) {
      log.error('Can not fetch favorites: ', e);
      if (withLoading) setIsLoading(false);
    }
  }, [setIsLoading, jwt]);
  // const reFetchLoadingFavorites = useCallback(async () => {}, [loadingFavorites]);

  // effect which gets the data
  useEffect(() => {
    (async () => {
      await fetchFavorites();
    })();
  }, [fetchFavorites]);

  // effect which updates single entries according to loadingFavorites state
  // TODO: this effect should get new data from backend for each entry
  //  in the loadingFavorites and replace the values from favorites state
  //  without triggering the whole loading state, so user does not notice
  //  the in-place replacement of newly loaded favorites

  // effect which gets the sorting method
  useEffect(() => {
    if (!jwt) return;

    (async () => {
      try {
        setIsLoading(true);
        const sortingMethod = await getFavoriteSortingMethod(jwt);
        setSortingBy(sortingMethod.sort);
        setFavoriteSettingsId(sortingMethod.id);
        setIsLoading(false);
      }
      catch (e) {
        log.error('Can not fetch settings: ', e);
        setIsLoading(false);
      }
    })();
  }, [setIsLoading, jwt]);

  // effect which updates the sorting method
  useEffect(() => {
    if (!favoriteSettingsId) return;
    (async () => {
      try {
        setIsLoading(true);
        await updateFavoriteSortingMethod(jwt, favoriteSettingsId, sortingBy);
        setIsLoading(false);
      }
      catch (e) {
        log.error('Can not fetch settings: ', e);
        setIsLoading(false);
      }
    })();
  }, [sortingBy, jwt, favoriteSettingsId, setIsLoading]);

  // effect which updates the sorting reference array
  useEffect(() => {
    const sortedKeys = Object.keys(favorites)
      .filter((key) => {
        const favorite = favorites[key];

        const nameMatches = !nameFilter
          || favorite.name.toLowerCase().includes(nameFilter.toLowerCase());

        const groupMatches = !filterGroup
          || filterGroup === 'all'
          || favorite.group === filterGroup;

        const typeMatches = !filterType
          || filterType === FAVORITE_TYPE.NO_FILTER
          || favorite.favoriteType === filterType;

        return nameMatches && groupMatches && typeMatches;
      })
      .sort((a, b) => {
        const favoriteA = favorites[a];
        const favoriteB = favorites[b];

        if (sortingBy === FAVORITES_SORT_BY.NAME_ASC || sortingBy === FAVORITES_SORT_BY.NAME_DES) {
          const nameA = favoriteA.name.toUpperCase();
          const nameB = favoriteB.name.toUpperCase();
          return sortingBy === FAVORITES_SORT_BY.NAME_ASC
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
        }

        const dateA = new Date(favoriteA.lastUpdated);
        const dateB = new Date(favoriteB.lastUpdated);

        return sortingBy === FAVORITES_SORT_BY.LAST_UPDATED_DES
          ? dateA - dateB
          : dateB - dateA;
      });
    setSortedFavorites(sortedKeys);
  }, [favorites, sortingBy, nameFilter, filterGroup, filterType]);

  // effect which updates the counter
  useEffect(() => {
    if (!jwt) return;

    // if the entries are less than 8000 we don't have to get
    // the actual amount of entries from backend
    if (Object.keys(favorites).length <= 8000) {
      setCounter(Object.keys(favorites).length);
    }
    else {
      (async () => {
        try {
          setIsLoading(true);
          const [regionsCount, addressesCount] = await Promise.all([
            getFavoriteRegionsCount(jwt),
            getFavoriteAddressesCount(jwt),
          ]);
          const count = regionsCount.data.count + addressesCount.data.count;
          setCounter(count);
          setIsLoading(false);
        }
        catch (e) {
          log.error('Can not fetch counters of region or address favorites: ', e);
          setIsLoading(false);
        }
      })();
    }
  }, [favorites, jwt, setIsLoading]);

  // effect which creates two unique arrays of selected groups and favorite types
  useEffect(() => {
    const newSelectedGroups = new Set();
    const newSelectedTypes = new Set();
    Object.keys(selectedFavorites).forEach((key) => {
      newSelectedGroups.add(favorites[key].group);
      newSelectedTypes.add(favorites[key].favoriteType);
    });
    setSelectedGroups(Array.from(newSelectedGroups));
    setSelectedTypes(Array.from(newSelectedTypes).filter((type) => type !== FAVORITE_TYPE.POLYGON));
  }, [selectedFavorites, favorites]);

  // effect which gets the event types
  useEffect(() => {
    if (!jwt) return;

    (async () => {
      try {
        setIsLoading(true);
        const [addressEventTypes, regionEventTypes] = await Promise.all([
          getEventTypesForAddresses(jwt),
          getEventTypesForRegions(jwt),
          // getEventTypesForPolygon(jwt),
        ]);

        const newAddressEventTypes = addressEventTypes.data.sort((a, b) => {
          const ta = t(a.descriptionLocize);
          const tb = t(b.descriptionLocize);
          return ta.localeCompare(tb);
        });
        const newRegionEventTypes = regionEventTypes.data.sort((a, b) => {
          const ta = t(a.descriptionLocize);
          const tb = t(b.descriptionLocize);
          return ta.localeCompare(tb);
        });

        setEventTypes({
          [FAVORITE_TYPE.REGION]: newRegionEventTypes,
          [FAVORITE_TYPE.ADDRESS]: newAddressEventTypes,
          // [FAVORITE_TYPE.POLYGON]: [],
        });
        setIsLoading(false);
      }
      catch (e) {
        log.error('Can not fetch event types: ', e);
        setIsLoading(false);
      }
    })();
  }, [setIsLoading, jwt, t]);

  // effect which updates the rowsPerPage state according to current filter
  useEffect(() => {
    // default select values are [50, 25, 10, 5] if there are enough data
    // the highest selectable value is always dynamic and depends on visible data length
    const maxOption = Math.min(50, sortedFavorites.length);
    // filter out the options which are higher than the actual data length
    // basically you can not select rows per page higher than available length
    const options = [5, 10, 25].filter((option) => option <= maxOption);
    // as last element of select we push the available data length
    // so user can display all elements in one page if they are less than 50, 25, 10 or 5
    if (maxOption === sortedFavorites.length) {
      options.push(sortedFavorites.length);
    }
    else {
      options.push(50);
    }
    const newItemsPerPage = options[options.length - 1];
    setItemsPerPage(newItemsPerPage);
  }, [sortedFavorites, filterGroup, filterType]);

  const api = useMemo(() => ({
    favorites,
    setFavorites,
    counter,
    groups,
    sortedFavorites,
    sortingBy,
    setSortingBy,
    nameFilter,
    setNameFilter,
    paginatedFavorites,
    setCurrentPage,
    currentPage,
    setItemsPerPage,
    filterGroup,
    setFilterGroup,
    filterType,
    setFilterType,
    selectedFavorites,
    setSelectedFavorites,
    loadingFavorites,
    selectedGroups,
    setLoadingFavorites,
    recipients,
    selectedTypes,
    setSelectedTypes,
    activeRecipients,
    currentRecipients,
    setIsLoading,
    eventTypes,
    isLoading: loadingStack.length > 0,
    fetchFavorites,
  }), [
    favorites,
    groups,
    filterGroup,
    selectedGroups,
    nameFilter,
    selectedFavorites,
    loadingFavorites,
    sortingBy,
    currentPage,
    paginatedFavorites,
    counter,
    currentRecipients,
    filterType,
    sortedFavorites,
    loadingStack,
    selectedTypes,
    recipients,
    eventTypes,
    activeRecipients,
    setIsLoading,
    fetchFavorites,
  ]);

  return (
    <FavoriteContext.Provider value={api}>{children}</FavoriteContext.Provider>
  );
}

export const useFavorites = () => {
  const ctx = useContext(FavoriteContext);
  if (!ctx) {
    throw new Error('useFavoriteProvider must be used within the FavoriteProvider');
  }
  return ctx;
};
