/* eslint brace-style: ["error", "stroustrup"] */
import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import * as loglevel from 'loglevel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  DialogContent,
  TextField,
  Autocomplete,
  Divider,
} from '@mui/material';
import SEPContext from '../../../../contexts/sep-context/SEPContext';
import env from '../../../../env/env';
import { useFavorites } from '../../../../contexts/favorites/FavoriteProvider';
import { API } from './utils';
import useToast from '../../../../hooks/useToast';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function ModalRenameGroup(props) {
  const {
    targetFavorites, // parent should provide selected or target favorites
    onRenameModalClose,
    open,
  } = props;
  const {
    groups,
    fetchFavorites,
    setLoadingFavorites,
  } = useFavorites();

  const targetGroups = targetFavorites.reduce((acc, target) => {
    acc.add(target.group);
    return acc;
  }, new Set());

  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation('favorites');
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const toast = useToast();

  const [value, setValue] = useState('');
  const [currentGroups, setCurrentGroups] = useState(Array.from(targetGroups));
  const [options, setOptions] = useState(groups);
  const [pendingChange, setPendingChange] = useState({});
  const [isPristine, setIsPristine] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const hasChanges = Object.keys(pendingChange).length > 0;

  useEffect(() => {
    const newValue = Object.keys(pendingChange)[0];
    setValue(newValue);
  }, [pendingChange]);

  const handleGroupChange = useCallback(async (newGroup) => {
    setPendingChange({
      [newGroup]: groups.includes(newGroup) ? 'selected' : 'new',
    });
  }, [groups]);

  const reloadFavorites = useCallback(async () => {
    const withLoading = false;
    await fetchFavorites(withLoading);
  }, [fetchFavorites]);

  const setLoadingTargets = useCallback((isLoading) => {
    const loading = targetFavorites.reduce((acc, curr) => ({
      ...acc,
      [`${curr.id}_${curr.favoriteType}`]: isLoading,
    }), {});

    setLoadingFavorites((prevLoading) => ({
      ...prevLoading,
      ...loading,
    }));
  }, [setLoadingFavorites, targetFavorites]);

  const updateFavorites = useCallback(async () => {
    try {
      setLoadingTargets(true);
      setLocalLoading(true);
      const targetGroup = Object.keys(pendingChange)[0];
      const requests = targetFavorites.map((favorite) => {
        const apiCall = API[favorite.favoriteType].patchFavorite;
        return apiCall(jwt, favorite, targetGroup)
          .catch((error) => log.error('updateFavorites', error));
      });
      await Promise.all(requests);
      await reloadFavorites();
      setLoadingTargets(false);
      setLocalLoading(false);
      setCurrentGroups([targetGroup]);
      setOptions(groups);
      setValue('');
      toast.success(t('various:settings-successfully-saved'));
    }
    catch (error) {
      log.error('updateFavorites', error);
      setLoadingTargets(false);
      setLocalLoading(false);
    }
  }, [
    targetFavorites,
    pendingChange,
    setLoadingTargets,
    reloadFavorites,
    groups,
    jwt, t,
    toast,
  ]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      onClose={() => onRenameModalClose(isPristine)}
    >
      <DialogTitle>
        {t('groupname-edit-mode')}
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          <Typography>
            {t('groupname-edit-explaination-favorites-from-the-groups')}
          </Typography>
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {currentGroups.map((group) => (
              <Typography key={group}>
                {group}
              </Typography>
            ))}
          </Stack>
          {Object.keys(pendingChange).length > 0 && pendingChange[value] === 'new' && (
            <Stack gap={1}>
              <Typography fontWeight={800}>
                {t('new-group-will-be-created')}
              </Typography>
              <Typography>
                {t('new-group-name')}
                {': '}
                {value}
              </Typography>
            </Stack>
          )}
          {Object.keys(pendingChange).length > 0 && pendingChange[value] === 'selected' && (
            <Stack gap={1}>
              <Typography fontWeight={800}>
                {t('group-will-be-changed-to')}
              </Typography>
              <Typography>
                {t('new-group-name')}
                {': '}
                {value}
              </Typography>
            </Stack>
          )}
          <Autocomplete
            freeSolo
            disableClearable
            value={value || ''}
            fullWidth
            sx={{ minWidth: 100 }}
            size="small"
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params} // eslint-disable-line react/jsx-props-no-spreading
                label={t('select-target-group')}
              />
            )}
            onChange={(e, newGroup) => handleGroupChange(newGroup)}
          />
        </Stack>
      </DialogContent>
      {localLoading && (
        <LinearProgress />
      )}
      <DialogActions>
        <Button onClick={() => onRenameModalClose(isPristine)}>
          {t('button-modal-cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            await updateFavorites();
            setIsPristine(false);
          }}
          disabled={!hasChanges || localLoading}
        >
          {t('button-modal-notification-update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
