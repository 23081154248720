import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as loglevel from 'loglevel';
import { read, utils as xlsxUtils, writeXLSX } from 'xlsx';
import axios from 'axios';
import { useContext } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSelection } from '../../../contexts/selection/selection-context';
import env from '../../../env/env';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import {
  columns,
  getBuildingCategory,
  getBuildingClass,
  getHeatGeneration,
  getHeatInformationSource,
  getHeatSource, getZone,
} from './utils';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function SelectionExporter({
  setIsExportModalOpen,
  setIsLoading,
  setDownloadLink,
  setFileName,
  setExportError,
  cancelDataExportToken,
  setFiles,
}) {
  const { polygons } = useSelection();
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { t } = useTranslation(['export', 'dynamic_panel_marketsense_v4', 'dynamic_panel_heat', 'controls_map']);

  // Replace specific column values with translations
  const translationMappings = {
    [columns.gebklasse(t)]: getBuildingClass,
    [columns.gebkategorieshort(t)]: getBuildingCategory,
    [columns.waermeerzeuger_heizung1(t)]: getHeatGeneration,
    [columns.waermequelle_heizung1(t)]: getHeatSource,
    [columns.informationsquelle_heizung1(t)]: getHeatInformationSource,
    [columns.zone(t)]: getZone,
  };

  // Columns that need rounding
  const roundingColumns = [
    columns.elec_demand_total_pred(t),
    columns.sumstromertrag_parcel_1(t),
    columns.sumstromertrag_parcelbuilding_1(t),
    columns.sumflaeche_parcel_1(t),
    columns.sumflaeche_parcelbuilding_1(t),
  ];

  const handleExportClick = async () => {
    if (!polygons.length) return;

    setIsExportModalOpen(true);
    setIsLoading(true);

    // Create a cancel token
    // eslint-disable-next-line no-param-reassign
    cancelDataExportToken.current = axios.CancelToken.source();

    try {
      const response = await axios({
        method: 'POST',
        url: `${env.API_GATEWAY_BASE}/api/marketsense/query-from-cloudant-by-polygon-csv?srid=4326&fields=${Object.keys(columns).join(',')}&jwt=${jwt}`,
        data: (() => {
          const formData = new FormData();
          formData.append('wkt', polygons[0]);
          return formData;
        })(),
        responseType: 'blob',
        cancelToken: cancelDataExportToken.current.token,
      });

      const text = await response.data.text();

      // Parse CSV data
      const csvData = read(text, { type: 'string' });
      const sheet = csvData.Sheets[csvData.SheetNames[0]];
      const jsonData = xlsxUtils.sheet_to_json(sheet, { header: 1 });

      // Replace header columns with translations
      jsonData[0] = jsonData[0].map((header) => {
        const key = Object.keys(columns).find((k) => k === header);
        return key ? columns[key](t) : header;
      });

      // Replace specific column values with translations
      const headMap = jsonData[0].reduce((acc, header, index) => {
        acc[header] = index;
        return acc;
      }, {});

      for (let i = 1; i < jsonData.length; i += 1) {
        const row = jsonData[i];
        Object.keys(translationMappings).forEach((column) => {
          if (row[headMap[column]]) {
            row[headMap[column]] = translationMappings[column](t, row[headMap[column]]);
          }
        });

        // Convert Excel date serial number to JavaScript date and format
        // as dd.MM.yyyy for "Aktualisierungsdatum Heizung 1 Gebäude"
        const dateColumnKey = columns.aktualisierungsdatum_heizung1(t);
        if (row[headMap[dateColumnKey]]) {
          // Excel stores dates as serial numbers where day 1 is January 1, 1900,
          // JavaScript's Date object counts milliseconds from January 1, 1970
          // 25569 is the number of days between January 1, 1900, and January 1, 1970,
          // Multiply by 86400 (seconds in a day) and then by 1000 to convert to milliseconds
          const excelDate = row[headMap[dateColumnKey]];
          const jsDate = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
          const dateValue = DateTime.fromJSDate(jsDate);
          row[headMap[dateColumnKey]] = dateValue.toFormat('dd.MM.yyyy');
          sheet[xlsxUtils.encode_cell({ r: i, c: headMap[dateColumnKey] })].z = 'dd.MM.yyyy';
        }

        // Round values for specific columns
        roundingColumns.forEach((column) => {
          if (row[headMap[column]] !== undefined) {
            row[headMap[column]] = Math.round(row[headMap[column]]);
          }
        });
      }

      // Convert back to worksheet
      const newSheet = xlsxUtils.aoa_to_sheet(jsonData);
      const newWorkbook = xlsxUtils.book_new();
      xlsxUtils.book_append_sheet(newWorkbook, newSheet, 'ExportData');

      // Generate dynamic date and year
      const creationDate = DateTime.now().toFormat('dd.MM.yyyy');
      const currentYear = DateTime.now().year;

      // Add a new sheet with Readme content
      const readmeData = [
        [t('readme_text_row_1')],
        [t('readme_text_row_2').replace('{creationDate}', creationDate)],
        [t('readme_text_row_3').replace('{currentYear}', currentYear)],
        [t('readme_text_row_4')],
      ];
      const readmeSheet = xlsxUtils.aoa_to_sheet(readmeData);
      xlsxUtils.book_append_sheet(newWorkbook, readmeSheet, 'Readme');

      // Create a unique file name
      const rowCount = jsonData.length - 1; // Subtracting the header row
      const uniqueDate = DateTime.now().toFormat('yyyy-LL-dd_HH-mm-ss');
      const fileName = `export_sep_${rowCount}_rows_${uniqueDate}.xlsx`;

      // Write the workbook to a binary string
      const xlsxData = writeXLSX(
        newWorkbook,
        {
          bookType: 'xlsx',
          type: 'array',
          Props: {
            Title: 'Data Export SEP',
            Subject: 'Exported Data',
            Author: 'support@geoimpact.ch',
            Company: 'geoimpact AG',
            Keywords: 'SEP, data export, geoimpact',
            Comments: 'Exported from SEP | Bern: Gutenbergstrasse 14, 3011 Bern;Zürich: Heinrichstrasse 267, 8005 Zürich;Basel: Kasernenhof 8, 4058 Basel;T: +41 41 560 09 85;support@geoimpact.ch',
            HyperlinkBase: 'https://geoimpact.ch',
            // TODO: Excel can't handle the file if we set the date (tbd search for fix)
            // CreatedDate: new Date(),
          },
        },
      );
      const blob = new Blob([xlsxData], { type: 'application/octet-stream' });

      const url = URL.createObjectURL(blob);
      setFiles([blob]);
      setDownloadLink(url);
      setFileName(fileName);
    } catch (e) {
      if (axios.isCancel(e)) {
        log.warn('Request canceled', e.message);
      } else if (e.response && e.response.status === 413) {
        setExportError('controls_map:error_selection_exceeds_the_limit');
      } else {
        setExportError('controls_map:error_export_try_later');
      }
      log.error('Error processing the file:', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      sx={{ color: 'white', height: '100%' }}
      onClick={handleExportClick}
      disabled={!polygons.length}
    >
      <DownloadIcon />
    </IconButton>
  );
}

export default SelectionExporter;
