/* eslint brace-style: ["error", "stroustrup"] */
import { useContext } from 'react';
import moment from 'moment/moment';
import iziToast from 'izitoast';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { IconButton } from '@mui/material';
import { themes } from '../../mui/themes';
import { useSelection } from '../../../contexts/selection/selection-context';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import env from '../../../env/env';
import { useFavorites } from '../../../contexts/favorites/FavoriteProvider';

function Favorites({ isOpen, isLoading, setIsLoading }) {
  const updateEventName = 'favorites-update';
  const { user: { jwt } } = useContext(SEPContext).SEPContext;
  const { selection, polygons, isPolygonMode } = useSelection();
  const { t } = useTranslation('favorites');
  const { fetchFavorites } = useFavorites();

  // util
  const dispatchUpdateEvent = async () => {
    const event = new Event(updateEventName);
    // Dispatch the event.
    document.body.dispatchEvent(event);
  };

  const getBuildings = () => selection.simple
    .map((selectedBuilding) => selectedBuilding.buildings[0]);

  const postFavoriteAddress = async (building, selectionGroupName) => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-building/${building.id}/${selectionGroupName}`;
    return axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => response)
      .catch((error) => error.response);
  };

  const getEventTypes = async () => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/eventtypesforaddresses`;
    return axios({
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((response) => response);
  };

  const addAddressesOfBuildingByGroup = async (group, eventType) => {
    const endpoint = `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-selectiongroup/${group}/eventtypes/${eventType.id}`;
    const data = {};
    return axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      data: JSON.stringify(data),
    })
      .then((response) => response);
  };

  const addAllEventTypes = async (groupName) => {
    const eventTypesResponse = await getEventTypes();
    const eventTypes = eventTypesResponse.data;
    const promises = [];
    eventTypes.forEach((eventType) => {
      const request = addAddressesOfBuildingByGroup(groupName, eventType);
      promises.push(request);
    });

    return Promise.all(promises);
  };

  const addFavorites = async () => {
    if (isLoading) {
      return Promise.resolve('a request is already pending');
    }
    if (isPolygonMode) return null;

    setIsLoading(true);
    const selectionGroupName = moment().unix();

    const buildings = getBuildings();
    const addFavoritesPromises = buildings
      .map(async (building) => postFavoriteAddress(building, selectionGroupName));
    await Promise.all(addFavoritesPromises)
      .then((favoritesResponses) => favoritesResponses)
      .then(async (favoritesResponses) => {
        // connect the favorite to all known event types
        const addAllEventTypesToAddresses = await addAllEventTypes(selectionGroupName);

        return { favoritesResponses, addAllEventTypesToAddresses };
      })
      .then(async ({ favoritesResponses }) => {
        let totalAddressedAdded = 0;
        favoritesResponses.forEach((response) => {
          if (
            response.status === 200
            || response.status === 201
          ) {
            totalAddressedAdded += response.data.length;
          }
        });
        const message = `${totalAddressedAdded} ${t('notification-favorites-added')}`;
        iziToast.show({
          timeout: 3000,
          theme: 'dark',
          class: 'loading',
          color: themes.geoimpact.palette.primary.main,
          zindex: 5,
          message: `${message}`,
          position: 'topCenter',
          drag: false,
        });
      });
    await fetchFavorites();
    await dispatchUpdateEvent();
    setIsLoading(false);
    return null;
  };

  return isOpen && (
    <IconButton
      sx={{ color: 'white', height: '100%' }}
      onClick={addFavorites}
      disabled={!selection?.simple?.length && !polygons.length}
    >
      <StarOutlineIcon />
    </IconButton>
  );
}

export default Favorites;
