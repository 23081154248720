/* eslint brace-style: ["error", "stroustrup"] */
import './Controls.css';

import { useTranslation, withTranslation } from 'react-i18next';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box, IconButton, Typography,
} from '@mui/material';
import * as loglevel from 'loglevel';
import { useContext } from 'react';
import { themes } from '../../mui/themes';
import AdvancedControls from './AdvancedControls';
import MapSelectionControl from './MapSelectionControl';
import { useSelection } from '../../../contexts/selection/selection-context';
import { useMap } from '../../../contexts/map/map-context';
import PolygonMode from './PolygonMode';
import env from '../../../env/env';
import SEPContext from '../../../contexts/sep-context/SEPContext';
import ControlButton from './ControlButton';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

function Controls() {
  const { mapRef } = useMap();

  const {
    buildingIDs, isLocked, polygons, clearPolygons, isPolygonMode,
  } = useSelection();
  const { user: { jwtParsed } } = useContext(SEPContext).SEPContext;
  const role = jwtParsed
    ? jwtParsed['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    : undefined;
  const LOCIZE_PANEL_NS = 'notification_map';
  const { t } = useTranslation(LOCIZE_PANEL_NS, { useSuspense: false });

  return (
    <Box>
      <ControlButton
        onClick={() => {
          if (mapRef?.current?.getZoom() >= 21) return;
          mapRef?.current?.zoomIn(1);
        }}
        hasHover={mapRef?.current?.getZoom() < 21}
        isPointer={mapRef?.current?.getZoom() < 21}
        className="control zoom-in"
      >
        <IconButton
          disabled={mapRef?.current?.getZoom() >= 21}
          sx={{ color: 'white', marginTop: '-1px' }}
        >
          {/* zoom in */}
          <AddIcon />
        </IconButton>
      </ControlButton>
      <ControlButton
        onClick={() => {
          const zoom = mapRef?.current?.getZoom();
          if (zoom <= 7) return;
          mapRef?.current?.zoomOut(1);
        }}
        hasHover={mapRef?.current?.getZoom() > 7}
        isPointer={mapRef?.current?.getZoom() > 7}
        className="control zoom-out"
      >
        <IconButton
          disabled={mapRef?.current?.getZoom() <= 7}
          sx={{ color: 'white' }}
        >
          {/* zoom out */}
          <RemoveIcon />
        </IconButton>
      </ControlButton>
      {role !== 'Guest' && (
        <ControlButton
          isPointer={!isLocked && (polygons.length || buildingIDs.length)}
          hasHover={!isLocked && (polygons.length || buildingIDs.length)}
          onClick={() => {
            if (isLocked || (!polygons.length && !buildingIDs.length)) return;
            if (!isPolygonMode) {
              try {
                const toasts = document.querySelectorAll('.loading-delete-selection');
                toasts.forEach((toast) => {
                  iziToast.hide({}, toast);
                });
              }
              catch (e) {
                log.debug('There is no iziToast to hide');
              }
              iziToast.show({
                timeout: 3000,
                theme: 'dark',
                class: 'loading-delete-selection',
                color: themes.geoimpact.palette.primary.main,
                zindex: 5,
                message: `${t(`${LOCIZE_PANEL_NS}:building-selection-deleted`)}`,
                position: 'topCenter',
                drag: false,
              });
              const event = new Event('sep_map-overlay_controls:trash-selection');
              // Dispatch the event.
              window.dispatchEvent(event);
            }
            else {
              iziToast.show({
                timeout: 3000,
                theme: 'dark',
                class: 'loading-delete-selection',
                color: themes.geoimpact.palette.primary.main,
                zindex: 5,
                message: `${t(`${LOCIZE_PANEL_NS}:polygon-selection-deleted`)}`,
                position: 'topCenter',
                drag: false,
              });
              clearPolygons();
            }
          }}
          className="control delete-selection"
        >
          <IconButton
            disabled={isLocked || (!polygons.length && !buildingIDs.length)}
            sx={{
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              color: 'white',
            }}
          >
            <DeleteIcon />
            <Typography variant="caption">
              {isPolygonMode ? polygons.length : buildingIDs.length}
            </Typography>
          </IconButton>
        </ControlButton>
      )}
      <PolygonMode />
      <MapSelectionControl />
      <AdvancedControls />
    </Box>
  );
}

export default withTranslation(['controls_map'])(Controls);
