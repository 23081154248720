import axios from 'axios';
import env from '../../../env/env';

const FavoriteApi = {
  getUserFavoriteSortingMethod: async (jwt) => {
    const res = await axios({
      url: `${env.API_GATEWAY_BASE}/api/appsettings`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (res.status !== 200) {
      return {
        sort: 'alphabetically',
        id: null,
      };
    }

    // NOTE: After the above-mentioned changes (see todo)
    // we have to change this part,
    // because there will be only one entry in the database
    const favoritesSettings = res.data.filter((settings) => settings.settingsType === 'favorites')[0];

    // If no favorite settings are saved in the backend
    // Save default settings in the database
    if (!favoritesSettings) {
      return {
        sort: 'alphabetically',
        id: null,
      };
    }

    return {
      sort: JSON.parse(favoritesSettings.settings).sorting,
      id: favoritesSettings.id,
    };
  },
  postUserFavoriteSortingMethod: async (jwt) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/appsettings`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: {
      settingsType: 'favorites',
      settings: JSON.stringify({
        sorting: 'alphabetically',
      }),
    },
  }),
  updateUserFavoriteSortingMethod: async (jwt, id, sorting) => axios({
    method: 'put',
    url: `${env.API_GATEWAY_BASE}/api/appsettings/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: {
      settingsType: 'favorites',
      settings: JSON.stringify({
        sorting,
      }),
    },
  }),
  getFavoriteAddresses: async (jwt) => axios({
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }),
  getFavoriteRegions: async (jwt) => axios({
    method: 'get',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }),
  getFavoriteAddressGroups: async (jwt) => axios({
    method: 'get',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-get-groups`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }),
  getFavoriteRegionGroups: async (jwt) => axios({
    method: 'get',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions-get-groups`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }),
  addRecipientOfAddressByEmail: async (jwt, favoriteId, email) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteId}/recipients/${email}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addRecipientOfRegionByEmail: async (jwt, favoriteId, email) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteId}/recipients/${email}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteRecipientOfAddressByEmail: async (jwt, favoriteId, email) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteId}/recipients/${email}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteRecipientOfRegionByEmail: async (jwt, favoriteId, email) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteId}/recipients/${email}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteRecipientForEventTypes: async (jwt, genericFavorite) => {
    const favoriteId = genericFavorite.id;
    const { recipientsMapping } = genericFavorite;
    if (genericFavorite.favoriteType === 'ADDRESS') {
      for (let i = 0; i < recipientsMapping.length; i += 1) {
        const recipientMap = recipientsMapping[i];
        const recipientEmail = genericFavorite.recipients
          .filter((r) => r.id === recipientMap.recipientId)[0].email;
        // TODO: rewrite this loop, await will not work here
        // eslint-disable-next-line no-await-in-loop
        await FavoriteApi.deleteRecipientOfAddressByEmail(
          jwt,
          favoriteId,
          recipientEmail,
        );
      }
    }
    if (genericFavorite.favoriteType === 'REGION') {
      for (let i = 0; i < recipientsMapping.length; i += 1) {
        const recipientMap = recipientsMapping[i];
        const recipientEmail = genericFavorite.recipients
          .filter((r) => r.id === recipientMap.recipientId)[0].email;
        // TODO: rewrite this loop, await will not work here
        // eslint-disable-next-line no-await-in-loop
        await FavoriteApi.deleteRecipientOfRegionByEmail(
          jwt,
          favoriteId,
          recipientEmail,
        );
      }
    }
  },
  postRecipient: async (jwt, email) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/recipients`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({
      address: '',
      email,
      emailActive: true,
      familyName: '',
      preName: '',
      title: '',
    }),
  }),
  addRecipientOfAddressGroupByEmail: async (jwt, groupname, email) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-group/recipients/${email}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteRecipientOfAddressByGroup: async (jwt, groupname, email) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-group/recipients/${email}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addRecipientOfRegionByGroup: async (jwt, groupname, email) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions-by-group/recipients/${email}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteRecipientOfRegionByGroup: async (jwt, groupname, email) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions-by-group/recipients/${email}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addEventTypeOfAddressByGroup: async (jwt, eventTypeId, groupname) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-group/eventtypes/${eventTypeId}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteEventTypeOfAddressByGroup: async (jwt, eventTypeId, groupname) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses-by-group/eventtypes/${eventTypeId}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addEventTypeOfRegionByGroup: async (jwt, eventTypeId, groupname) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions-by-group/eventtypes/${eventTypeId}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteEventTypeOfRegionByGroup: async (jwt, eventTypeId, groupname) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions-by-group/eventtypes/${eventTypeId}?group=${encodeURIComponent(groupname)}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteFavoriteAddress: async (jwt, favoriteId) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteFavoriteRegion: async (jwt, favoriteId) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteFavoritePolygon: async (jwt, favoriteId) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteselections/${favoriteId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }),
  deleteEventTypeFromAddressFavorite: async (jwt, favoriteId, eventTypeId) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteId}/eventtypes/${eventTypeId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addEventTypeToAddressFavorite: async (jwt, favoriteId, eventTypeId) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteId}/eventtypes/${eventTypeId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteEventTypeFromRegionFavorite: async (jwt, favoriteId, eventTypeId) => axios({
    method: 'delete',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteId}/eventtypes/${eventTypeId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  addEventTypeToRegionFavorite: async (jwt, favoriteId, eventTypeId) => axios({
    method: 'post',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteId}/eventtypes/${eventTypeId}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  deleteGenericFavorite: async (jwt, genericFavorite) => {
    if (genericFavorite.favoriteType === 'ADDRESS') {
      return FavoriteApi.deleteFavoriteAddress(jwt, genericFavorite.id);
    }
    return FavoriteApi.deleteFavoriteRegion(jwt, genericFavorite.id);
  },
  patchFavoriteAddress: async (jwt, favoriteAddress, data) => axios({
    method: 'patch',
    url: `${env.API_GATEWAY_BASE}/api/favoriteaddresses/${favoriteAddress.id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify(data),
  }),
  patchFavoriteRegion: async (jwt, favoriteRegion, data) => axios({
    method: 'patch',
    url: `${env.API_GATEWAY_BASE}/api/favoriteregions/${favoriteRegion.id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify(data),
  }),
  patchFavoritePolygon: async (jwt, favorite, data) => axios({
    method: 'patch',
    url: `${env.API_GATEWAY_BASE}/api/favoriteselections/${favorite.id}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify(data),
  }),
  patchGenericFavorite: async (jwt, genericFavorite, data) => {
    if (genericFavorite.favoriteType === 'ADDRESS') {
      return FavoriteApi.patchFavoriteAddress(jwt, genericFavorite, data);
    }
    return FavoriteApi.patchFavoriteRegion(jwt, genericFavorite, data);
  },
  getEventTypesForAddresses: async (jwt) => axios({
    url: `${env.API_GATEWAY_BASE}/api/eventtypesforaddresses`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
  getEventTypesForRegions: async (jwt) => axios({
    url: `${env.API_GATEWAY_BASE}/api/eventtypesforregions`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    data: JSON.stringify({}),
  }),
};

export const patchFavorite = async (jwt, favorite, data) => {
  if (favorite.favoriteType === 'ADDRESS') {
    return FavoriteApi.patchFavoriteAddress(jwt, favorite, data);
  }
  if (favorite.favoriteType === 'REGION') {
    return FavoriteApi.patchFavoriteRegion(jwt, favorite, data);
  }
  return FavoriteApi.patchFavoritePolygon(jwt, favorite, data);
};

export default FavoriteApi;
