/* eslint-disable react/destructuring-assignment */
import React, {
  StrictMode, Suspense, useContext, useEffect, useState, version,
} from 'react';

import 'normalize.css/normalize.css';
import './App.css';
import './client_styles/Global.css';

import { Helmet } from 'react-helmet';
import { StyledEngineProvider, ThemeProvider } from '@mui/system';
import * as loglevel from 'loglevel';
import qs from 'qs';
import axios from 'axios';
import { Alert, Snackbar } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
// eslint-disable-next-line import/no-cycle
import Router from '../router/Router';
import 'fomantic-ui-css/semantic.min.css';

import { UserUtil } from '../user_management/util/user-util';
// eslint-disable-next-line import/no-cycle
import SuspenceLoader from '../suspence_loader/SuspenceLoader';
import { initLangFromCookie } from '../settings/language_switch/LanguageSwitch';
import { initLanguageHeaderInterceptor, removeLanguageHeaderInterceptor } from '../../i18n/language-request-interceptor';
import { initAuthInterceptor, removeAuthInterceptor } from '../user_management/util/authentitication-inteceptors';

import KibanaEvents from '../kibana_events/KibanaEvents';
import { themes } from '../mui/themes';
import { CookieUtil } from '../user_management/cookies/cookie-util';
import * as MySEPContext from '../../contexts/sep-context/SEPContext';
import env from '../../env/env';

const SEPTheme = React.lazy(() => import('./client_styles/SEPTheme'));

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(
  env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.DEBUG : loglevel.levels.WARN,
);

const getContinuePath = () => {
  try {
    const { search } = window.location;
    const toParse = search.substring(1, search.length);
    const parsed = qs.parse(toParse, { arrayFormat: 'brackets' });
    const { continuePath } = parsed;
    return continuePath || null;
  } catch (e) {
    log.warn(`${e.message}`);
    return null;
  }
};
const continueNavigationFromUrl = (onNoContinuation) => {
  const continuePathFromUrl = getContinuePath();
  if (continuePathFromUrl !== null) {
    window.location.href = `${decodeURIComponent(continuePathFromUrl)}`;
  } else {
    onNoContinuation();
  }
};

export default function App(props) {
  // eslint-disable-next-line
  const context = useContext(MySEPContext.default).SEPContext;
  const [snackBar, setSnackBar] = useState(null);

  const {
    // eslint-disable-next-line no-unused-vars
    i18n, updateSEPContext,
  } = props;
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookieString, setCookieString] = useState(null);

  /*
  1. Get tenant info
  * */
  useEffect(() => {
    (async () => {
      if (cookieString) {
        const jwtCookieParsed = UserUtil.parseJWT(cookieString);
        const mandantResponse = await UserUtil.getMandant(cookieString, env)
          .then((response) => response)
          .catch((e) => {
            log.warn('Could not get the tenant information with the given jwt. Cleaning up the cookie and realoading the page.', e.response);
            return null;
          });
        if (mandantResponse) {
          CookieUtil.setCookie(CookieUtil.constants.names.USER, cookieString);
          const newSEPContext = {
            SEPContext: {
              env,
              user: {
                jwt: cookieString,
                jwtParsed: jwtCookieParsed,
                mandant: mandantResponse.data,
              },
              themes,
              theme: themes.geoimpact,
            },
          };
          updateSEPContext(newSEPContext);
          setIsAuthenticated(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieString, updateSEPContext]);

  useEffect(() => {
    (async () => {
      const jwtCookieString = CookieUtil.getCookie(CookieUtil.constants.names.USER);
      if (jwtCookieString) {
        setCookieString(jwtCookieString);
        const myIsAuthenticated = (await UserUtil.getMandant(jwtCookieString, env)
          .then(() => true).catch(() => {
            CookieUtil.deleteCookie(CookieUtil.constants.names.USER);
            return false;
          }));
        setIsAuthenticated(myIsAuthenticated);
      }
      setHasLoaded(true);
    })();
  }, []);

  /*
  1. Init the language from the cookie
  2. JWT refresh interceptor
  3. Auth interceptor
  * */
  useEffect(() => {
    initLangFromCookie({
      props: {
        i18n,
      },
    });
    // Create a request interceptor to always send the cookie to our backend
    const jwtRefreshInterceptor = axios.interceptors.response.use(
      (response) => {
        // Set the authentication header for every response we send to the SEP backend.
        if (
          response.config.url.startsWith(`${env.API_GATEWAY_BASE}/api/login`)
            || response.config.url.startsWith(`${env.MS_LOGIN_URL}`)
        ) {
          const newJwt = response?.headers?.jwt;
          if (newJwt !== undefined) {
            CookieUtil.setCookie(CookieUtil.constants.names.USER, newJwt);
          }
        }
        return response;
      },
      (error) =>
      // Do something with response error
      // eslint-disable-next-line implicit-arrow-linebreak
        Promise.reject(error),
    );
    // Create a request interceptor to always send the cookie to our backend
    const sepAuthInterceptor = axios.interceptors.request.use(
      (config) => {
        // Set the authentication header for every request we send to the SEP backend.
        const newConfig = config;
        if (config.url.startsWith(env.API_GATEWAY_BASE)) {
          // disable the sending of cookies on SEP endpoints
          newConfig.withCredentials = false;
          const jwtCookie = CookieUtil.getCookie(CookieUtil.constants.names.USER);
          if (jwtCookie !== null) {
            newConfig.headers.Authorization = `Bearer ${jwtCookie}`;
          }
          if (newConfig.headers?.['SEP-auth-override'] !== undefined) {
            newConfig.headers.Authorization = `Bearer ${newConfig.headers?.['SEP-auth-override']}`;
            // delete the custom header
            delete newConfig.headers['SEP-auth-override'];
          }
        }
        return newConfig;
      },
      (error) =>
      // Do something with request error
      // eslint-disable-next-line implicit-arrow-linebreak
        Promise.reject(error),
    );

    const languageInterceptor = initLanguageHeaderInterceptor(i18n);

    const authKickoutInterceptor = initAuthInterceptor((error) => {
      log.error('Unauthorized request.', {
        error, context,
      });
      if (context.user.jwtParsed?.Mandant !== 'guest') {
        // Do not kick out the user if he is a guest
        const continuePath = `${window.location.pathname}${window.location.search}`;
        /* We do not want to use the browser history api here because it is used by the router */
        window.location.href = `/user/signin/?continuePath=${continuePath}`;
      }
    });

    return () => {
      // Remove the interceptor
      axios.interceptors.response.eject(jwtRefreshInterceptor);
      axios.interceptors.request.eject(sepAuthInterceptor);
      removeAuthInterceptor(authKickoutInterceptor);
      removeLanguageHeaderInterceptor(languageInterceptor);
    };
  }, [context, i18n]);

  useEffect(() => {
    (async () => {
      // Parse the uri
      const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      // Decide if you came from oAuth based on the presence of special query parameters
      const comesFromOAuth = !!queryParams.code && !!queryParams.state;
      const hasOAuthError = !!queryParams.error && !!queryParams.error_description;
      if (hasOAuthError) {
        log.error('Could not log in with microsoft', {
          error: queryParams.error, error_description: queryParams.error_description,
        });
        setSnackBar((
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={5000}
            onClose={() => { setSnackBar(null); }}
          >
            <Alert
              severity="error"
            >
              <div>
                {i18n.t('sign_in:login-with-ms-nok')}
              </div>
            </Alert>
          </Snackbar>
        ));
      } else if (
        hasLoaded
          && isAuthenticated === false
          && comesFromOAuth
      ) {
        // perform ms login
        // TODO use a special route instead
        // const queryString = qs.stringify(queryParams);
        const loginResponse = await axios({
          // url: `${env.API_GATEWAY_BASE}/ms-login/?${queryString}`,
          url: `${env.API_GATEWAY_BASE}/ms-login/?code=${queryParams.code}&state=${queryParams.state}`,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }).catch((e) => {
          log.error('Could not login using microsoft: ', e.message);
          return null;
        });
        if (loginResponse) {
          const { jwt: jwtStringFromResponse } = loginResponse.headers;
          CookieUtil.setCookie(CookieUtil.constants.names.USER, jwtStringFromResponse);
          setCookieString(jwtStringFromResponse);
          setIsAuthenticated(true);
          window.location.replace('/map/');
        } else {
          setSnackBar((
            <Snackbar
              open
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={5000}
              onClose={() => { setSnackBar(null); }}
            >
              <Alert
                severity="error"
              >
                <div>
                  {i18n.t('sign_in:login-with-ms-nok')}
                </div>
              </Alert>
            </Snackbar>
          ));
        }
      }
    })();
  }, [hasLoaded, i18n, isAuthenticated]);

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <Suspense fallback={<SuspenceLoader {...props} />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes.geoimpact}>
          <ConfirmProvider
            defaultOptions={{
              confirmationButtonProps: { autoFocus: true },
              allowClose: false,
            }}
          >
            <StrictMode>
              <div className="App">
                {/* {JSON.stringify({
                hasLoaded, isAuthenticated,
              })} */}
                {snackBar}
                <SEPTheme />
                <div id="react-version" style={{ display: 'none' }}>
                  Running React
                  {version}
                </div>
                <Helmet />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <KibanaEvents {...props} />
                <Router
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...props}
                  appState={{
                    hasLoaded, isAuthenticated,
                  }}
                  onSignIn={async (loginResponseJwt) => {
                    // eslint-disable-next-line max-len
                    CookieUtil.setCookie(CookieUtil.constants.names.USER, loginResponseJwt);
                    setCookieString(loginResponseJwt);
                    continueNavigationFromUrl(() => {
                      window.location.href = '/map/';
                    });
                  }}
                  onLogout={() => {
                    CookieUtil.deleteCookie(CookieUtil.constants.names.USER);
                    window.location.href = '/';
                  }}
                />
              </div>
            </StrictMode>
          </ConfirmProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
}
